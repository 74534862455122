import React from 'react';
import {
    Create,
    maxLength,
    minLength,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect
} from 'react-admin';
import ISIN_FIELD from "../model/ISIN";
import { isinValidation } from '../validation/isinValidation';
import { cleanupIsinBeforeSave } from "./IsinForm";

const IsinCreate = (props: any) => {
    const redirect = useRedirect();

    const PostCreateToolbar = props => (
        <Toolbar {...props}>
            <SaveButton label="anna.addIsin.saveButtonLabel" {...props} submitOnEnter={true}/>
        </Toolbar>
    );

    function onSaveSuccess({ data }) {
        // no notification
        redirect('edit', props.basePath, data.id, data);
    }

    return (
        <Create onSuccess={onSaveSuccess}
                transform={cleanupIsinBeforeSave}
                {...props}>
            <SimpleForm toolbar={<PostCreateToolbar/>}>

                <TextInput source={ISIN_FIELD.id}
                           validate={[
                               required(),
                               minLength(12, 'resources.isin.isinLenWrong'),
                               maxLength(12, 'resources.isin.isinLenWrong'),
                               isinValidation
                           ]}
                           parse={value => value ? value.toUpperCase() : ''}
                />

            </SimpleForm>
        </Create>
    );
};

export default IsinCreate;