import React from "react";
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';

const PasswordValidator = ({ mincharacters, oneLowercase, oneUppercase, hasNumber, hasSymbol, isConfirmed}) => {
    const errorStyles = makeStyles({
        invalid: {
            color: "red"
        },
        valid: {
            color: "green"
        },
    });

    const style = errorStyles();
    const translate = useTranslate();
    return <>
        <ul>
            <li className={mincharacters === true ? style.valid : style.invalid}>{translate('anna.resetPassword.mincharacters')}</li>
            <li className={oneLowercase === true ? style.valid : style.invalid}>{translate('anna.resetPassword.oneLowercase')}</li>
            <li className={oneUppercase === true ? style.valid : style.invalid}>{translate('anna.resetPassword.oneUppercase')}</li>
            <li className={hasNumber === true ? style.valid : style.invalid}>{translate('anna.resetPassword.hasNumber')}</li>
            <li className={hasSymbol === true ? style.valid : style.invalid}>{translate('anna.resetPassword.hasSymbol')}</li>
            <li className={isConfirmed === true ? style.valid : style.invalid}>{translate('anna.resetPassword.isConfirmed')}</li>
        </ul>
    </>
};

export default PasswordValidator;