import * as React from "react";
import { Button, Collapse, Toolbar } from '@material-ui/core';
import { FormWithRedirect, SaveButton, useTranslate } from 'react-admin';
import ChallengeFields from "./ChallengeFields";
import IsinFields from "../isin/IsinFields";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CardContent from "@material-ui/core/CardContent";
import createDecorator from 'final-form-focus';
import { IsinFormMutators } from "../isin/IsinFormMuatators";

const focusOnError = createDecorator();

const ChallengeForm = (props) => {

    const translate = useTranslate();
    const [showDetails, setShowDetails] = React.useState(false);

    const switchView = () => {
        setShowDetails(!showDetails);
    }

    function renderForm(formProps) {
        return <form>

            <ChallengeFields resource={formProps.resource}  {...props}/>

            {/*Using material Button here, because React-Admin Button will be called several times and triggers the onClick method uncontrollable*/}
            <Button onClick={switchView}>
                {showDetails ? <ExpandMoreIcon/> : <ChevronRightIcon/>}
                {translate(showDetails ?
                    'resources.challenge.hideSpecifyExpectedValues' :
                    'resources.challenge.specifyExpectedValues'
                )}
            </Button>

            <Collapse in={showDetails}>
                <IsinFields resource={formProps.resource} {...props} challenge={true}/>
            </Collapse>

            <Toolbar>
                <SaveButton
                    disabled={formProps.pristine}
                    saving={formProps.saving}
                    submitOnEnter={true}
                    label={"resources.challenge.buttons.challenge"}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    color={"primary"}
                    invalid={formProps.invalid}
                />
            </Toolbar>
        </form>;
    }

    return <CardContent /* add margins */ >
        <FormWithRedirect
            decorators={[focusOnError]}
            validateOnBlur // Only perform validation when leaving a field
            mutators={IsinFormMutators}
            render={renderForm}
            {...props}
        />
    </CardContent>
};

export default ChallengeForm;