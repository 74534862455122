import React, { FC, Fragment, ReactElement, ReactNode } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
}));

interface Props {
    handleToggle: () => void;
    sidebarIsOpen: boolean;
    isOpen: boolean;
    name: string;
    icon: ReactNode;
    children: ReactNode;
    dense: boolean;
    visible: boolean;
}

const SubMenu: FC<Props> = ({
                                handleToggle,
                                sidebarIsOpen,
                                isOpen,
                                name,
                                icon,
                                children,
                                dense,
                                visible,
                            }) => {
    const translate = useTranslate();
    const classes = useStyles();

    if (!visible)
        return <Fragment />;

    const Header = (props: { visible: boolean }) =>
        <MenuItem dense={dense} button onClick={handleToggle}>
            <ListItemIcon className={classes.icon}>
                {isOpen ? <ExpandMore /> : icon}
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {translate(name)}
            </Typography>
        </MenuItem>;

    return (
        <Fragment>
            {sidebarIsOpen || isOpen ? (
                <Header visible={visible} />
            ) : (
                <Tooltip title={translate(name)} placement="right">
                    <Header visible={visible} />
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    className={
                        sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                >
                    {children}
                </List>
                <Divider />
            </Collapse>
        </Fragment>
    );
};

export default SubMenu;
