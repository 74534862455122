import cfiInstrumentCategoryRules2015 from './rules/cfiInstrumentCategoryRules2015.json';
import cfiInstrumentCategoryRules2001 from './rules/cfiInstrumentCategoryRules2001.json';
import cfiInterestTypeRules from './rules/cfiInterestTypeRules.json';
import cfiMaturityDateRules from './rules/cfiDebtMaturityDateRules.json';
import InstrumentCategory from "../model/InstrumentCategory";
import fieldValidationRules from "./rules/fieldvaliationRules";
import interestfieldValidationRules from "./rules/interestFieldvaliationRules";
import FIELD_TYPE from "./FieldType";
import ISIN_FIELD from "../model/ISIN";
import CfiStandard from "../model/CfiStandard";
import InterestType from "../model/InterestType";

const getFieldRules = map => {
    const rules: Map<InstrumentCategory, FIELD_TYPE> = new Map();
    for (let innerMapEntry in map) {
        const entryValue = map[innerMapEntry] === '' ? 'N' : map[innerMapEntry];
        rules.set(InstrumentCategory[innerMapEntry], entryValue);
    }
    return rules;
};

const getInterestFieldRules = map => {
    const rules: Map<InterestType, FIELD_TYPE> = new Map();
    for (let innerMapEntry in map) {
        const entryValue = map[innerMapEntry] === '' ? 'N' : map[innerMapEntry];
        rules.set(InterestType[innerMapEntry], entryValue);
    }
    return rules;
};

export default () => ({

    getCfiValidationRules: (cfiVersion: string) => {
        const compareKey = Object.keys(CfiStandard).find(key => CfiStandard[key] === CfiStandard.CFI2015);
        return compareKey === cfiVersion ? new Map(Object.entries(cfiInstrumentCategoryRules2015)): new Map(Object.entries(cfiInstrumentCategoryRules2001));
    },

    getInterestTypeRules: () => {
        return new Map(Object.entries(cfiInterestTypeRules));
    },

    getMaturityDateRules: () => {
        return new Map(Object.entries(cfiMaturityDateRules));
    },

    /**
     * Maps json stuctured data into typescripted structure.
     */
    getFieldValidationRules: (): Map<ISIN_FIELD, Map<InstrumentCategory, FIELD_TYPE>> => {
        const allRules: Map<ISIN_FIELD, Map<InstrumentCategory, FIELD_TYPE>> = new Map();
        for (const entry in fieldValidationRules) {
            if (Object.values(ISIN_FIELD).includes(ISIN_FIELD[entry])) {
                const innerMap = fieldValidationRules[entry];
                allRules.set(ISIN_FIELD[entry], getFieldRules(innerMap));
            } else {
                console.error('ISIN field rule (Instrument Category) for ' + entry + ' is not among known ISIN fields');
            }
        }
        return allRules;
    },
    getInterestFieldValidationRules: (): Map<ISIN_FIELD, Map<InterestType, FIELD_TYPE>> => {
        const allRules: Map<ISIN_FIELD, Map<InterestType, FIELD_TYPE>> = new Map();
        for (const entry in interestfieldValidationRules) {
            if (Object.values(ISIN_FIELD).includes(ISIN_FIELD[entry])) {
                const innerMap = interestfieldValidationRules[entry];
                allRules.set(ISIN_FIELD[entry], getInterestFieldRules(innerMap));
            } else {
                console.error('ISIN field rule (InterestType) for ' + entry + ' is not among known ISIN fields');
            }
        }
        return allRules;
    },
});
