import polyglotI18nProvider from 'ra-i18n-polyglot';
import origEnglishMessages from 'ra-language-english';
import customEnglishMessages from './en';

const allEnglishMessages = {
    ...origEnglishMessages,
    ...customEnglishMessages
};

allEnglishMessages.ra.action.refresh = 'Reload this page'
allEnglishMessages.ra.notification.data_provider_error = 'data provider error'

const i18nProvider = polyglotI18nProvider(() =>
        allEnglishMessages,
    'en' // Default locale
);

export default i18nProvider;