import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Title } from 'react-admin';

export default function AboutAsb() {
    return <>
        <Title title="About ASB"/>
        <Card className="Card">
            <CardContent>
                <div className="textcontent aboutfont">
                    <h3>About the Anna Service Bureau</h3>

                    <p>The ASB was developed in partnership with CUSIP Global Services and SIX Financial
                        Information Ltd., which continue to serve as the operators of the ASB. The ASB is
                        governed and supported by the ANNA Service Bureau Technical Committee, which is
                        comprised of members elected by the membership body and appointees by the ANNA board and
                        the operators. </p>
                    <p>The work of the ASB reflects ANNA's ongoing commitment to the progress of
                        straight-through processing (STP) in financial markets, improving timeliness, accuracy
                        and standardization of identification of financial instruments, while fostering
                        equitable distribution of this information.</p>
                    <p>The ASB provides the following benefits to the industry:</p>
                    <ul>
                        <li>Fully supported, robust ISIN products and services available via multiple media
                            (database and web query tools) and frequencies (including daily)
                        </li>
                        <li>Efficient worldwide support of data quality and service</li>
                        <li>Centralized administration</li>
                        <li>Robust database storage and disaster recovery</li>
                        <li>Proactive planning and global implementation of technology and communication
                            systems
                        </li>
                    </ul>

                    <h3>Data Services</h3>
                    <p>The ASB provides access to its hub of consolidated global identifier information and is
                        available in the following formats:</p>
                    <ul>
                        <li><b>ANNA ISIN_db:</b> Weekly or Daily delivery of bulk ISIN data in a relational
                            database format, delivered via FTP.
                        </li>
                        <li><b>ANNA ISIN Access:</b> A real-time, Web-based query tool that provides access to
                            all ISIN data via personal computer.
                        </li>
                    </ul>
                    <p>The ANNA ISIN products offer the broadest and the only directly-sourced compilation of
                        ISIN codes and associated data worldwide. New and updated ISINs and related data are
                        received daily directly from the National Numbering Agencies, not third parties or data
                        vendors.</p>

                    <p>The ASB database has recently been enhanced to include the following new data
                        elements:</p>

                    <ul>
                        <li>Legal Entity Identifier (LEI) (ISO 17442)</li>
                        <li>Lead Manager Name</li>
                        <li>Lead Manager LEI</li>
                        <li>Fund Manager Name</li>
                        <li>Fund Manager LEI</li>
                        <li>Financial Instrument Short Name (FISN) ( ISO 18774)</li>
                        <li>Market Identifier Code (MIC) (ISO 10383)</li>
                        <li>Central Securities Depository Name (CSD)</li>
                        <li>Issuer Headquarters Address</li>
                    </ul>
                    <p>The instrument category has also been enhanced to include Entitlement Rights, Structured
                        Products, Referential Instruments and Collective Investment Vehicles.</p>
                    <p>Additional ISIN data elements include:</p>
                    <ul>
                        <li>ISIN Number (ISO 6166)</li>
                        <li>Create and Modify Date</li>
                        <li>ISIN Status</li>
                        <li>Issuer Long Name</li>
                        <li>Issue Description</li>
                        <li>CFI Code (Classification of Financial Instruments ISO 10962)</li>
                        <li>Issuer Legal Registration Country (ISO 3166)</li>
                        <li>Currency of Issue (ISO 4217)</li>
                        <li>Maturity/Expiry Date</li>
                    </ul>
                    <h3>About the Association of National Numbering Agencies</h3>
                    <p>Established in 1992 by 22 foundering numbering agencies, ANNA serves as the Registration
                        Authority for ISIN and CFI numbering standards, under appointment by the International
                        Organization for Standardization (ISO).The number of NNAs and nations using the ISIN
                        numbers and CFI codes to standardize identification of their locally issued securities
                        has grown consistently year after year.</p>
                </div>
            </CardContent>
            <CardActions>
                <a href="https://www.anna-web.org/members/about-anna-members/" target="_blank"
                    rel="noopener noreferrer" className="footer-disclaimer__link">
                    Read more.
                </a>
            </CardActions>
        </Card>
    </>;
}
