import FLD from "../../model/ISIN";
import FIELD_TYPE from "../FieldType";

export default {
    [FLD.id]: {
        "EQ": FIELD_TYPE.M,
        "CV": FIELD_TYPE.M,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.M,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.M,
        "RI": FIELD_TYPE.M,
        "OT": FIELD_TYPE.M,
        "SO": FIELD_TYPE.M,
    },
    [FLD.isinStatus]: {
        "EQ": FIELD_TYPE.M,
        "CV": FIELD_TYPE.M,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.M,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.M,
        "RI": FIELD_TYPE.M,
        "OT": FIELD_TYPE.M,
        "SO": FIELD_TYPE.M,
    },
    [FLD.instrumentCategory]: {
        "EQ": FIELD_TYPE.M,
        "CV": FIELD_TYPE.M,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.M,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.M,
        "RI": FIELD_TYPE.M,
        "OT": FIELD_TYPE.M,
        "SO": FIELD_TYPE.M,
    },
    [FLD.issueDescription]: {
        "EQ": FIELD_TYPE.M,
        "CV": FIELD_TYPE.M,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.M,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.M,
        "RI": FIELD_TYPE.M,
        "OT": FIELD_TYPE.M,
        "SO": FIELD_TYPE.M,
    },
    // CFI and FISN is Mandatory for all Instrumentcategories, so set required on the field in IsinFields.tsx. This rule is only to show the asterix in the UI.
    [FLD.cfiCode]: {
        "EQ": FIELD_TYPE.M,
        "CV": FIELD_TYPE.M,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.M,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.M,
        "RI": FIELD_TYPE.M,
        "OT": FIELD_TYPE.M,
        "SO": FIELD_TYPE.M
    },
    [FLD.fisn]: {
        "EQ": FIELD_TYPE.M,
        "CV": FIELD_TYPE.M,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.M,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.M,
        "RI": FIELD_TYPE.M,
        "OT": FIELD_TYPE.M,
        "SO": FIELD_TYPE.M
    },
    [FLD.preliminaryTerms]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.nominalValue]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issueCurrency]: {
        "EQ": FIELD_TYPE.M,
        "CV": FIELD_TYPE.M,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.M,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.smallestDenomination]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.conversionRatio]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.maturityDate]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.maturityDateCode]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.exercisePrice]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.N,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.exercisePriceCurrency]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.N,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.underlyings]: {  // todo a list?
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.interestType]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.N,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.interestRate]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.N,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.interestPayMonth]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.N,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.interestPayDay]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.N,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.interestFrequency]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.N,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.interestFirstPayDate]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.N,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.additionalInformationText]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.O
    },
    [FLD.marketIdCodes]: { // todo lists?
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.O
    },
    [FLD.leadManagerName]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.N,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.leadManagerLEI]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.N,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.fundManagerName]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.N,
        "EN": FIELD_TYPE.N,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.N,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.fundManagerLEI]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.N,
        "EN": FIELD_TYPE.N,
        "OP": FIELD_TYPE.N,
        "FT": FIELD_TYPE.N,
        "SP": FIELD_TYPE.N,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.csdName]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.centralSecDepsAndLEIs]: {    // todo a list of objects!!
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerNameLong]: {
        "EQ": FIELD_TYPE.M,
        "CV": FIELD_TYPE.M,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.M,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.M,
        "RI": FIELD_TYPE.M,
        "OT": FIELD_TYPE.M,
        "SO": FIELD_TYPE.M
    },
    [FLD.issuerLEI]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerNameShort]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerSupranational]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerHQAddress1]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerHQAddress2]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerHQStateProvince]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerHQPostCode]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerHQCity]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerHQCountry]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerLegalRegAddress1]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerLegalRegAddress2]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerLegalRegStateProvince]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerLegalRegPostCode]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerLegalRegCity]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.issuerLegalRegCountry]: {
        "EQ": FIELD_TYPE.M,
        "CV": FIELD_TYPE.M,
        "DT": FIELD_TYPE.M,
        "EN": FIELD_TYPE.M,
        "OP": FIELD_TYPE.M,
        "FT": FIELD_TYPE.M,
        "SP": FIELD_TYPE.M,
        "RI": FIELD_TYPE.M,
        "OT": FIELD_TYPE.M,
        "SO": FIELD_TYPE.N
    },
    [FLD.oldIsin]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.O
    },
    [FLD.newIsin]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.O
    },
    [FLD.callPut]: {
        "EQ": FIELD_TYPE.N,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.N,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.N,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.underlyingInstrumentName]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.N,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.N,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.headOfficeLEI]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    },
    [FLD.headOfficeName]: {
        "EQ": FIELD_TYPE.O,
        "CV": FIELD_TYPE.O,
        "DT": FIELD_TYPE.O,
        "EN": FIELD_TYPE.O,
        "OP": FIELD_TYPE.O,
        "FT": FIELD_TYPE.O,
        "SP": FIELD_TYPE.O,
        "RI": FIELD_TYPE.O,
        "OT": FIELD_TYPE.O,
        "SO": FIELD_TYPE.N
    }
}