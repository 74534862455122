import { composeSyncValidators, maxLength, regex } from 'react-admin';

// Comma is allowed in the front-end, it will be replaced with a dot during backend validation F_NRS_NUMERIC_FIELD_CONTAINS_COMMA

function buildValidator(integerLength: number, decimalLength: number = 6, regexMessage: string = 'Field must be numeric') {
    const error = `Number must be up to ${integerLength} integer and ${decimalLength} decimal places`;
    return composeSyncValidators(
        regex(/^[\d.,-]*$/, regexMessage),            // only digits and commas
        maxLength(integerLength + 1 + decimalLength, error),    // overall max length validation
        regex(new RegExp("^[0-9]{0," + integerLength + "}([.,][0-9]{1," + decimalLength + "})?$"), error)
    )
}

export const validateNumber_12_6 = buildValidator(12)
export const validateNumber_9_6 = buildValidator(9)
export const validateNumber_5_6 = buildValidator(5)
export const validateNumber_3_6 = buildValidator(3)
export const validateNumber_12_6_NPV = buildValidator(12, 6, "Field must be numeric or 'NPV'")

export const nominalValueValidation = (value: string) => {
    if (!value || value === 'npv' || value === 'NPV') {
        return undefined
    }

    return validateNumber_12_6_NPV(value)
}