import React, { useState } from 'react';
import { withTypes } from 'react-final-form';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { email, PasswordInput, required, TextInput, useLogin, useNotify, useRedirect, useTranslate } from 'react-admin';
import { Button, Card, CardActions, CardContent, CircularProgress, } from '@material-ui/core';

const PASSWORD_EXPIRED = 'anna.login.passwordExpired';
const TWOFACTOR_AUTHENTICATION = 'anna.login.twoFactorAuthenticationNeeded';
const SECURITY_CODE_NOT_CORRECT = 'anna.login.providedSecurityCodeNotCorrect';
const SECURITY_CODE_EXPIRED = 'anna.login.providedSecurityCodeExpired';

const useStyles = makeStyles(() => ({
    input: {
        maxWidth: '20em'
    }
}));

interface FormValues {
    username?: string;
    password?: string;
    securityCode?: string;
    }

const { Form } = withTypes<FormValues>();

const LoginForm = () => {
    const [loading, setLoading] = useState(false);
    const [seccode, setSeccode] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const classes = useStyles();
    const redirect = useRedirect();
    const translate = useTranslate();
    const location = useLocation<{ nextPathname: string } | null>();

    const handleSubmit = async (auth: FormValues) => {
        setLoading(true);

        let formData: FormValues = { ...auth };

        login(formData, location.state ? location.state.nextPathname : '/')
            .then(() => redirect('isin'))
            .catch((error: Error) => {
                setLoading(false);
                setSeccode(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );

                if (error.message === PASSWORD_EXPIRED) {
                    redirect(`requestPasswordReset?expired=true&email=${auth.username}`);
                }
                if (error.message === TWOFACTOR_AUTHENTICATION || error.message === SECURITY_CODE_NOT_CORRECT) {
                    setSeccode(true);
                }
                if(error.message === SECURITY_CODE_EXPIRED){
                    auth.securityCode = "";
                }
            });
    };

    const renderLoginForm = ({ handleSubmit }) => {
        return (
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.input}>
                    <TextInput
                        source="username"
                        type="email"
                        autoComplete="username"
                        label="anna.login.labelEmail"
                        validate={[required(), email()]}
                        disabled={loading}
                        autoFocus
                        fullWidth
                    />
                </div>
                <div className={classes.input}>
                    <PasswordInput
                        source="password"
                        label="ra.auth.password"
                        validate={required()}
                        autoComplete="current-password"
                        disabled={loading}
                        fullWidth
                    />
                </div>

                {seccode ? (
                    <div className={classes.input}>
                        <TextInput
                            visible={true}
                            source="securityCode"
                            validate={[required()]}
                            label="anna.login.securityCode"
                            fullWidth
                        />
                    </div> ) : null }

                <CardActions>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} thickness={2}/> : <ExitToAppIcon/>}
                    >
                        {translate('ra.auth.sign_in')}
                    </Button>
                    <Button color="primary"
                            component={Link}
                            to="/requestPasswordReset">
                        {translate('anna.login.forgotPassword')}
                    </Button>
                </CardActions>
            </form>
        );
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">{translate('anna.login.title')}</Typography>
                <Form
                    onSubmit={handleSubmit}
                    render={renderLoginForm}
                />
            </CardContent>
        </Card>
    );
};

export default LoginForm;
