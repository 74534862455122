import React from 'react';
import { TextInput, useRedirect } from 'react-admin';
import InputAdornment from '@material-ui/core/InputAdornment';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';

export default ({ source, ...props }) => {

    const redirect = useRedirect();

    const clickHandler = () => {
        redirect('/isin/create');
    };

    return <TextInput source={source}
                      {...props}
                      disabled={true}
                      InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton aria-label="New"
                                              onClick={clickHandler}
                                              edge="end">
                                      <CreateIcon color="inherit"/>
                                  </IconButton>
                              </InputAdornment>
                          ),
                      }}
    />

}