import { composeSyncValidators, DateInput } from "react-admin";
import React from "react";

const patternDateNumber = /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/;
const patternDateDashes = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

/**
 * IE11 does not support <input type="date">
 * In this case, users must enter date exactly as YYYY-MM-DD
 *
 * Other browsers support date input and let user pick with the calendar.
 * Also they present dates in local format (e.g. MM/DD/YYYY) and do the translation automatically
 * so that input.value is always in ISO format.
 */
function isDateSupported() {
    const input = document.createElement('input');
    const expected = 'a';
    input.setAttribute('type', 'date');
    input.setAttribute('value', expected);
    const actual = input.value;
    return actual !== expected;
}

const dateInputSupported = isDateSupported();

function addDashes(value: string): string {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (!value) {
        return '';
    }

    const dayStart = 6;
    const monthStart = 4;
    const separator = '-';

    // We only format the date, if it is correct.
    if (value.match(patternDateNumber)) {
        return value.substring(0, monthStart) + separator + value.substring(monthStart, dayStart) + separator + value.substring(dayStart);
    }
    return value;
}

function removeDashes(value: string): string {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (!value) {
        return '';
    }

    if (value.match(patternDateDashes)) {
        return value.replace(/-/g, "");
    }
    return value;
}

function emptyIfInvalid(event) {
    if (!event.target.value) {
        // If user entered an invalid date manually, then the date field will be cleared.
        // Otherwise an invalid date is still shown in the field, but field.value returns null. 
        // Forcing value to be set to null again (despice it's already reported as null) clears the input field
        // User will see empty box intead of invalid date and everything is in sync
        event.target.value = null
    }
}

export function IE11DateInput({ validate = [], ...props }) {

    const inputValidator = (value) => {
        if (!value) {
            return undefined;
        }
        const match = patternDateNumber.exec(value) || patternDateDashes.exec(value);
        if (!match) {
            return dateInputSupported ? 'anna.validation.incorrectDate' : 'anna.validation.incorrectIsoDate';
        }
        const year = parseInt(value.slice(0, 4));
        if (year < 1899) {
            return 'anna.validation.dateTooEarly';
        }
        return undefined;
    };

    return <DateInput {...props}
                      format={addDashes}
                      parse={removeDashes}
                      onBlur={emptyIfInvalid}
                      helperText={dateInputSupported ? undefined : "anna.validation.incorrectIsoDate"}
                      validate={composeSyncValidators(inputValidator, ...validate)}
    />
}

