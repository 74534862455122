export function matchesPattern(cfiCode: string, pattern: string[]): boolean {
    if (!cfiCode || !pattern) return false

    for (let i = 0; i < pattern.length; i++) {
        if (matchPattern(cfiCode, pattern[i])) {
            return true;
        }
    }
    return false
}

function matchPattern(value: string, pattern: string): boolean {
    for (let i = 0; i < value.length; i++) {
        let codeChar = value.charAt(i);

        if (pattern.length > i) {
            let charPattern = pattern.charAt(i);
            if (charPattern !== '*' && codeChar !== 'X' && codeChar !== charPattern) {
                return false;
            }
        }
    }
    return true;
}
