import React from 'react';
import { useNotify, useRefresh } from 'ra-core';
import { Create, FileInput, FunctionField, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';
import AUDIT from "../model/InboundAudit";
import DownloadIcon from "@material-ui/icons/GetApp";
import { HiddenInputCsrfToken } from "./HiddenInputCsrfToken";
import { hasFieldData } from "../validation/fieldValidation";
import { validateTextFile } from "../validation/textfileValidation";

/**
 * Ugly difference - we expect Portfolio requests to appear only from the GUI.
 * Unlike other APIs it uses vanilla HTML5 form upload which returns a portfolio response
 * in the same request as a downloadable file.
 */
export function submitPortfolioUpload() {
    const form = document.forms["portfolioOneOffUpload"]
    if (!form) return Promise.reject("Portfolio download works only from the portfolio screen");

    // Start the upload process
    form.submit();

    // It's pretty much impossible to detect when FORM upload is finished, or download started
    // There is a hack with a cookie to identify when download has finished, but here we assume that
    // it will take 2 seconds for downloaded file to appear in the browser. Complete download will take time
    const fakeButtonDelay = 2;

    // Using Promise to keep Submit button locked (busy with spinner icon) for 2 seconds
    return new Promise((res) => {
            // @ts-ignore
            setTimeout(() => res({ data: { id: 0 } }), 1000 * fakeButtonDelay)
        }
    )
}

function PostCreateToolbar(props) {
    const notify = useNotify();
    const refresh = useRefresh();

    function onUploadSuccess() {
        notify('resources.portfolio.started');
        // erase the form
        refresh();
    }

    return <Toolbar {...props}>

        <SaveButton
            icon={<DownloadIcon/>}
            label='ra.action.export'
            submitOnEnter={true}
            onSuccess={onUploadSuccess}
        />

    </Toolbar>;
}

function validateAll(values) {
    const errors = {} as any;
    if (!hasFieldData(values.file) && !values.pasted) {
        errors.file = "resources.portfolio.emptyForm";
    }

    return errors
}

export function PortfolioUpload(props) {
    return <Create {...props} title="Portfolio Export">

        <SimpleForm toolbar={<PostCreateToolbar/>}
                    id="portfolioOneOffUpload"
                    encType="multipart/form-data"
                    action="/anna/api/v1/portfolio/export"
                    method="POST"
                    download={true}
                    validate={validateAll}
        >
            {/*
              * This SimpleForm is backed by a usual HTML form, and a normal classical POST request is be used
              * to upload a file (or pasted data) when [Export] button is clicked.
              * Server responds with OK 200 and provides the file for download
             */}
            <HiddenInputCsrfToken/>


            <FileInput
                source={AUDIT.file}
                fullWidth
                type="file"
                validate={validateTextFile}
                helperText="resources.portfolio.hints.file">

                <FunctionField source={AUDIT.file} // used for sorting
                               label={AUDIT.file}
                               render={file => {
                                   return file.rawFile.name + " is selected";
                               }}
                />

            </FileInput>

            <TextInput multiline
                       source="pasted"
                       fullWidth
                       helperText="resources.portfolio.hints.pasted"
                       rowsMax={5}
            />

        </SimpleForm>
    </Create>
}