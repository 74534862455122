import React, { useEffect, useState } from 'react';
import {
    Create,
    DeleteButton,
    Edit,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    useInput,
    useNotify,
    useRecordContext,
    useRedirect
} from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import { AssigneeNNASelection, AssigneeUserSelection, getAssigneesFromDb } from "./AssigneeNNaSelection";
import TagsInput from "./TagInputs";
import './Tickets.css'
import ChatEntriesField from "./ChatEntriesField";
import CustomFileInput from "./CustomFileInput";

const useStyles = makeStyles((theme) => ({
    attachmentSection: {
        backgroundColor: '#e8e8e8',
        borderRadius: '4px',
        borderBottom: '1px solid #ccc',
        marginBottom: theme.spacing(1),
    },
    attachmentTitle: {
        marginBottom: theme.spacing(0),
        fontSize: '0.8rem',
        color: 'rgba(0, 0, 0, 0.54)',
        paddingLeft: theme.spacing(0),
        paddingTop: theme.spacing(0.1),
    },
    small: {
        fontSize: '0.9rem',
        padding: '0px',
        '& .MuiInputBase-input': {
            fontSize: '0.9rem',
        },
    },
    gridItem: {
        paddingBottom: '0px',
        paddingTop: '0px',
    }
}));

interface TicketEditProps {
    id?: string;
}

const TicketEdit: React.FC<TicketEditProps> = (props) => {

    const [newAttachments, setNewAttachments] = useState([]);

    const handleAttachmentsChange = (newAttachments) => {
        setNewAttachments(newAttachments);
    };

    const isCreating = props.id === undefined;
    const redirect = useRedirect();

    const EditShowField = ({ source, label, editable, ...rest }) => {
        const classes = useStyles();
        const record = useRecordContext();
        const condition = (record && record.currentTicketRole === 'OWNER') ||  isCreating;

        return condition && (editable === undefined || editable === true) ? (
            <TextInput className={classes.small} fullWidth source={source} label={label} disabled={false} {...rest} />
        ) : (
            <TextInput className={classes.small} fullWidth source={source} label={label} disabled={true || !isCreating} {...rest} />
        );
    };

    const EditShowSelectedField = ({ source, label, ...rest }) => {
        const classes = useStyles();
        const record = useRecordContext();
        const condition = (record && record.currentTicketRole === 'OWNER') ||  isCreating;
        return condition ? (
            <SelectInput className={classes.small} fullWidth source={source} label={label} disabled={false} {...rest} />
        ) : (
            <SelectInput className={classes.small} fullWidth source={source} label={label} disabled={true} {...rest} />
        );
    };

    const StatusField = ({ source, label, ...rest }) => {
        const classes = useStyles();
        const record = useRecordContext();
        const isOwner = record && record.currentTicketRole === 'OWNER';

        const choicesOwner = [
            { id: 'OPEN', name: 'Open' },
            // { id: 'ACCEPTED', name: 'Accepted' },
            { id: 'INPROGRESS', name: 'In progress' },
            { id: 'CLOSED', name: 'Closed' },
        ]
        const choicesResolver = [
            { id: 'OPEN', name: 'Open' },
            // { id: 'ACCEPTED', name: 'Accepted' },
            { id: 'INPROGRESS', name: 'In progress' },
            { id: 'CLOSED', name: 'Closed' },
        ]

        return isOwner ? (
            <SelectInput className={classes.small} fullWidth source={source} label={label} disabled={false}
                         choices={choicesOwner} {...rest} />
        ) : (
            <SelectInput className={classes.small} fullWidth source={source} label={label} disabled={false}
                         choices={choicesResolver} {...rest} />
        );
    };

    const validateCommentRequired = (values) => {
        const errors = {};
        if((values.status === 'CLOSED') && !values.commentToAdd) {
            errors["commentToAdd"] = 'Comment is required when status changes to Closed.';
        }
        if((values.status === 'RESOLVED') && !values.commentToAdd) {
            errors["commentToAdd"] = 'Comment is required when status changes to Resolved.';
        }
        return errors;
    };

    const PostCreateToolbar = props => {
        const record = useRecordContext(props);
        const isOwner = record && record.currentTicketRole === 'OWNER';

        return <Toolbar {...props}>
            <SaveButton label="Save"/>
            {isOwner && (
                <DeleteButton label={"Delete"}/>
            )}
        </Toolbar>;
    };

    const onFailure = (error) => {
        console.debug("data from failed: " + JSON.stringify(error))
        notify('Error: ' + error.message, 'warning');
        redirect('/tickets')
    };

    const notify = useNotify();

    const onSuccess = (response) => {
        console.debug("response from success: ", response.json.message);
        notify(response.json.message, 'info');
        redirect('/tickets')
    };

    const defaultValues = {
        status: 'OPEN',
        priority: 'NORMAL',
    };
    const FileLinksField = ({ source }) => {
        const {
            input: { value, onChange },
        } = useInput({ source });

        if (!value || value.size == 0) {
            return null;
        }

        const handleRemoveAttachment = (attachmentToRemove) => {
            const updatedAttachments = value.filter(attachment => attachment !== attachmentToRemove);
            console.debug("deleted " + JSON.stringify(updatedAttachments))
            onChange(updatedAttachments);
        };

        return (
            <>
                {value.map((attachment, index) => {
                    const { id, fileName, base64EncodedData, fileType } = attachment;
                    // Use the ID to determine if this is an existing attachment or a new one
                    const downloadUrl = id ? `/anna/api/v1/downloadAttachement/${id}` : base64EncodedData;
                    const isExistingAttachment = !!id;
                    const displayName = fileName;

                    return (
                        <div key={index} style={{ width: '200%' }}>
                            {isExistingAttachment ? (
                                <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                                    {displayName}
                                </a>
                            ) : (
                                // For new attachments
                                <span>{fileName} (new)</span>
                            )}
                            <Button name={"delete"} title={"delete"} onClick={() => handleRemoveAttachment(attachment)} style={{ cursor: 'pointer' }}>
                                <DeleteIcon />
                            </Button>
                            {/*<Button name={"delete"} title={"delete"} onClick={() => handleRemoveAttachment(attachment)} style={{ cursor: 'pointer' }} >Delete</Button>*/}
                        </div>
                    );
                })}
            </>
        );
    };

    const extensionToMimeType: { [key: string]: string } = {
        '.pdf': 'application/pdf',
        '.txt': 'text/plain',
        '.xls': 'application/vnd.ms-excel',
        '.doc': 'application/msword',
        '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.ppt': 'application/vnd.ms-powerpoint',
        '.png': 'image/png',
        '.jpeg': 'image/jpeg',
        '.jpg': 'image/jpeg',
        '.csv': 'text/csv',
        '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '.msg': 'application/vnd.ms-outlook',
        '.gif': 'image/gif',
        '.webp': 'image/webp'
    };

    const extensions = ".pdf,.txt,.xls,.doc,.docx,.ppt,.png,.jpeg,.jpg,.csv,.xlsx,.msg,.gif,.webp";
    const extensionsArray: string[] = extensions.split(',');


    const AttachmentsInput = ({ source, onChange: onAttachmentsChange }) => {
        const {
            input: { value, onChange },
        } = useInput({ source });

        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = Array.from(event.target.files || []);
            const allowedTypes: string[] = extensionsArray.map((ext: string) => extensionToMimeType[ext]);

            const validFiles = files.filter(file => {
                if (file.type === "" && file.name.endsWith(".msg")) {
                    return true;
                }
                return allowedTypes.includes(file.type);
            });

            const invalidFiles = files.filter(file => {
                if (file.type === "" && file.name.endsWith(".msg")) {
                    return false;
                }
                return !allowedTypes.includes(file.type);
            });

            if (invalidFiles.length > 0) {
                alert("Some files have been rejected because the file types are not allowed.");
            }

            const fileReaders = validFiles.map(file => {
                return new Promise(resolve => {
                    const reader = new FileReader();
                    reader.onload = () => resolve({
                        base64EncodedData: reader.result,
                        fileName: file.name,
                        fileType: file.type,
                    });
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(fileReaders).then(newAttachments => {
                const updatedAttachments = [...(value || []), ...newAttachments];
                const onlyNewAttachment = [...[], ...newAttachments];
                onChange(updatedAttachments);
                if (onAttachmentsChange) {
                    onAttachmentsChange(onlyNewAttachment);
                }
            });
        };
        return (
            <div>
                <CustomFileInput extensions={extensions} handleFileChange={handleFileChange}></CustomFileInput>
            </div>
        );
    };

    const dataProvider = useDataProvider();
    const [defaultAssignees, setDefaultAssignees] = useState([]);

    useEffect(() => {
        const fetchAssignees = async () => {
            try {
                const response = await getAssigneesFromDb(dataProvider);
                setDefaultAssignees(response);
            } catch (error) {
                console.info("error in useeffect dataProvider go data: " + error);
                console.error(error);
            }
        };

        fetchAssignees();
    }, [dataProvider]);

    const classes = useStyles();

    const ticketForm = <SimpleForm validate={validateCommentRequired} defaultValue={defaultValues} toolbar={<PostCreateToolbar />}>
        <Container maxWidth="lg" style={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <EditShowField validate={required()} source="title" label="Title" editable={true}/>
                </Grid>
                <Grid item xs={3}>
                    <EditShowField source={"ticketId"} label={"Ticket ID"} editable={false}/>
                </Grid>
                <Grid item xs={2}>
                    <StatusField source={"status"} label={"Status"}></StatusField>
                </Grid>
                <Grid item xs={2}>
                    <EditShowSelectedField label="Priority" source="priority" choices={[
                        { id: 'LOW', name: 'Low' },
                        { id: 'NORMAL', name: 'Normal' },
                        { id: 'HIGH', name: 'High' },
                    ]}></EditShowSelectedField>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <EditShowField source="body" label="Description" multiline fullWidth editable={true}/>
                    {/*<TextInput source="body" multiline fullWidth />*/}
                </Grid>
                <Grid item xs={6}>
                    <TagsInput source="tags" placeholder="Add tag" />
                </Grid>
            </Grid>
            <Grid container spacing={2}>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <EditShowField source={"ownerName"} label={"Owner"} editable={false}/>
                </Grid>
                <Grid item xs={3}>
                    <AssigneeNNASelection source="assignedToCustomerId"
                                          label="Resolver NNA" defaultAssignees={defaultAssignees}></AssigneeNNASelection>
                </Grid>
                <Grid item xs={3}>
                    <AssigneeUserSelection source="assignedToUserId" label="Assigned To" defaultAssignees={defaultAssignees}/>
                </Grid>
                <Grid item xs={3} className={classes.attachmentSection}>
                    <Typography className={classes.attachmentTitle}>Attachments:</Typography>
                    <FileLinksField source="attachments"/>
                    <AttachmentsInput source="attachments" onChange={handleAttachmentsChange}/>
                </Grid>

            </Grid>

            {/*<Grid container spacing={2}>*/}
            {/*    <TagsInput source="tags" placeholder="Add tag" />*/}
            {/*</Grid>*/}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput source="commentToAdd" multiline fullWidth/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <ChatEntriesField source="annaTicketChatEntries"/>
            </Grid>

        </Container>
    </SimpleForm>;

    return (
        <>
            {isCreating ? (
                <Create {...props} title="Create Ticket" mutationMode="pessimistic" onFailure={onFailure} onSuccess={onSuccess}>
                    {ticketForm}
                </Create>
                ) : (
                <Edit {...props} title="Edit Ticket" mutationMode="pessimistic" onFailure={onFailure} onSuccess={onSuccess}>
                    {ticketForm}
                </Edit>
            )}
        </>
    );
};

export default TicketEdit;
