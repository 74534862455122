import { KEY_ANNA_COUNTRIES } from "./authProvider";

export function fillEnums(enumType, showKey: boolean = true) {
    return Object.keys(enumType).map(key => {
        return {
            id: key,
            name: enumType[key] + (showKey === true ? " (" + key + ")" : ''),
        };
    });
}

export function getNNACountries(): string[] {
    return JSON.parse(localStorage.getItem(KEY_ANNA_COUNTRIES)) || [];
}

export interface PermissionInterface {
    nna: boolean;
    portfolio: boolean;
    admin: boolean;
    user: boolean;
}

export async function getRoles(): Promise<PermissionInterface> {
    const response = await fetch('/permissions');
    const permissions = await response.json();

    const permissionObject: PermissionInterface = {
        nna: permissions.nna || false,
        portfolio: permissions.portfolio || false,
        admin: permissions.admin || false,
        user: permissions.user || false
    };

    return permissionObject;
}