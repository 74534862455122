import React, { useState, useEffect } from 'react';
import { Button, useNotify, useDataProvider } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { fetchUtils } from "ra-core";
import EmailIcon from "@material-ui/icons/Email";

const TicketOptions = () => {
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = useState(true);
    const [active, setActive] = useState(true);
    const [onlyAssignedToMe, setOnlyAssignedToMe] = useState(true);
    const [newTickets, setNewTickets] = useState(true);
    const [updatedTickets, setUpdatedTickets] = useState(true);
    const [closedTickets, setClosedTickets] = useState(true);
    const [userId, setUserId] = useState(-1);
    const notify = useNotify();
    const dataProvider = useDataProvider();

    useEffect(() => {
        // Replace `/your-api-endpoint/` with the actual endpoint
        // Ensure `userId` is available and valid
        const fetchData = async () => {
            try {
                const response = await fetch(`/anna/api/v1/ticketnotificationsettings`);
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setNotification(data.notification);
                setOnlyAssignedToMe(data.onlyAssignedToMe);
                setNewTickets(data.newTickets);
                setUpdatedTickets(data.updatedTickets);
                setClosedTickets(data.closedTickets);
                setUserId(data.userId);
                setActive(data.active);
            } catch (error) {
                notify(`Error: ${error.message}`, 'warning');
            }
        };

        fetchData();
    }, [notify]);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOnlyAssignedToMe = () => setOnlyAssignedToMe(!onlyAssignedToMe);
    const handleNotification = () => {
        console.log("handleNotification " + notification);
        setNotification(!notification);
    };
    const handleNewTickets = () => setNewTickets(!newTickets);
    const handleUpdateTickets = () => setUpdatedTickets(!updatedTickets);
    const handleClosedTickets = () => setClosedTickets(!closedTickets);

    const handleConfirm = () => {
        const settings = {
            active,
            notification,
            onlyAssignedToMe,
            newTickets,
            updatedTickets,
            closedTickets
        };
        dataProvider.update('ticketnotificationsettings', { data: settings })
            .then(({ body }) => {
                var jsonObject = JSON.parse(body)
                // console.debug("Settings updated successfully : " + JSON.stringify(body));
                if(jsonObject.success === true){
                    notify('Settings updated successfully.', 'info');
                }else{
                    notify('Something went wrong with saving the settings. Please contact Support.', 'error');
                }
                handleClose();
            })
            .catch(error => {
                notify(`Error: ${error.message}`, 'warning');
            });
    };
    return (
        <>
            <Button startIcon={<EmailIcon />} label="Notification Settings" onClick={handleClickOpen} icon={<CheckCircleIcon />}/>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Notification Settings</DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={<Checkbox checked={notification} onChange={handleNotification} />}
                        label="Get notified per email"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={onlyAssignedToMe} onChange={handleOnlyAssignedToMe} />}
                        label="Get notified only when personally assigned (No group emails)"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={newTickets} onChange={handleNewTickets} />}
                        label="new tickets"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={updatedTickets} onChange={handleUpdateTickets} />}
                        label="updated tickets"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={closedTickets} onChange={handleClosedTickets} />}
                        label="closed tickets"
                    />
                </DialogContent>
                <DialogActions>
                    <Button label="Cancel" onClick={handleClose} />
                    <Button label="Confirm" onClick={handleConfirm} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TicketOptions;