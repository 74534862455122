import { Datagrid, List, Loading, TextField, useListContext, usePermissions } from "react-admin";
import React from "react";

function ListContents({ resource }) {
    const { loading } = useListContext();

    const { loaded, permissions } = usePermissions();
    const isAdmin = loaded && permissions && permissions.admin;

    if (loading) {
        return <Loading loadingPrimary="resources.isin.loadingPrimary"
                        loadingSecondary="resources.isin.loadingSecondary"/>;
    }

    function onClick() {
        return isAdmin ? "edit" : null
    }

    return <Datagrid resource={resource}
                     rowClick={onClick}>
        <TextField sortable={false} source="id"/>
        <TextField sortable={false} source="description"/>
    </Datagrid>;
}

export function CCList({ resource, ...props }) {
    const { loaded, permissions } = usePermissions();
    const isAdmin = loaded && permissions && permissions.admin;

    return <List resource={resource}

                 actions={isAdmin
                     ? undefined // keep default: Create and Export
                     : false     // Don't show anything until login
                 }

                 basePath={isAdmin ? "/" + resource : "/list"}
                 hasList={true}
                 hasShow={false}
                 hasCreate={isAdmin}
                 hasEdit={isAdmin}

                 title={"List of " + resource}

                 pagination={null} // data source is not paginated, always returns the full list
                 perPage={500}

                 filters={null}
                 bulkActionButtons={false}
                 {...props}>
        <ListContents resource={resource}/>
    </List>;
}