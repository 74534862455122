import React from 'react';
import { format, differenceInWeeks } from 'date-fns';
import { ticketStatus } from "./TicketEnums";

const statusColors = {
    OPEN: '#E13B37',
    INPROGRESS: '#FAC133',
    CLOSED: '#689E39',
};

const adjustColorBrightness = (color, factor) => {
    const colorInt = parseInt(color.slice(1), 16);
    const r = (colorInt >> 16) & 255;
    const g = (colorInt >> 8) & 255;
    const b = colorInt & 255;
    const newR = Math.min(255, Math.floor(r * factor));
    const newG = Math.min(255, Math.floor(g * factor));
    const newB = Math.min(255, Math.floor(b * factor));
    return `rgb(${newR}, ${newG}, ${newB})`;
};

export const statusColorRenderer = (record) => {
    const status = ticketStatus.find(status => status.id === record.status);
    if (!status) return record.status;

    const color = statusColors[status.id] || '#000000';
    const dateCreated = new Date(record.date_created);
    const weeksOld = differenceInWeeks(new Date(), dateCreated);
    const brightnessFactor = Math.max(0.5, 1 - (weeksOld / 5));

    const style = {
        backgroundColor: adjustColorBrightness(color, brightnessFactor),
        color: 'white',
        padding: '5px 10px',
        borderRadius: '10px',
        display: 'inline-block',
    };

    return <span style={style}>{status.name}</span>;
};

const priorityStyles = {
    HIGH: {
        color: 'red',
        fontWeight: 'bold'
    },
};

export const priorityRenderer = (record) => {
    const style = record.priority === 'HIGH' ? priorityStyles.HIGH : {};
    return <span style={style}>{record.priority}</span>;
};