import React from 'react';
import { useRecordContext } from 'react-admin';
import { Box, styled } from "@material-ui/core";

const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit', hour12: true
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    backgroundColor: '#f9f9f9',
    padding: theme.spacing(2),
}));

const ChatEntriesField = ({ source }: { source: string }) => {
    const record = useRecordContext();
    const chatEntries = record[source];

    return (
        <StyledBox>
            <h3>Chat History</h3>
            {chatEntries && chatEntries.length > 0 ? (
                chatEntries.map((entry, index) => (
                    <Box key={index} sx={{ marginBottom: 1, padding: 1, borderBottom: '1px solid #ccc' }}>
                        <p style={{ margin: 0, fontWeight: 'bold' }}>
                            {formatDate(entry.date_updated)} {entry.commentOwner}:
                        </p>
                        <p style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{entry.comment}</p>
                    </Box>
                ))
            ) : (
                <p>No chat entries.</p>
            )}
        </StyledBox>
    );

};

export default ChatEntriesField