/**
 * Calculates a check digit for an isin
 * @param {String} code an ISIN code with country code, but without check digit
 * @return {number} The check digit for this code
 */
import { getNNACountries } from "../api/commonFunctions";

function calcISINCheck(code: string): number {
    let conv = '';
    let digits = '';
    let sd = 0;
    // convert letters
    for (let i = 0; i < code.length; i++) {
        const c = code.charCodeAt(i);
        conv += (c > 57) ? (c - 55).toString() : code[i]
    }
    // group by odd and even, multiply digits from group containing rightmost character by 2
    for (let i = 0; i < conv.length; i++) {
        digits += (parseInt(conv[i]) * ((i % 2) === (conv.length % 2 !== 0 ? 0 : 1) ? 2 : 1)).toString();
    }
    // sum all digits
    for (let i = 0; i < digits.length; i++) {
        sd += parseInt(digits[i]);
    }
    // subtract mod 10 of the sum from 10, return mod 10 of result
    return (10 - (sd % 10)) % 10;
}

export function isValidISIN(isin): boolean {
    const regex = /^([A-Z]{2})([0-9A-Z]{9})([0-9])$/;
    const match = regex.exec(isin);
    if (!match || match.length !== 4) return false;
    const firstTwo = match[1];
    const middle = match[2];
    const calc = calcISINCheck(firstTwo + middle);
    const checkDigit = match[3];
    return checkDigit === calc.toString();
}

export function isinValidation(value) {
    if (value && !isValidISIN(value)) {
        return 'resources.isin.isinInvalid';
    }
    return undefined;
}
export function checkResponsibility(isin: string) {
    return isin && isin.length >= 2 && getNNACountries().includes(isin.substr(0, 2));
}