import { FormDataConsumer, required, SelectInput } from "react-admin";
import React from "react";
import { IE11DateInput } from "./IE11DateInput";
import { fillEnums } from "../api/commonFunctions";
import MaturityTypes from "../model/MaturityTypes";
import { maturityDateValidation } from "../validation/maturityDateValidation";

// Internal special values
export const SPECIFIC_DATE_INDICATOR = '~';
const NONE = '';  // comes from backend this way, if empty

function choices() {
    const choices: { name: string; id: string }[] = fillEnums(MaturityTypes);
    choices.push({ id: SPECIFIC_DATE_INDICATOR, name: "Specific Date" });
    return choices;
}

export function MaturityType({ ...props }) {

    return <SelectInput allowEmpty={true}
                        emptyText="None"
                        emptyValue={NONE}
                        choices={choices()}
                        parse={(value) =>
                            // undefined will be interpreted as NONE
                            value === undefined ? NONE : value
                        }
                        validate={maturityDateValidation}
                        {...props} />
}

export function MaturityDate({ ...props }) {
    return <FormDataConsumer {...props}>
        {({ formData }) =>
            formData.maturityDateCode !== "P" &&
            formData.maturityDateCode !== "Q" &&
            formData.maturityDateCode !== "R" &&
            formData.maturityDateCode !== NONE &&
            formData.maturityDateCode &&
            <IE11DateInput {...props} validate={[required('anna.validation.incorrectDate')]}/>
        }
    </FormDataConsumer>
}

