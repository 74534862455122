import callPostRequest from './callPostRequest';
import { refreshCsrfToken } from './authProvider';

export default () => ({

    forgot: async (email: string): Promise<void> => {
        await refreshCsrfToken();
        return callPostRequest("/anna/general/forgetPassword", { email });
    },

    reset: async (token: string, newPassword: string): Promise<void> => {
        await refreshCsrfToken();
        return callPostRequest("/anna/general/resetPassword", { token, newPassword });
    }

});
