import AUDIT from "../model/InboundAudit";
import FLD from "../model/ISIN";
import SEARCH_FLD from "../model/ISINSearch";

export const isinFields = {
    [FLD.id]: 'ISIN',
    [FLD.instrumentCategory]: "Instrument Category",

    [FLD.issuerNameLong]: 'Issuer Name (Long)', // Issuer Name (Long)

    [FLD.issuerLegalRegCountry]: 'Issuer Legal Registration Country',

    [FLD.issueDescription]: 'Issue Description',

    [FLD.issueCurrency]: "Issue Currency",
    [FLD.isinStatus]: 'Issue Status',

    [FLD.interestType]: "Type of Interest",
    [FLD.maturityDate]: "Maturity/Expiry Date",
    [FLD.maturityDateCode]: "Maturity/Expiry Date",

    [FLD.cfiCode]: 'CFI',
    [FLD.cfiVersion]: "CFI Standard Version",

    [FLD.fisn]: "FISN",

    // Page 2
    [FLD.preliminaryTerms]: "Preliminary Terms/Prospectus",
    [FLD.nominalValue]: "Nominal Value",

    [FLD.smallestDenomination]: "Smallest Denomination",
    [FLD.conversionRatio]: "Conversion Ratio/Contract Size",

    [FLD.exercisePrice]: "Exercise Price",
    [FLD.exercisePriceCurrency]: "Exercise Price Currency",

    [FLD.interestRate]: "Interest Rate",
    [FLD.interestFrequency]: "Interest Frequency",

    [FLD.interestPayMonth]: "Interest Payment Month",
    [FLD.interestPayDay]: "Interest Payment Day",
    [FLD.interestFirstPayDate]: "First Interest Payment Date",

    [FLD.additionalInformationText]: "Additional Information",

    [FLD.marketIdCodes]: "Market Identifier Code (MIC)",
    [FLD.underlyings]: "Underlying ISINs",

    [FLD.leadManagerLEI]: "Lead Manager LEI",
    [FLD.leadManagerName]: "Lead Manager Name",

    [FLD.fundManagerLEI]: "Fund Manager LEI",
    [FLD.fundManagerName]: "Fund Manager Name",

    // virtual fields
    [FLD.centralSecDepsAndLEIs]: "Central Security Depository (CSD)",
    [FLD.csdLei]: "CSD LEI",
    [FLD.csdName]: "CSD Name",

    // Page 3
    [FLD.issuerLEI]: "Issuer LEI",
    [FLD.issuerSupranational]: "Issuer Supranational",
    [FLD.issuerNameShort]: "Issuer Name (Short)",
    [FLD.issuerHQAddress1]: "Issuer Headquarters Address 1",
    [FLD.issuerHQAddress2]: "Issuer Headquarters Address 2",
    [FLD.issuerHQStateProvince]: "Issuer Headquarters State/Province",
    [FLD.issuerHQPostCode]: "Issuer Headquarters Post Code",
    [FLD.issuerHQCity]: "Issuer Headquarters City",
    [FLD.issuerHQCountry]: "Issuer Headquarters Country",
    [FLD.issuerLegalRegAddress1]: "Issuer Legal Registration Address 1",
    [FLD.issuerLegalRegAddress2]: "Issuer Legal Registration Address 2",
    [FLD.issuerLegalRegStateProvince]: "Issuer Legal Registration State/Province",
    [FLD.issuerLegalRegPostCode]: "Issuer Legal Registration Post Code",
    [FLD.issuerLegalRegCity]: "Issuer Legal Registration City",

    [FLD.userIdAdd]: "Added by",
    [FLD.dateAdd]: "Added on",
    [FLD.userIdModify]: "Updated by",
    [FLD.dateModify]: "Updated on",
    [FLD.oldIsin]: "Old ISIN",
    [FLD.newIsin]: "New ISIN",
    [FLD.callPut]: "Call/Put",
    [FLD.underlyingInstrumentName]: "Underlying Instrument Name",
    [FLD.headOfficeLEI]: "Head Office LEI",
    [FLD.headOfficeName]: "Head Office Name"
};
const customEnglishMessages = {

    resources: {
        // User-friendly field names

        currencies: {
            name: 'Currencies',
            fields: {
                "id": "Currency Code",
                "description": "Currency Name"
            }
        },
        countries: {
            name: 'Countries',
            fields: {
                "id": "Country Code",
                "description": "Country Name"
            }
        },

        isin: {
            name: 'ISIN',
            fields: {
                // Page 1
                ...isinFields,

                [SEARCH_FLD.maturityType]: "Maturity type",
                [SEARCH_FLD.maturityDateRangeFrom]: "Maturity date from",
                [SEARCH_FLD.maturityDateRangeTo]: "Maturity date to",
                [SEARCH_FLD.dateModifyRangeFrom]: "Last update from",
                [SEARCH_FLD.dateModifyRangeTo]: "Last update to",
                [SEARCH_FLD.dateAddRangeFrom]: "Added from",
                [SEARCH_FLD.dateAddRangeTo]: "Added to",
                [SEARCH_FLD.interestRateRangeFrom]: "Interest rate from",
                [SEARCH_FLD.interestRateRangeTo]: "Interest rate to",
            },
            sections: {
                main: "ISIN Main data",
                details: "Instrument Data",
                debt: "Debt Data",
                entity: "Entity Profile Data"
            },
            successfullySaved: 'Record has been saved to the database.',
            changeSuccessfullySaved: 'ISIN Record %{isin} was successfully saved.',
            dataValidationWarning: 'Data Validation Warning',
            isinInvalid: 'ISIN is not valid.',
            oldIsinInvalid: 'One of the ISIN is invalid.',
            oldIsinsInvalid: 'Only 5 ISIN are allowed.',
            isinAlreadyExists: 'Current ISIN is not allowed.',
            saveFailed: 'ISIN save failed.',
            resultLimitReachedMessage: 'Over 400 search results found, please narrow your search criteria to see all results.',
            exportWarningMessage: '%{smart_count} records found. Please note: Attempting to export more than 3 million records may take several hours and result in a session timeout error. Please exclude ISINs with issue status = (D) delete and revise search parameters to avoid this situation.',
            resultCountMessage: '%{smart_count} record found |||| %{smart_count} records found.',
            buttons: {
                backToEdit: "Back",
                done: "Done",
                save: "Save",
            },
            missing: {
                message: "ISIN %{isin} does not exist in the ANNA Database",
                suggestCreate: 'Would you like to create the ISIN?',
                suggestChallenge: 'Initiate ISIN Validation Data Challenge?'
            },
            isinSearchTitle: 'ISIN Data Search',
            challengeAction: 'Challenge',
            editAction: 'Edit',
            editPageTitle: 'ISIN Data Search - ISIN',
            resetFilterAction: 'Clear search filters',
            loadingPrimary: 'Loading',
            loadingSecondary: '...',
            isinLenWrong: 'ISIN must be 12 Characters.'
        },
        challenge: {
            name: 'Challenge',
            create: 'New Data Challenge',
            fields: {
                ...isinFields,
                challengeISINs: 'ISIN(s) to Challenge',
                challengeISINsHelper: 'You can enter multiple ISINs',
                challengeComment: 'Challenge Details',
                uploadChallengeISINs: 'List of ISINs in a file',
                additionalDocuments: 'Attachments',
                challengePriority: 'Priority',
            },
            uploadChallengeISINsDescription: 'Upload additional ISINs as comma-separated list here',
            additionalDocumentsDescription: 'Add additional Documents here',
            buttons: {
                challenge: "Submit Challenge"
            },
            openTickets: "Challenges",
            specifyExpectedValues: 'Specify Expected Values',
            hideSpecifyExpectedValues: 'Hide Expected Values',
            openTicketExistsMessage: 'There is one open data challenge |||| There are %{smart_count} open data challenges',
            closedTicketExistsMessage: 'There is one closed data challenge |||| There are %{smart_count} closed data challenges',
            isinMissing: 'Please specify at least one ISIN, or use the Upload button below to provide a list of ISINs in a text file',
            loadingPrimary: 'Opening Data Challenge'
        },

        schedule: {
            fields: {
                schedule: "Runs on"
            },

            runNow: 'Run now'
        },

        audit: {
            fields: {
                // list
                [AUDIT.dateReceive]: "Date Received",
                [AUDIT.action]: "Process",
                [AUDIT.year]: "Year",
                [AUDIT.sourceLastUpdate]: "Input Source",
                [AUDIT.sequence]: "Sequence",
                [AUDIT.fileName]: "File Name",
                [AUDIT.reconFileName]: "Reconciliation File Name",
                [AUDIT.numberRecords]: "Total ISIN",
                [AUDIT.numberProcWithoutError]: "Without Errors",
                [AUDIT.numberProcWithError]: "With Errors",
                [AUDIT.numberRejected]: "Rejected",

                // upload
                [AUDIT.file]: "ISIN File"
            },

            title: 'Upload ISIN Records',
            browse: 'Browse',

            validate: 'Validate',
            upload: 'Upload',

            daily: 'Daily',
            master: 'Master',

            fileMissing: 'No file was selected. Please use the Browse button to select a file.',
            fileEmpty: 'Selected file is empty. Please use the Browse button to select another file.',
            fileSizeTooBig: 'The file submitted exceeds the allowable size limit of 15MB. Please reduce the size of the file.',
            fileUploadSizeHint: 'File size should not exceed %{maxfilesize} MB and should be .txt type with the following format:',
            fileUploadFormatHint: 'Daily file must be named [NNA]-[YYYY]-[MM]-[DD]-[000001].txt (e.g. US-2021-09-19-000001.txt), Master file must be named  [NNA]-[YYYY]-[MM]-[DD]-[MASTER].txt (e.g. US-2021-09-19-MASTER.txt)',
            typeNotSpecified: 'Type not specified',
            actionNotSpecified: 'Action not specified',

            created: {
                validate: 'The file has been validated. The ISIN data has not been saved to the database.',
                upload: 'Records have been saved to the database.'
            }
        },

        lei: {
            loading: "Loading data from GLEIF...",
            fromGleif: "Based on %{field}",
            leiFormatError: 'LEI code must have 20 digits or capital letters',
            leiFetchError: 'Cannot fetch LEI information: %{error}',
            leiMatchError: 'GLEIF database has a different value: `%{expectedValue}`'
        },

        portfolio: {
            started: "Portfolio download has started...",
            emptyForm: "Please select a file or paste ISINs for your portfolio request",
            wrongFile: "Only TXT, CSV, or TSV files are supported. File %{name} seems to be saved as %{extension}",

            hints: {
                file: "Upload a Text or CSV file with a list of ISINs",
                pasted: "Alternatively you can paste multiple ISINs (using any separator)"
            },

            fields: {
                pasted: "Paste a list of ISINs",
                file: ""
            }
        },
        cleanupisins: {
            title: "Cleanup ISINs",
            isinsFile: "Please select a file with ISIN Ids or paste ISIN Ids into the textfield for your deletion request",
            wrongFile: "Only TXT, CSV, or TSV files are supported. File %{name} seems to be saved as %{extension}",
            reasonRequired: "In case of physical deletion a reason must be provided.",

            hints: {
                file: "Upload a Text or CSV file with a list of ISINs",
                pasted: "Alternatively you can paste multiple ISINs (using any separator)"
            },
            logicalDelete: "logical delete ISINs",
            physicalDelete: "physical delete ISINs.",
            physicalDeleteWarning: "Attention! Isins will be physically deleted after 24 hours",
            logicalDeleteWarning: "Isins will be marked as deleted only.",
            askDelete: "Do you really want to delete these ISINs? ",

            fields: {
                pasted: "Paste a list of ISINs",
                file: ""
            }
        }
    },

    anna: {
        welcome: "Welcome to the ANNA Service Bureau",
        aboutUs: "About Us",
        about: "The ANNA Service Bureau (ASB) was founded by the Association of National Numbering Agencies (ANNA) " +
            "to collect and share ISIN and CFI information among its members through a common platform. " +
            "Operating as a central hub of financial instrument identifier information, the ASB receives and consolidates " +
            "ISINs and other standard identifiers contributed by a federated group of 116 national numbering agencies " +
            "providing ISIN coverage across more than 220 jurisdictions. " +
            "In addition to making this information available to numbering agencies, the ASB offers subscription data services for " +
            "non-member market participants.",
        email: {
            serverError: "Failed to submit the reset password email. Please contact your administrator.",
        },
        menu: {
            ISINAccess: {
                title: 'ISIN Access',
                items: {
                    ISINDataSearch: 'ISIN Data Search',
                    SubmittedInquiries: 'Challenge Tickets',
                    DataChallenge: 'Data Challenge',
                    DataChallengeAdmin: 'Challenge Admin',
                    portfolio: 'Portfolio'
                }
            },
            ISINRecordManagement: {
                title: 'ISIN Record Management',
                items: {
                    AddMaintainISINRecord: 'Add/Maintain ISIN Record',
                    UploadISINRecords: 'Upload ISIN records',
                    DataQualityReporting: 'Data Quality Reporting',
                    DataCompletenessScoring: 'Data Completeness Scoring',
                }
            },
            ASBAdministration: {
                title: 'ASB Administration',
                items: {
                    QuerybyCustomer: 'Query by Customer',
                    QueryByUser: 'Query by User',
                    AddNewCustomer: 'Add New Customer',
                    AddNewUser: 'Add New User',
                    cleanupISINs: 'Cleanup ISINs',
                    Currencies: 'Currencies',
                    Countries: 'Countries',
                    BatchActivity: 'Batch Activity'
                }
            },
            About: {
                title: 'Welcome',
                items: {
                    Login: 'Home',
                    AboutANNA: 'About ANNA',
                    AboutTheASB: 'About the ASB',
                    ContactUs: 'Contact Us',
                    FieldDefinitions: 'Field Definitions',
                }
            },
        },
        resetPassword: {
            title: 'Set Password',
            submit: 'Submit',
            requirements: 'The password must meet the following requirements:',
            mincharacters: 'Minimum of 8 characters and maximum of 25 characters',
            oneLowercase: 'Have at least one lowercase letter',
            oneUppercase: 'Have at least one uppercase letter',
            hasNumber: 'Contain at least one number',
            hasSymbol: 'Have at least one symbol - examples: /*!@#$%^&()\\"{}_[]|?<>,.',
            isConfirmed: 'The two passwords must match',
            newPassword: 'New password',
            passwordRetype: 'Retype new password',
            requestNewLink: 'Request new password reset link',
            resetpasswordRepeated: 'Cannot be the same as one of the previous 5 passwords',
            resetTokenExpired: 'The reset token has expired. Please request new password again.',
            resetSent: 'Password set.',
            resetMessage: 'Password successfully set, you can now login with your new password',
            backToLogin: 'back to login',
            backToLogin1: 'Password successfully set, click',
            backToLogin2: 'to login.',
            requirementsIssue: 'The provided password does not match the defined requirements.'
        },
        login: {
            title: 'Please sign in',
            labelEmail: 'Username [email address]',
            securityCode: 'Security code',
            usernamePasswordInvalid: 'Username and/or password invalid',
            userAccountDisabled: 'Your account is disabled',
            userAccountLocked: 'Too many login attempts. Please try again later.',
            sessionLocked: 'Too many login attempts. Please try again later.',
            forgotPassword: 'Forgot password?',
            wrongEmailAddress: 'wrong email address',
            passwordExpired: 'Your account Password has expired. Please request a password reset link.',
            twoFactorAuthenticationNeeded: 'Two factor authentication is needed. An Email is sent to you with a confirmation code.',
            providedSecurityCodeNotCorrect: 'Provided security code is not correct.',
            providedSecurityCodeExpired: 'Provided security code has expired.',
        },
        requestPasswordReset: {
            title: 'Forgot Password',
            expirePasswordTitle: 'Reset Password',
            requestResetText: 'Request Reset Password',
            backToLogin: 'Login',
            sentMessageTitle: 'Message Sent',
            sentMessage:
                'A message was sent to the email address associated with your account.\n' +
                'Please check your e-mail. Click the link in the e-mail to set a new password for your account.\n' +
                'To protect your privacy, we will send this information only to the e-mail address on file for this account.\n' +
                'If no email is received within ten minutes, check that the submitted address is correct.',
            wrongEmail: 'Wrong email address',
            instruction: 'Enter your email address below and a password reset link will be sent to you.',
            expiredPasswordExplanation: 'Your account password has expired. Please request a password reset link.',
            emailLabel: 'email address',
            noEmail: 'This email address does not exist.',
            missingEmail: 'Missing email address argument',
        },
        validation: {
            mandatoryField: 'For Instrument Category %{instrumentCategoryName} (%{instrumentCategoryCode}) this field is mandatory',
            notExpectedField: 'For ISIN Records with Instrument Category %{instrumentCategoryName} (%{instrumentCategoryCode}) this field is not expected',
            notExpectedFieldForInteresttype: 'For ISIN Records with type of interest %{interestTypeName} (%{interestTypeCode}) and Instrument Category %{instrumentCategoryCode} this field is not expected',
            missingExercisePriceCurrency: 'Please provide a valid Exercise Price Currency for Exercise Price: %{exercisePriceCurrency}',
            missingExercisePriceForExistingCurrency: 'An Exercise Price Currency without an Exercise Price was provided. The provided Exercise Price Currency %{exercisePriceCurrency} will be ignored',
            missingInterestRate: 'The Type of Interest is set to F (for Fixed) for this ISIN Record and as such an Interest Rate value is expected, but is not provided.',
            headLeiEqualIssuerLei: 'Issuer LEI received for for this record is equal to Head office LEI value. Please provide different LEI values in order to pass validation.',
            notExpectedInterestRate: 'The Type of Interest is set to Z (for Zero Coupon Instruments) and as such an Interest Rate can either be left empty or set to zero. Provided value: %{interestRate}',
            softMandatory: 'This field is strongly recommended',
            warningSuppressMessage: 'The validation can be suppressed with the icon',
            incorrectDate: 'Please enter a valid date.',
            incorrectIsoDate: 'ISO date format is expected: YYYY-MM-DD',
            dateTooEarly: 'Date must be from the year 1899 onwards.',
            cfiCode: 'CFI code must have 6 upper-case characters',
            unexpectedValue: 'The unexpected value `%{value}` was saved earlier. Please select another option.',
            wrongIsinStatusNew: 'Only status Updated or Deleted is allowed.',
            wrongIsinStatusNewIsin: 'Only status New is allowed for new ISINs.',
        },
        addIsin: {
            saveButtonLabel: 'Add or Open ISIN'
        },
        challenge: {
            ticketCreationFailed: 'Sending data challenge failed. Please contact support.',
            wrongAttachment: 'Only Excel, Word, text, PDF or images files are allowed.',
        }
    },

    component: {
        completenessScore: {
            andMore: ', and more...',
            recordScore: 'Record Score',
            improveScoreMessage: 'To improve the score complete the following field |||| To improve the score complete the following fields',
            highlightInfo: '(Fields which would improve the score are highlighted in yellow).',
        },
    },
    enums: {
        completenessScoreType: {
            SUFFICIENT: 'SUFFICIENT',
            GOOD: 'GOOD',
            VERY_GOOD: 'VERY GOOD',
            EXCELLENT: 'EXCELLENT',
            NOSCORE: 'NOSCORE'
        }
    }
};

export default customEnglishMessages;