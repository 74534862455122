
export function utcToLocalDateTimeISO(timeStamp: string): string {
    if (!timeStamp) return null;

    try {
        // parse correctly from UTC
        const utc = new Date(timeStamp + "Z");

        // There is no easy way to format JS Date object to match <text value/> expected format.

        // crazy hack to break time zone information
        utc.setMinutes(utc.getMinutes() - utc.getTimezoneOffset());
        // so that ISO string format will convert to UTC _again_, returning
        // local time with incorrect UTC zone mark
        // We then cut zone info off to accommodate to browser's expectation of date format
        // And with two symmetrical mistakes we get UTC time converted to Local time
        return utc.toISOString().slice(0, 16);
    } catch (e) {
        return null;
    }
}

export function utcToLocalDateTime(timeStamp: string): string {
    if (!timeStamp) return null;
    try {
        const utc = new Date(timeStamp + "Z");
        return utc.toLocaleDateString() + " " + utc.toLocaleTimeString();
    } catch (e) {
        return null;
    }
}