import React from "react";
import { Create, Edit, required, SaveButton, SimpleForm, TextField, TextInput, Toolbar } from 'react-admin';

export function CCEdit(props) {
    return <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<SaveOnlyNoDelete/>}>
            <TextField source="id"/>
            <TextInput source="description" validate={required()}/>
        </SimpleForm>
    </Edit>
}

const SaveOnlyNoDelete = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

export function CCCreate(props) {
    return <Create {...props}>
        <SimpleForm>
            <TextInput source="id" validate={required()}/>
            <TextInput source="description" validate={required()}/>
        </SimpleForm>
    </Create>
}