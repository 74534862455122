import { stringify } from 'query-string';
import { AuthProvider, DataProvider, fetchUtils, GetListParams, GetOneResult } from 'ra-core';
import gleif from "./gleif";
import AUDIT from "../model/InboundAudit";
import { submitPortfolioUpload } from "../portfolio/PortfolioUpload";


/**
 * Maps react-admin queries to a simple REST API
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=title&ascending=true&page=0&size=24
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => POST http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
export default (apiUrl: String, auth: AuthProvider, httpClient = fetchUtils.fetchJson): DataProvider => ({

    getList: async (resource, params) => {
        const query = queryFromParams(params);
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        // Spring will return redirect to /login if the session has expired
        // We want to treat this as error {redirect: "manual"}, which will be handled
        // by authProvider.checkError
        const result = await httpClient(url, { redirect: "manual" });
        const response = result.json;

        // Slow-changing tables can be cached on client side
        switch (resource) {
            // 'isin' or other fast-changing data must not be cached
            case 'lei':
                response.validUntil = cached(20);
        }

        return response;
    },

    getOne: (resource, params) => {
        const id = params.id;

        switch (resource) {
            case 'lei':
                if (!id || id.toString().length !== 20)
                    return Promise.resolve({ data: null }); //Promise.reject("Wrong LEI: " + id);

                return gleif(id)

            case 'challenge':
                if (id.toString().length !== 12) {
                    // not a single ISIN - the multi challenge form will be empty
                    return Promise.resolve({ data: { id: id } })
                } else {
                    // single-ISIN challenge: fetch existing ISIN details
                    return  getChallengeFromId(id, httpClient, apiUrl);
                }
        }

        return getOneFromId(resource, id, httpClient, apiUrl);
    },

    getMany: async (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        const result = await httpClient(url);
        return result.json;
    },

    getManyReference: async (resource, params) => {

        const query = queryFromParams(params);
        query.filter[params.target] = params.id;

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        const result = await httpClient(url);
        return result.json;
    },

    update: async (resource, params) => {
        const data = params.data;

        switch (resource) {
            case 'challenge':
                const attachments = await getAttachmentsAsJSON(data.additionalDocuments);
                const apiUrlSwitch = `${apiUrl}/${resource}`;
                let dataWithFiles = { attachments: attachments, ...data };

                if (!!data.uploadChallengeISINs) {
                    const fileData = await convertFileToBase64(data.uploadChallengeISINs)
                    const fileType = data.uploadChallengeISINs.rawFile.type;
                    dataWithFiles = {
                        ...dataWithFiles,
                        isinsFile: { fileName: 'isins', fileType: fileType, base64EncodedData: fileData }
                    }
                }

                const response = await httpClient(apiUrlSwitch, {
                    method: 'POST',
                    body: JSON.stringify(dataWithFiles)
                });

                // added fake id 0 to satisfy the react admin create interface.
                return { data: { id: 0 }, ...response.json };
            case 'ticketnotificationsettings':
                // In case we save the sittings for an notification, we don't need an ID, because the backend knows about the current user id.
                var jsonResponse = await httpClient(`${apiUrl}/${resource}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data)
                });
                // Just return a fake in case its successfull
                let newVar = { data: { id: 0 }, ...jsonResponse};
                return newVar
            case 'tickets':
                var jsonResponse = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data)
                });
                return { data: { id: 0 }, ...jsonResponse}
        }

        const apiUrlSwitch = `${apiUrl}/${resource}/${params.id}`;

        const { json } = await httpClient(apiUrlSwitch, {
            method: 'POST',
            body: JSON.stringify(params.data)
        });
        return json;
    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    // @ts-ignore
    create: async (resource, params) => {
        const data = params.data;
        switch (resource) {

            case 'portfolio':
                return submitPortfolioUpload();

            case 'cleanupisins':

                var dataWithFiles = {cleanupIsinsType: data.cleanupIsinsType, ...data}
                if(!!data.file){
                    const isinsFile = await getAttachmentAsJSON(data.file)
                    dataWithFiles = {...dataWithFiles, isinsFile: isinsFile}
                }

                const apiUrlSwitch = `${apiUrl}/${resource}`;
                const response = await httpClient(apiUrlSwitch, {
                    method: 'POST',
                    body: JSON.stringify(dataWithFiles)
                });

                return { data: { id: 0 , ...response.json} };

            case 'audit':
                data[AUDIT.fileName] = params.data.file.rawFile.name;

                const requestPayload = new URLSearchParams();
                requestPayload.append(AUDIT.action, data[AUDIT.action]);
                requestPayload.append(AUDIT.fileName, data[AUDIT.fileName]);
                const headers = new Headers();
                headers.set('Content-Type', 'application/octet-stream');
                const response2 = await httpClient(`${apiUrl}/${resource}?` + requestPayload.toString(), {
                    method: 'POST',
                    headers: headers,
                    body: params.data.file.rawFile,
                });

                // somehow this does not come in the POST response. Let's fake it here
                return {
                    data: {
                        id: 0,
                        dateReceive: new Date().toISOString(),
                        ...response2.json
                    }
                }

            case 'isin':
                // We are misusing the create form to also fetch existing ISINs (redirect to edit-form).
                // For this we need to fetch the existing ISIN data as well here.
                return getOneFromId(resource, params.data.id, httpClient, apiUrl);

            case 'countries':
            case 'currencies':
                await httpClient(`${apiUrl}/${resource}/${params.data.id}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                })
            case 'tickets':
                params.data.id = 0
                var jsonResponse = await httpClient(`${apiUrl}/${resource}/create`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                })
                return { data: { id: 0 }, ...jsonResponse}
        }

        // Other Create calls will not be delegated to backend.
        return { data: data };
    },

    delete: async (resource, params) => {
        const result = await httpClient(`${apiUrl}/${resource}/${params.id}`, { method: 'DELETE' });
        return { data: result.json };
    },

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});


const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
});

function queryFromParams(params: GetListParams) {
    return {
        sort: params.sort.field,
        ascending: params.sort.order === 'ASC',  // queryReducer.SORT_ASC
        page: params.pagination.page,
        size: params.pagination.perPage,
        filter: JSON.stringify(params.filter),
    };
}

async function getJsonRecord(apiUrl, resource, id, httpClient) {
    let url = `${apiUrl}/${resource}/${id}`;
    let {json} = await httpClient(url, {redirect: "manual"});
    return json;
}

async function getOneFromId(resource, id, httpClient, apiUrl): Promise<GetOneResult> {
    let json = await getJsonRecord(apiUrl, resource, id, httpClient);
    return { data: { id, ...json } };
}

async function getChallengeFromId(id, httpClient, apiUrl): Promise<GetOneResult> {
    let json = await getJsonRecord(apiUrl, 'isin', id, httpClient);
    return { data: { id, challengePriority : 'LOW', ...json } };
}

export function cached(durationMinutes: number = 20) {
    // client-side cache, because server doesn't properly set HTTP cache flags
    const validUntil = new Date();
    validUntil.setTime(validUntil.getTime() + durationMinutes * 60 * 1000);
    return validUntil;
}

async function getAttachmentsAsJSON(attachments) {
    const fileList: { fileName: string, fileType: string, base64EncodedData: any }[] = [];
    if (!attachments) {
        return []
    }
    for (let i = 0; i < attachments.length; i++) {
        const file = await getAttachmentAsJSON(attachments[i])
        fileList.push(file)
    }
    return fileList;
}

async function getAttachmentAsJSON(attachment) {
    const fileName = attachment.rawFile.name;
    const fileType = attachment.rawFile.type;
    const fileData = await convertFileToBase64(attachment);
    return { fileName: fileName, fileType: fileType, base64EncodedData: fileData }
}

