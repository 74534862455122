import React, { useEffect } from 'react';
import { Admin, Resource } from 'react-admin';

import { loadSettings } from './settings';
import dataProvider from './api/dataProvider';
import cachedDataProvider from './api/cachedDataProvider';
import authProvider, { csrfAwareHttpClient } from './api/authProvider';
import isin from './isin'
import portfolio from './portfolio'
import cleanupisins from './cleanupisins'
import tickets from './tickets'
import challenge from './challenge'
import countries from './countries'
import audit from './audit'
import { Layout } from './layout';
import customRoutes from './routes';
import theme from './layout/theme'
import LoginPage from "./login/LoginPage";
import i18nProvider from './i18n/i18nProvider';
import { ScheduleList } from "./admin/ScheduleList";
import { AnnaFooter } from "./layout/AnnaFooter";
import ticketadmin from "./ticketadmin";

const annaAuth = authProvider('/login', '/logout');
const annaApi = dataProvider('/anna/api/v1', annaAuth, csrfAwareHttpClient);


export default () => {
    useEffect(() => {
        // Once app is mounted we load the settings.
        loadSettings();
    }, []);

    return <>
        <Admin
            dataProvider={cachedDataProvider(annaApi)}
            authProvider={annaAuth}
            loginPage={LoginPage}
            theme={theme}
            layout={Layout}
            customRoutes={customRoutes}
            i18nProvider={i18nProvider}
        >
            <Resource name="isin" {...isin} />
            <Resource name="countries" {...countries} />
            <Resource name="currencies" {...countries} />
            <Resource name="audit" {...audit} />
            <Resource name="challenge" {...challenge} />
            <Resource name="portfolio" {...portfolio} />
            <Resource name="cleanupisins" {...cleanupisins} />
            <Resource name="tickets" {...tickets} />
            <Resource name="ticketadmin" {...ticketadmin} />

            {/* Hidden developer tool */}
            <Resource name="schedule" list={ScheduleList}/>

        </Admin>
        <AnnaFooter/>
    </>;
}
