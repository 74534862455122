import React, { ChangeEvent, useState } from 'react';

interface CustomFileInputProps {
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    extensions: string;
}
const CustomFileInput: React.FC<CustomFileInputProps> = ({ handleFileChange, extensions }) => {
    const [fileNames, setFileNames] = useState("No file chosen");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleFileChange(event);
        const files = event.target.files;
        if (files && files.length > 0) {
            setFileNames(Array.from(files).map(file => file.name).join(', '));
        } else {
            setFileNames("No file chosen");
        }
    };

    return (
        <div>
            <input
                type="file"
                id="file"
                onChange={handleChange}
                multiple
                accept={extensions}
                style={{ display: 'none' }}
            />
            <label htmlFor="file" style={{ display: 'inline-block', cursor: 'pointer', padding: '6px 12px', border: '1px solid #ccc', borderRadius: '4px', backgroundColor: '#f9f9f9' }}>
                Add Attachments
            </label>
            <span style={{ marginLeft: '10px' }}>{fileNames}</span>
        </div>
    );
};

export default CustomFileInput;
