import React from 'react';
import { withTypes } from 'react-final-form';
import { Button, Card, CardActions, CardContent, CircularProgress, } from '@material-ui/core';
import { Link, useLocation } from "react-router-dom";
import { parse } from 'query-string';
import passwordApiProvider from '../api/passwordApi';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSafeSetState, useTranslate } from 'ra-core';
import { email, required, TextInput, useNotify } from 'react-admin';

import './login.css';

interface FormData {
    email: string;
}

const { Form } = withTypes<FormData>();

const useStyles = makeStyles(() => ({
    input: {
        maxWidth: '20em'
    },
    mb: {
        marginBottom: '2rem',
    }
}));

const passwordApi = passwordApiProvider();

const RequestResetPassword = () => {
    const location = useLocation();
    const isPasswordExpired = parse(location.search).expired;
    const prefillEmail = parse(location.search).email;
    const notify = useNotify();
    const classes = useStyles();
    const translate = useTranslate();
    const [loading, setLoading] = useSafeSetState(false);
    const [requestSent, setRequestSent] = useSafeSetState(false);

    const emailValidator = email('anna.requestPasswordReset.wrongEmail');

    const validate = (values: FormData) => {
        const errors = { email: undefined };
        if (!values.email) {
            errors.email = translate('ra.validation.required');

        } else if (!!emailValidator(values.email, values)) {
            errors.email = translate('anna.requestPasswordReset.wrongEmail');
        }
        return errors;
    };

    const handleSubmit = async (values) => {
        setLoading(true);

        try {
            await passwordApi.forgot(values.email);
            setRequestSent(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notify(error.message, 'error');
        }
    }

    const renderResetForm = ({handleSubmit}) => {
        return <form onSubmit={handleSubmit} noValidate>
            <div className={classes.input}>
                <TextInput 
                    type="email"
                    id="email"
                    source="email"
                    label="anna.login.labelEmail"
                    initialValue={prefillEmail}
                    validate={[required(), email()]}
                    disabled={loading}
                    autoFocus
                    fullWidth
                />
            </div>
            <CardActions>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={20} thickness={2}/> : null}
                >
                    {translate('anna.requestPasswordReset.requestResetText')}
                </Button>
            </CardActions>
        </form>;
    };

    return <Card>
            <CardContent>
                {!requestSent && <>
                    <Typography variant="h5" className={classes.mb}>
                        {isPasswordExpired 
                        ? translate('anna.requestPasswordReset.expirePasswordTitle')
                        : translate('anna.requestPasswordReset.title')}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        {isPasswordExpired
                        ? translate('anna.requestPasswordReset.expiredPasswordExplanation')
                        : translate('anna.requestPasswordReset.instruction')}
                    </Typography>
                    
                    <Form
                        onSubmit={handleSubmit}
                        validate={validate}
                        render={renderResetForm}
                    />
                </>}
                {requestSent && <>
                    <Typography variant="h5" className={classes.mb}>
                        {translate('anna.requestPasswordReset.sentMessageTitle')}
                    </Typography>
                    <Typography color="textSecondary" className="textcontent" gutterBottom>
                        {translate('anna.requestPasswordReset.sentMessage')}
                    </Typography>
                    
                    <CardActions>
                        <Button color="primary"
                                component={Link}
                                to="/login">
                            {translate('anna.requestPasswordReset.backToLogin')}
                        </Button>
                    </CardActions>
                </>}
            </CardContent>
        </Card>;
};

export default RequestResetPassword;