import { FormDataConsumer } from "react-admin";
import React, { Children, cloneElement } from 'react';
import { anyMandatory, anyNotExpected, isRequired, shouldBeRendered } from "../validation/fieldValidation";
import { hasFieldData } from "../validation/fieldValidation";

/**
 * This Component checks if its child should be rendered and be indicated as required (Asterisk)
 *  based on the InstrumentCategory.
 */
const ConditionalInput = ({ children, ...props }) => {

    const childElement = Children.only(children);
    const source = props.source;
    // Is there any InstrumentCategory, where current field (source) should not be rendered.
    const possiblyNotExpected: boolean = anyNotExpected(source);
    const possibleMandatory: boolean = anyMandatory(source);

    if (props.resource === 'isin' && (possiblyNotExpected || possibleMandatory)) {
        return <FormDataConsumer {...props}>
            {({ formData }) => {
                const field = formData[source];
                const hasData = hasFieldData(field);

                if (shouldBeRendered(source, formData.instrumentCategory, formData.interestType, formData.preliminaryTerms) || hasData) {
                    props.required = isRequired(source, formData.instrumentCategory, formData.preliminaryTerms, formData.interestType, formData.preliminaryTerms, formData.isinStatus);
                    return cloneElement(childElement, {...props, source});
                } else {
                    return null; // works by mistake, producing ra-input-undefined
                    // return cloneElement(childElement, { ...props, source, variant: "outlined", disabled: true });
                }
            }}
        </FormDataConsumer>
    } else {
        // props.resource = resource;
        return cloneElement(childElement, {...props, source});
    }
};

export default ConditionalInput;

