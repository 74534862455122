import arrayMutators from "final-form-arrays";
import { submitErrorsMutators } from "final-form-submit-errors";
import { LeiFormMutators } from "./LeiInput";

// Copy of React-Admin FormWithRedirect.tsx, it might change in the future
const defaultMutators = {
    ...arrayMutators,
    ...submitErrorsMutators,
};

export const IsinFormMutators = {
    ...LeiFormMutators,
    ...defaultMutators
};