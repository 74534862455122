import InstrumentCategory from "../model/InstrumentCategory";
import InterestType from "../model/InterestType";
import ValidationRulesProvider from "./validationRules";
import MaturityTypes from "../model/MaturityTypes";
import { matchesPattern } from "./cfiMatcher";
import { SPECIFIC_DATE_INDICATOR } from "../isin/MaturityDateInput";

const validationRules = ValidationRulesProvider();
const cfiInterestTypeRules: Map<string, string[]> = validationRules.getInterestTypeRules();
const cfiMaturityDateRules: Map<string, string[]> = validationRules.getMaturityDateRules();

function isStartingWith(allowedPrefixes, cfiCode) {
    return allowedPrefixes.findIndex((cfiCodeSearch) => {
        return cfiCode.startsWith(cfiCodeSearch);
    }, cfiCode) === -1;
}

// Todo : AS translate.
export function validateCfiCodeInstrumentCategory(cfiCode: string, category: string, cfiVersion: string) {

    if (!cfiCode || !category) {
        return undefined;
    }
    const rules: Map<string, string[]> = validationRules.getCfiValidationRules(cfiVersion);
    const instrumentCat = InstrumentCategory[category];
    const allowedCfiPrefixes: string[] = rules.get(category);
    if (allowedCfiPrefixes && isStartingWith(allowedCfiPrefixes, cfiCode)) {
        return 'The Instrument Category ' + category + ' (' + instrumentCat + ')  is not consistent with the CFI Code provided: ' + cfiCode + ' (allowed CFI code prefixes: ' + allowedCfiPrefixes + ')';
    } else {
        return undefined;
    }
}

export function validateCfiCodeInterestType(cfiCode: string, interestTypeValue: string, instrumentCategoryValue): string {

    if (ignoreCfiInterestTypeValidation(cfiCode, interestTypeValue, instrumentCategoryValue)) {
        return undefined;
    }

    const interestType = InterestType[interestTypeValue];
    const pattern: string[] = cfiInterestTypeRules.get(interestTypeValue);

    if (matchesPattern(cfiCode, pattern)) {
        return undefined
    }

    return 'The Type of Interest ' + interestTypeValue + ' (' + interestType + ') is not consistent with the CFI Code provided. (Allowed : ' + pattern + ')';
}

export function validateCfiCodeStartingWithDM(cfiCode: string, instrumentCategoryValue): string {
    if(!cfiCode || !cfiCode.startsWith('DM')){
        return undefined
    }

    const instrumentCategory = InstrumentCategory[instrumentCategoryValue];

    if(instrumentCategory !== InstrumentCategory.DT && instrumentCategory !== InstrumentCategory.SP){
        return undefined
    }

    // For CFI codes that begin with DM, the only valid characters for the third position are B, P, M and X.
    if(!matchesPattern(cfiCode, ["DMB***","DMP***","DMM***"])){
        return 'For CFI codes that begin with DM, the only valid characters for the third position are B, P, M and X.!'
    }
    return undefined
}

function ignoreCfiInterestTypeValidation(cfiCode: string, interestTypeValue: string, instrumentCategoryValue): Boolean {
    if (!cfiCode || !interestTypeValue || cfiCode.length < 2 || cfiCode.length > 6) {
        return true;
    }

    const instrumentCategory = InstrumentCategory[instrumentCategoryValue];
    // Only crossvalidate for Debbt and Structured Products.
    if (InstrumentCategory.DT !== instrumentCategory && InstrumentCategory.SP !== instrumentCategory) {
        return true

    }

    // In case of Structured Products only validate when CFI starting with D, indicating a Debt.
    if (InstrumentCategory.SP === instrumentCategory && !cfiCode.startsWith('D')) {
        return true
    }

    // Only check against valid Instrument types. There are old/invalid values in the DB like 'N'
    if (!Object.keys(InterestType).includes(interestTypeValue)) {
        return true;
    }
    return false
}

export function validateCfiCodeMaturityDate(cfiCode: string, instrumentCategory: string, maturityDateCode: string, interestType: string): string {

    if (!cfiCode || !interestType || !instrumentCategory || maturityDateCode === SPECIFIC_DATE_INDICATOR) {
        return undefined;
    }

    if(InstrumentCategory[instrumentCategory] !== InstrumentCategory.DT && InstrumentCategory[instrumentCategory] !== InstrumentCategory.SP){
        return undefined
    }

    if(InstrumentCategory[instrumentCategory] === InstrumentCategory.SP && !cfiCode.startsWith('D')){
        return undefined
    }

    const pattern: string[] = cfiMaturityDateRules.get(maturityDateCode);

    if(!pattern){
        return undefined
    }

    if(matchesPattern(cfiCode, pattern)){
        return undefined
    }

    const maturityType = MaturityTypes[maturityDateCode];

    return 'The Maturity Date ' + maturityDateCode + ' (' + maturityType + ') is not consistent with the CFI Code provided. (Allowed : ' + pattern + ')';
}
