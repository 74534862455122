import FLD from "../model/ISIN";
import InstrumentCategory from "../model/InstrumentCategory";
import MaturityTypes from "../model/MaturityTypes";

export function maturityDateValidation(value: string, values) {
    const cfiCode = values[FLD.cfiCode]
    const instrumentCategory = values[FLD.instrumentCategory]
    if(!value || !cfiCode || cfiCode.length < 2 || !instrumentCategory || InstrumentCategory.DT !== InstrumentCategory[instrumentCategory]){
        return undefined
    }
    if(['DE','DS','DY'].includes(cfiCode.substr(0,2)) && ['P','Q','R'].includes(value)){
        return 'Maturity Date ' + value + ' (' + MaturityTypes[value] + ') is not allowed for CFI code ' + cfiCode
    }
    return undefined
}