import { Datagrid, DateField, List, Pagination, TextField, Filter, TextInput, DateInput } from "react-admin";
import LinkField from "./LinkField";
import React from "react";
import AUDIT from "../model/InboundAudit";
import AuditUpload from "./AuditUpload";
import ReconreportField from "./ReconreportField";
import './AuditList.css'


const AuditFilter = (props) => (
    <Filter  {...props}>
        <TextInput label="File Name" source="fileName" alwaysOn/>
        <TextInput label="Sequence" source="sequence" alwaysOn/>
        <DateInput label="Date Receive From" source="dateReceiveFrom" alwaysOn/>
        <DateInput label="Date Receive To" source="dateReceiveTo" alwaysOn/>
    </Filter>
);

export default (props) => <React.Fragment>

    <AuditUpload {...props} title="resources.audit.title"/>

    <br/>

    <List title=" "
          filters={<AuditFilter />}
          perPage={25}
          bulkActionButtons={false}
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 400]} />}
          actions={false /* remove Create and Export buttons */}
          {...props}>

        <Datagrid rowClick="expand" optimized>

            <DateField source={AUDIT.dateReceive}
                       sortable={true}
                       showTime/>

            <TextField source={AUDIT.action}
                       sortable={false}/>

            <TextField source={AUDIT.year}
                       sortable={false}/>

            <TextField source={AUDIT.sourceLastUpdate}
                       sortable={false}/>

            <TextField source={AUDIT.sequence}
                       sortable={true}/>

            <LinkField source={AUDIT.fileName}
                       sortable={true}
                       href={(record) => {
                           const action = record[AUDIT.action] ? record[AUDIT.action] : "process";
                           return "/anna/api/v1/audit/" + record[AUDIT.id] + "/" + action + "/report";
                       }}/>

            <ReconreportField source={AUDIT.reconFileName}
                              action={(record) => {return record[AUDIT.action]}}
                              id={(record) => {return record[AUDIT.id]}}
                              reconFileName={(record) => {return record[AUDIT.reconFileName]}}
                       />

            <TextField source={AUDIT.numberRecords}
                       sortable={false}/>

            <TextField source={AUDIT.numberProcWithoutError}
                       sortable={false}/>

            <TextField source={AUDIT.numberProcWithError}
                       sortable={false}/>

            <TextField source={AUDIT.numberRejected}
                       sortable={false}/>
        </Datagrid>

    </List>
</React.Fragment>