import React from "react";
import { useLocation } from 'react-router-dom'
import { Title } from "react-admin";
import "./admin.css";
import { LoadingAnimation, removeLoadingAnimation } from "./LoadingAnimation";

export default function Admin() {
    const location = useLocation();

    // React is reasonably smart about re-rendering components, and will not call this function too often
    // only when actual navigation happens because user clicked on the menu.
    // In this case we'll create new DOM iframe element, deleting the old one
    // so that `src` will be reloaded properly

    return <>
        <Title title="ASB Administration"/>
        <div className="container">
            <LoadingAnimation/>
            <iframe title="ANNA User Admin"
                    key={Math.random()} // force refresh to allow clicking on the same menu item
                    src={location.pathname}
                    onLoad={removeLoadingAnimation}
            />
        </div>
    </>
}