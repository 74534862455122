import React from "react";
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const ErrorMessage = ({ ...props }) => {
    const errorStyles = makeStyles({
        message: {
            color: "red",
            marginTop: "2rem"
        },
    });

    const style = errorStyles();

    return props.message && <Typography className={style.message}
                                        gutterBottom>{props.message}</Typography>
};

export default ErrorMessage;