import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { useSafeSetState, useTranslate } from 'ra-core';
import { required, TextInput } from 'react-admin';
import { Button, Card, CardActions, CardContent, CircularProgress, Typography, } from '@material-ui/core';

import './login.css';
import ErrorMessage from "./ErrorMessage";
import validateApiProvider from '../validation';
import PasswordValidator from "./PasswordValidator";
import passwordApiProvider from '../api/passwordApi';

interface FormData {
    password: string;
    passwordretype: string;
}

const useStyles = makeStyles(() => ({
    input: {
        maxWidth: '20em'
    },
    mb: {
        marginBottom: '2rem',
    }
}));

const passwordApi = passwordApiProvider();

function ResetPassword({ token }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [resetSent, setResetSent] = useSafeSetState(false);
    const [errorMessage, setErrorMessage] = useSafeSetState('');


    const valid = {
        minCharacters: false,
        oneLowercase: false,
        oneUppercase: false,
        hasNumber: false,
        hasSymbol: false,
        allMeet: false,
        retypeValid: false
    };
    const translate = useTranslate();
    const validateApi = validateApiProvider();

    function validate(values: FormData) {
        const errors = { password: undefined, passwordretype: undefined };

        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        if (!values.passwordretype) {
            errors.passwordretype = translate('ra.validation.required');
        }
        const empty = values.password === undefined;
        valid.minCharacters = !empty && values.password.length >= 8;
        valid.oneLowercase = !empty && validateApi.hasLowerCase(values.password);
        valid.oneUppercase = !empty && validateApi.hasUpperCase(values.password);
        valid.hasNumber = !empty && validateApi.hasNumber(values.password);
        valid.hasSymbol = !empty && validateApi.hasSymbol(values.password);
        valid.retypeValid = !empty && values.passwordretype !== undefined && values.password === values.passwordretype;

        valid.allMeet =
            valid.minCharacters &&
            valid.oneLowercase &&
            valid.oneUppercase &&
            valid.hasNumber &&
            valid.hasSymbol &&
            valid.retypeValid;

        return errors;
    }

    async function handleSubmit(values) {
        setLoading(true);
        try {
            await passwordApi.reset(token, values.password);
            // todo replace with a Notification pop-up?
            setResetSent(true);
            setErrorMessage('');
            setLoading(false);
        } catch (error) {
            setIsTokenExpired(true);
            setErrorMessage(translate(error.message));
            console.log(error);
            setLoading(false);
        }
    }

    function renderResetForm({ handleSubmit }) {
        return <form onSubmit={handleSubmit} noValidate>
            <PasswordValidator mincharacters={valid.minCharacters}
                               oneLowercase={valid.oneLowercase}
                               oneUppercase={valid.oneUppercase}
                               hasNumber={valid.hasNumber}
                               hasSymbol={valid.hasSymbol}
                               isConfirmed={valid.retypeValid}/>
            <div className={classes.input}>
                <TextInput
                    type="password"
                    id="password"
                    source="password"
                    label={translate('anna.resetPassword.newPassword')}
                    validate={[required()]}
                    disabled={loading}
                    autoComplete="new-password"
                    autoFocus
                    fullWidth
                />
                <TextInput
                    type="password"
                    id="passwordretype"
                    source="passwordretype"
                    label={translate('anna.resetPassword.passwordRetype')}
                    validate={[required()]}
                    disabled={loading}
                    fullWidth
                />
            </div>
            <ErrorMessage message={errorMessage}/>
            <CardActions>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!valid.allMeet || loading}
                    startIcon={loading ? <CircularProgress size={20} thickness={2}/> : null}
                >
                    {translate('anna.resetPassword.submit')}
                </Button>
                {isTokenExpired &&
                <Button color="primary"
                        component={Link}
                        to="/requestPasswordReset">
                    {translate('anna.resetPassword.requestNewLink')}
                </Button>
                }
            </CardActions>
        </form>;
    }

    return <Card>
        <CardContent>
            {!resetSent && <>
                <Typography variant="h6" gutterBottom>
                    {translate('anna.resetPassword.title')}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    {translate('anna.resetPassword.requirements')}
                </Typography>

                <Form
                    onSubmit={handleSubmit}
                    validate={validate}
                    render={renderResetForm}
                />
            </>}
            {resetSent && <>
                <Typography variant="h6" gutterBottom>
                    {translate('anna.resetPassword.resetSent')}
                </Typography>
                <Typography color="textSecondary" className="textcontent" gutterBottom>
                    {translate('anna.resetPassword.resetMessage')}
                </Typography>
                <CardActions>
                    <Button color="primary"
                            component={Link}
                            to="/login">
                        {translate('anna.resetPassword.backToLogin')}
                    </Button>
                </CardActions>
            </>}
        </CardContent>
    </Card>;
}

export default ResetPassword;
