import React from "react";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';

const OverrideButton = ({ onClick }) => <InputAdornment position="end">
    <IconButton
        disableRipple
        onClick={onClick}>

        <WarningIcon color="secondary"/>

    </IconButton>
</InputAdornment>

function createOverrideButton(visible: boolean, handleClickClearButton: (Event) => void) {
    return { endAdornment: visible && <OverrideButton onClick={handleClickClearButton}/> }
}

export default createOverrideButton;
