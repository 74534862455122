import React, { useState } from "react";
import createDecorator from 'final-form-focus';
import { CardContent, Toolbar } from '@material-ui/core';
import { FormWithRedirect, SaveButton } from 'react-admin';

import IsinFields from "./IsinFields";
import { IsinFormMutators } from "./IsinFormMuatators";
import { CompletenessScoreElement } from "./CompletenessScoreElement";
import { Full, Row } from "../layout/LayoutComponents";
import {
    validateExercisePrice,
    validateInterestRateContent,
    validateMandatoryFields,
    validateDoublicated
} from "../validation/fieldValidation";
import { getCompletenessScoreForValues, getFieldsForValues, getFieldsToImproveForScore } from "./CompletenessScore";
import CompletenessScoreType from "../model/CompletenessScoreType";
import { SPECIFIC_DATE_INDICATOR } from "./MaturityDateInput";

const focusOnError = createDecorator();

/**
 * Any transformations or cleanup before ISIN goes to the server
 */
export function cleanupIsinBeforeSave(isin) {
    if (isin.maturityDateCode !== SPECIFIC_DATE_INDICATOR) {
        // So that server won't have to deal with 2 conflicting json property values:
        // e.g.: { maturityDate: '2020-01-01', maturityDateCode: 'Q' }
        isin.maturityDate = null;
    }
    return isin
}

export const validateAll = (values, errors) => {
    validateMandatoryFields(values, errors);
    validateExercisePrice(values, errors);
    validateInterestRateContent(values, errors);
    validateDoublicated(values, errors);
};

const IsinForm = (props) => {

    const [completenessScore, setCompletenessScore] = useState(CompletenessScoreType.EXCELLENT);
    const [fieldsToImprove, setFieldsToImprove] = useState([]);

    const validateIsinForm = (values) => {

        const errors = {} as any;
        validateAll(values, errors);

        computeCompletenessScore(values)

        return errors;
    };

    function computeCompletenessScore(values) {
        if(!values.instrumentCategory){
            setFieldsToImprove(undefined)
            return
        }

        const score = getCompletenessScoreForValues(values);

        setCompletenessScore(score);

        const fields = getFieldsForValues(values);
        const newFieldsToImprove = getFieldsToImproveForScore(fields, values, score);
        if (JSON.stringify(newFieldsToImprove) !== JSON.stringify(fieldsToImprove)) {
            setFieldsToImprove(newFieldsToImprove);
        }
    }

    function renderForm({ resource, pristine, saving, handleSubmitWithRedirect, invalid }) {
        return <form>
            {fieldsToImprove && <Row>
                <Full><CompletenessScoreElement fieldsToImprove={fieldsToImprove} score={completenessScore}/></Full>
            </Row>}
            <IsinFields resource={resource} record={props.record} fieldsToImprove={fieldsToImprove} challenge={false}/>

            <Toolbar>
                <SaveButton
                    disabled={pristine}
                    saving={saving}
                    submitOnEnter={true}
                    label={"resources.isin.buttons.save"}
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    color={"secondary"}
                    invalid={invalid}
                />
            </Toolbar>

        </form>
    }

    return <CardContent /* add margins */ >
        <FormWithRedirect
            decorators={[focusOnError]}
            validate={validateIsinForm}
            validateOnBlur // Only perform validation when leaving a field
            mutators={IsinFormMutators} // necessary for LeiDataInput
            render={renderForm}
            {...props}
        />
    </CardContent>;
};

export default IsinForm;