export const ticketStatus = [
    { id: 'OPEN', name: 'Open' },
    // { id: 'ACCEPTED', name: 'Accepted' },
    { id: 'INPROGRESS', name: 'In progress' },
    { id: 'CLOSED', name: 'Closed' },
];
const ticketTypes = [
    { id: 'OWNER', name: 'Owner' },
    { id: 'ASSIGNEE', name: 'Assignee' },
];
