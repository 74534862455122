import { AuthProvider, fetchUtils } from 'ra-core';
import callPostRequest from './callPostRequest';

export const KEY_USER = 'ANNA_username';
// export const KEY_ROLES = 'ANNA_permissions';
export const KEY_ANNA_COUNTRIES = 'ANNA_countries';
const CSRF_TOKEN = 'csrf_token';

interface Csrf {
    headerName: string;
    token: string;
    parameterName: string;
}

export function getCsrfToken(): Csrf {
    try {
        return JSON.parse(localStorage.getItem(CSRF_TOKEN));
    } catch (error) {
        console.log('Could not parse csrf token', error);
        return undefined;
    }
}

export async function refreshCsrfToken() {
    try {
        const csrf = await (await fetch('/csrf-token')).text();
        localStorage.setItem(CSRF_TOKEN, csrf);
        return csrf;
    } catch (error) {
        console.warn('Something went wrong while retrieving /csrf-token', error);
        return null;
    }
}

export default (loginUrl: string, logoutUrl: string): AuthProvider => ({

    login: async (args): Promise<any> => {
        try {
            // first we need to retrieve the csrf-token
            await refreshCsrfToken();

            // x-www-form-urlencoded
            const result = await callPostRequest(loginUrl, args);
            if (!result.success) {
                return Promise.reject("Unexpected response: " + result.status);
            }
            localStorage.setItem(KEY_USER, args.username);
            // localStorage.setItem(KEY_ROLES, JSON.stringify(result.roles));
            localStorage.setItem(KEY_ANNA_COUNTRIES, JSON.stringify(result.countries));

            // after a successful login we have a new session. We thus need to refresh the CSRF token.
            await refreshCsrfToken();

            return Promise.resolve(result.roles);
        } catch (e) {
            return Promise.reject(e)
        }
    },

    logout: async (): Promise<string> => {
        console.debug("Logging out");

        localStorage.removeItem(KEY_USER);
        // localStorage.removeItem(KEY_ROLES);
        localStorage.removeItem(KEY_ANNA_COUNTRIES);

        try {
            await csrfAwareHttpClient(logoutUrl, { method: 'POST' });
        } catch (e) {
            // ignore all errors
        }

        // The csrf token needs to be removed after the request to logout as it is needed for the post request.
        localStorage.removeItem(CSRF_TOKEN);
        // redirect to main page instead of default /login
        return Promise.resolve("/");
    },

    checkError: async (error) => {
        /** This callback is called whenever API returns an HTTP error */

        if (error.status === 401 || error.status === 403 || error.status === 302 || error.status === 0) {
            // It means server does not accept a cookie
            // So the browser information is obsolete
            // Logout and require a new login
            console.warn("Session cookie has expired? Access error", error);
            // this.logout({});
            return Promise.reject();
        }

        // Other errors are not authentication problems
        return Promise.resolve();
    },

    checkAuth: async () => {
        // Default: require login before calling API.
        // check that locally we think user is logged in and later try calling API (given browser cookie)
        // if cookie has expired or is invalid, `checkError` will be called
        return localStorage.getItem(KEY_USER) ? Promise.resolve() : Promise.reject();
    },

    getPermissions: async () => {
        const permissions = await (await fetch('/permissions')).text();
        return Promise.resolve(JSON.parse(permissions) || {});
    }
});

export function csrfAwareHttpClient(url, options: fetchUtils.Options = {}): any {
    if (!options.headers) {
        options.headers = new Headers({});
    }

    const csrf = getCsrfToken();

    if (csrf && csrf.token && csrf.headerName) {
        (options.headers as Headers).set(csrf.headerName, csrf.token);
    }

    return fetchUtils.fetchJson(url, options);
}
