// fields of com.six.anna.common.model.InboundAudit
enum AUDIT_FIELD {
    id = "id",
    dateReceive = "dateReceive",
    status = "status",  // todo missing
    action = "action",  // todo missing
    year = "year",
    sourceLastUpdate = "sourceLastUpdate",
    sequence = "sequence",
    fileName = "fileName",
    reconFileName = "reconFileName",
    numberRecords = "numberRecords",
    numberProcWithoutError = "numberProcWithoutError",
    numberErrors = "numberErrors",
    numberRejected = "numberRejected",
    numberProcWithError = "numberProcWithError",
    customerId = "customerId",
    dateLastUpdate = "dateLastUpdate",

    // for upload
    file = "file"
}

export default AUDIT_FIELD