// https://material-ui.com/customization/default-theme/

export const sidebarSize = 300;
export const sidebarClosedWidth = 55;

const theme = {

    sidebar: {
        width: sidebarSize,
        closedWidth: sidebarClosedWidth,
    },

    palette: {
        // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=3f50b5&secondary.color=FF80AB&secondary.text.color=000000
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffb2dd',
            main: '#ff80ab',
            dark: '#8e0038',
            contrastText: '#000000',
        },
        text: {
            // The most important text.
            primary: 'rgba(0, 0, 0, 1)', // rgba(0, 0, 0, 0.87)
            // disabled: 'rgba(0, 0, 0, 0.80)' // rgba(0, 0, 0, 0.38)
        }
    },

    overrides: {
        RaDatagrid: {
            headerCell: {
                // make all table headers look different
                backgroundColor: "#d3d3d3" // just like nagivation color
            }
        }
    }

};

export default theme;