import { Dispatch, SetStateAction, useEffect, useState } from "react";

// https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go

function usePersistedState<S>(key: string, defaultValue: S): [S, Dispatch<SetStateAction<S>>] {

    const [state, setState] = useState(
        () => JSON.parse(localStorage.getItem(key)) || defaultValue
    );

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
}

export default usePersistedState;