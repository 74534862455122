import React, { useCallback, useEffect } from 'react';

interface WindowFocusListenerProps {
    onFocus: () => void;
    children?: React.ReactNode;
}

const WindowFocusListener: React.FC<WindowFocusListenerProps> = ({ onFocus, children }) => {
    const handleFocus = useCallback(() => {
        onFocus();
    }, [onFocus]);

    useEffect(() => {
        window.addEventListener('focus', handleFocus);
        return () => {
            window.removeEventListener('focus', handleFocus);
    };
    }, [handleFocus]);
    return <>{children}</>
};

export default WindowFocusListener