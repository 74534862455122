import FLD from "../../model/ISIN";
import FIELD_TYPE from "../FieldType";

export default {
    [FLD.interestRate]: {
        "F": FIELD_TYPE.M,
        "Z": FIELD_TYPE.O,
        "V": FIELD_TYPE.O,
        "C": FIELD_TYPE.N,
        "K": FIELD_TYPE.N,
        "D": FIELD_TYPE.O,
        "Y": FIELD_TYPE.N,
        "M": FIELD_TYPE.O,
    },
    [FLD.interestFrequency]: {
        "F": FIELD_TYPE.O,
        "Z": FIELD_TYPE.N,
        "V": FIELD_TYPE.O,
        "C": FIELD_TYPE.O,
        "K": FIELD_TYPE.O,
        "D": FIELD_TYPE.O,
        "Y": FIELD_TYPE.N,
        "M": FIELD_TYPE.O,
    },
    [FLD.interestPayMonth]: {
        "F": FIELD_TYPE.O,
        "Z": FIELD_TYPE.N,
        "V": FIELD_TYPE.O,
        "C": FIELD_TYPE.O,
        "K": FIELD_TYPE.O,
        "D": FIELD_TYPE.O,
        "Y": FIELD_TYPE.N,
        "M": FIELD_TYPE.O,
    },
    [FLD.interestPayDay]: {
        "F": FIELD_TYPE.O,
        "Z": FIELD_TYPE.N,
        "V": FIELD_TYPE.O,
        "C": FIELD_TYPE.O,
        "K": FIELD_TYPE.O,
        "D": FIELD_TYPE.O,
        "Y": FIELD_TYPE.N,
        "M": FIELD_TYPE.O,
    },
    [FLD.interestFirstPayDate]: {
        "F": FIELD_TYPE.O,
        "Z": FIELD_TYPE.N,
        "V": FIELD_TYPE.O,
        "C": FIELD_TYPE.O,
        "K": FIELD_TYPE.O,
        "D": FIELD_TYPE.O,
        "Y": FIELD_TYPE.N,
        "M": FIELD_TYPE.O,
    }
}
