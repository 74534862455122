import { getCsrfToken } from "./authProvider";

function getHeaders() {
    const csrfToken = getCsrfToken();
    const headers = {
        'Accept': 'application/json',
        // This is required because of the way spring deals with credentials
        'Content-Type': "application/x-www-form-urlencoded",
    };
    if (csrfToken && csrfToken.token && csrfToken.headerName) {
        headers[csrfToken.headerName] = csrfToken.token;
    }
    return headers;
}

export default async function callPostRequest(url: string, form: any): Promise<any> {
    const requestPayload = new URLSearchParams();
    for (let key in form) {
        if (!form.hasOwnProperty(key)) continue;

        let value = form[key];
        requestPayload.append(key, value);
    }
    
    const request = new Request(url, {
        method: 'POST',
        headers: getHeaders(),
        body: requestPayload
    });
    
    const response = await fetch(request, { redirect: "manual" });
    if (response.status < 200 || response.status >= 300) {
        let errorMessage;
        try{
            errorMessage = (await response.json()).message;
        } catch (e) {
            console.log(e);
        }
        throw new Error(errorMessage || response.statusText || 'ra.notification.http_error');
    }
    
    let responseJson = await response.json();
    if (!responseJson.success) {
        throw new Error(responseJson.message);
    }
    return responseJson;
}

