import FLD from "../model/ISIN";
import {
    validateCfiCodeInstrumentCategory,
    validateCfiCodeInterestType,
    validateCfiCodeMaturityDate,
    validateCfiCodeStartingWithDM
} from "./cfiValidation";

const isDeleteStatus = (values) => {
    let isinStatus = values[FLD.isinStatus];
    return isinStatus === 'D'

}

export const cfiValidater = (value: string, values) => {
    if(!isDeleteStatus(values)){
        if (!value) {
            return 'ra.validation.required';
        }
        if(!/^[A-Z]{6}$/.test(value)){
            return 'anna.validation.cfiCode'
        }
        return hardCfiValidation(value, values)
    }
    return undefined
}

export const fisnValidater = (value: string, values) => {
    if(!isDeleteStatus(values)){
        if(!value) {
            return 'ra.validation.required';
        }
        if(value.length > 35) {
            return 'FISN must be 35 characters or less';
        }
    }
    return undefined;
};

export function cfiValidationAll(cfiValue: string, cfiVersion: string, interestType: string, instrumentCategory: string, maturityDateCode: string) {
    return validateCfiCodeInstrumentCategory(cfiValue, instrumentCategory, cfiVersion) ||
        validateCfiCodeInterestType(cfiValue, interestType, instrumentCategory) ||
        validateCfiCodeMaturityDate(cfiValue, instrumentCategory, maturityDateCode, interestType) ||
        validateCfiCodeStartingWithDM(cfiValue, instrumentCategory);
};

function hardCfiValidation(value: string, values) {
    let instrumentCategory = values[FLD.instrumentCategory];
    return cfiValidationAll(value, values[FLD.cfiVersion], values[FLD.interestType], instrumentCategory, values[FLD.maturityDateCode])
}
