import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useRedirect, useTranslate, } from 'react-admin';
import { checkResponsibility } from "../validation/isinValidation";
import { Alert } from "@material-ui/lab";

export default function MissingIsinDialog({ isin, filterValues }) {
    const [open, setOpen] = React.useState(true);

    const redirect = useRedirect();
    const translate = useTranslate();

    const responsible: boolean = checkResponsibility(isin);

    const missingIsinTitle = translate('resources.isin.missing.message', { isin });

    const dialogMessage = translate((responsible
        ? 'resources.isin.missing.suggestCreate'
        : 'resources.isin.missing.suggestChallenge'));

    function handleClose() {
        setOpen(false);
    }

    function gotoEdit() {
        if (responsible) {
            redirect('edit', '/isin', isin, filterValues);
        } else {
            redirect('edit', '/challenge', isin);
        }
    }

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
            <Alert severity='warning'>
                {missingIsinTitle}
            </Alert>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {dialogMessage}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                {translate('ra.message.no')}
            </Button>
            <Button onClick={gotoEdit} color="primary">
                {translate('ra.message.yes')}
            </Button>
        </DialogActions>
    </Dialog>
}
