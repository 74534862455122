import React from "react";
import { useReference, } from 'ra-core';
import get from 'lodash/get';
import { Typography } from "@material-ui/core";

export function ShowFieldReference({ reference, projection = "description", ...props }) {
    const record = props.record
    const id = get(record, props.source);

    const { loaded, referenceRecord } = useReference({
        reference: reference,
        id: id,
    });

    let explanation = "";
    if (loaded) {
        const description = get(referenceRecord, projection);
        if (description) {
            explanation = " - " + description;
        }
    }
    return <Typography variant={"body2"}>
        {id}{explanation}
    </Typography>
}