import React, { useState } from 'react';
import { useNotify, useRefresh, useTranslate } from 'ra-core';
import { Create, FileInput, FunctionField, required, SaveButton, SimpleForm, Toolbar } from 'react-admin';
import AUDIT from "../model/InboundAudit";
import { hasFieldData } from "../validation/fieldValidation";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ValidationResultsDialog } from "../isin/IsinValidationDialog";
import { Alert } from "@material-ui/lab";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";


const useStyles = makeStyles({
    alertBox: {
        width: "auto"
    },
    alertBoxTitle: {
        fontSize: "1.1em"
    },
    alertBoxBody: {
        fontSize: "0.9em"
    },
});

const AuditUpload = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();
    const classes = useStyles();

    const maxSize = 15 * 1024 * 1024;

    function translateFieldData(field) {
        if (Array.isArray(field)) {
            let translatedFields = field.map(item => translate(item))
            return translatedFields
        }
        if(field)
            return [translate(field)];
    }

    function ShowHint() {
        const fileUploadSizeHint = translate("resources.audit.fileUploadSizeHint", { maxfilesize: Math.floor(maxSize/1000000) });
        const fileUploadFormatHint = translate("resources.audit.fileUploadFormatHint", { maxfilesize: Math.floor(maxSize/1000000) });
        return <Alert className={classes.alertBox} severity="info"><AlertTitle className={classes.alertBoxTitle}>Info</AlertTitle>
            <Typography className={classes.alertBoxBody}>{fileUploadSizeHint}</Typography>
            <Typography className={classes.alertBoxBody}>{fileUploadFormatHint}</Typography>
        </Alert>
    }

    const [responseState, setResponseState] = useState();

    const closeDialog = () => {
        setResponseState(undefined)
    };

    function checkFileSize(file): string {
        if (!file || !file.rawFile) return undefined;
        const fileSize = file.rawFile.size;
        if (fileSize && fileSize > maxSize)
            return translate("resources.audit.fileSizeTooBig", { fileSize, maxSize });
        return undefined;
    }

    function onUploadSuccess(response, action) {
        const { success, errors, warnings } = response.data;
        response.data.errors =  translateFieldData(errors);
        response.data.warnings = translateFieldData(warnings);

        if (!success || hasFieldData(errors) || hasFieldData(warnings)) {
            setResponseState(response.data)
        } else {
            notify('resources.audit.created.' + action, 'info');
        }

        // reload the table, erase the upload form
        refresh();
    }

    function PostCreateToolbar(props) {

        return <Toolbar {...props}>

            <SaveButton
                icon={<CheckCircleIcon/>}
                label='resources.audit.validate'
                submitOnEnter={true}
                transform={data => ({ ...data, action: 'validate' })}
                onSuccess={(resp) => onUploadSuccess(resp, 'validate')}
            />

            <SaveButton
                label='resources.audit.upload'
                submitOnEnter={false}
                transform={data => ({ ...data, action: 'upload' })}
                onSuccess={(resp) => onUploadSuccess(resp, 'upload')}

            />

        </Toolbar>;
    }


    return <>
        <ValidationResultsDialog responseState={responseState}
                                 onClose={closeDialog}
        />

        <Create {...props}>
            <SimpleForm toolbar={<PostCreateToolbar/>}>

                <FileInput
                    fullWidth
                    source={AUDIT.file}
                    type="file"
                    variant="outlined"
                    validate={[required(),checkFileSize]}
                    accept={"text/plain"}
                    multiple={false}>

                    <FunctionField source={AUDIT.file} // used for sorting
                                   label={AUDIT.file}
                                   render={file => {
                                       return file.rawFile.name;
                                   }}
                    />

                </FileInput>

                <ShowHint/>

            </SimpleForm>

        </Create>
    </>;
};

export default AuditUpload;