import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid";
import './about.css';
import { Title } from 'react-admin';

export default function Contact() {

    const FirmContact = (props) => {
        return (<Card className="Card">
                <CardContent>
                    <div className="textcontentlarger">
                        {props.name}<br/>
                        {props.adress1}<br/>
                        {props.adress2}<br/>
                    </div>
                </CardContent>
            </Card>
        );
    };

    const PersonContact = (props) => {
        return (<Card className="Card">
                <CardContent>
                    <div className="textcontent">
                        {props.name}<br/>
                        {props.role}<br/>
                        Telephone {props.phone}<br/>
                        <a href={'mailto:' + props.email}>{props.email}</a><br/>
                    </div>
                </CardContent>
            </Card>
        );
    };
    
    const PersonContactNoPhone = (props) => {
        return (<Card className="Card">
                <CardContent>
                    <div className="textcontent">
                        {props.name}<br/>
                        {props.role}<br/>                      
                        <a href={'mailto:' + props.email}>{props.email}</a><br/>
                    </div>
                </CardContent>
            </Card>
        );
    };

    const SupportContact = (props) => {
        return (<Card className="Card">
                <CardContent>
                    <div className="textcontent">
                        Support<br/>
                        <a href={'mailto:' + props.email}>{props.email}</a><br/>
                        {props.lines.map(item => (
                            <div>{item}</div>
                        ))}<br/>
                    </div>
                </CardContent>
            </Card>
        );
    };

    return <>
        <Title title="ANNA Service Bureau Contacts"/>
        <Grid item xs>
            <Grid container spacing={2}>
                <Grid item xs>
                    <CardContent>
                        <FirmContact name="CUSIP Global Services" adress1="45 Glover Avenue"
                                        adress2="Norwalk, CT 06850"/>
                        <PersonContactNoPhone name="Scott J. Preiss" role="Senior Vice President, Global Head, CGS"
                                        email="scott.preiss@cusip.com"/>
                        <PersonContactNoPhone name="Darren Purcell" role="Senior Director, CGS EMEA"
                                        email="darren.purcell@cusip.com"/>
                        <PersonContactNoPhone name="Charles Freda" role="Executive Directorate-ANNA Service Bureau"
                                        email="charles.freda@cusip.com"/>
                        <SupportContact email="asb_support@cusip.com"
                                        lines={['National Numbering Agency', 'www.anna-web.org']}/>
                    </CardContent>
                </Grid>
                <Grid item xs>
                    <CardContent>
                        <FirmContact name="SIX Financial Information" adress1="Postfach CH-8021"
                                        adress2="8005 Zurich, Switzerland"/>
                        <PersonContact name="Manuel Alonso" role="Director" phone="(41) 58 399 8486"
                                        email="manuel.alonso@six-group.com"/>
                        <PersonContact name="Marc Honegger" role="Executive Director" phone="(41) 58 399 5443"
                                        email="marc.honegger@six-group.com"/>
                        <PersonContact name="Michel Brüngger" role="ANNA Service Bureau Operations"
                                        phone="(41) 58 399 5257" email="michel.bruengger@six-group.com"/>
                        <SupportContact email="asbsupport@six-financial-information.com"
                                        lines={['ANNA', 'Avenue Marnix 13-17', '1000 Bruxelles, Belgium']}/>
                    </CardContent>
                </Grid>
            </Grid>
        </Grid>
    </>;
}
