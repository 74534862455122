import { AutocompleteInput, ReferenceInput, required } from "react-admin";
import React from "react";

const referenceToText = choice => {
    if (!choice || !choice.id) return "(empty)";
    else return choice.id + " - " + choice.description
};

function AutocompleteReferenceInput({ reference, ...props }) {
    return <ReferenceInput reference={reference}
                           filterToQuery={(searchText: string) => ({ q: searchText })}
                           allowEmpty={!props.required}
                           validate={props.required && required()}
                           {...props} >
        <AutocompleteInput optionText={referenceToText}
                           optionValue="id"
                           shouldRenderSuggestions={(val: string) => {
                               /* at least 2 chars to make a request */
                               return val.trim().length >= 2
                           }}
        />
    </ReferenceInput>;
}

export default AutocompleteReferenceInput