enum InstrumentCategory {
    EQ = "Equities",
    CV = "Collective Inv. Vehicles",
    DT = "Debt",
    EN = "Entitlements/Rights",
    OP = "Options",
    FT = "Futures",
    SP = "Structured Products",
    RI = "Referential Instruments",
    OT = "Others",
    SO = "Spot",
}

export default InstrumentCategory;