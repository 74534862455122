import React, { FC, Fragment } from "react";
import { MenuItemLink, useAuthState, usePermissions, useTranslate } from "react-admin";
import InfoIcon from "@material-ui/icons/Info";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddCircle from "@material-ui/icons/AddCircle";
import Delete from "@material-ui/icons/Delete";
import Score from "@material-ui/icons/Score";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ShowChart from "@material-ui/icons/ShowChart";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import CloudUpload from "@material-ui/icons/CloudUpload";
import SearchIcon from "@material-ui/icons/Search";
import ViewList from "@material-ui/icons/ViewList";
import Build from "@material-ui/icons/Build";
import MenuBook from "@material-ui/icons/MenuBook";
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import { useSelector } from "react-redux";
import SubMenu from "./SubMenu";
import usePersistedState from "../settings/usePersistentState";
import AnnouncementIcon from '@material-ui/icons/Announcement';

/**
 * Roles and Visibility specification.
 * Menu items (new ASB)            ADMIN   NNA     USER
 * ISIN Access                       *       *       *
 *    ISIN Data Search               *       *       *
 *    Challenge Tickets              *       *
 *
 * ISIN Record Management            *       *
 *    Add/Maintain ISIN Record       *       *
 *    Upload ISIN records            *       *
 *    Data Quality Reporting         *       *
 *    Data Completeness Scoring      *       *
 *
 * ASB Administration                 *
 *    Query by Customer              *
 *    Query by User                  *
 *    Add New Customer               *
 *    Add New User                   *
 *
 * About                             *       *       *
 */

type MenuName = "menuIsinAccess" | "menuIsinRecordManagement" | "menuAbout" | "menuSystemadmin";

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const AnnaMenu: FC<Props> = ({ onMenuClick, dense }) => {
    const [state, setState] = usePersistedState("menuState", {
        menuIsinAccess: true,
        menuIsinRecordManagement: true,
        menuAbout: true,
        menuSystemadmin: false,
    });

    const SubMenuItem = (props) => {
        if(props.visible === false)
            return <Fragment/>;

        return (<MenuItemLink
            to={props.to}
            primaryText={translate(props.name)}
            onClick={onMenuClick}
            leftIcon={props.leftIcon}
            sidebarIsOpen={open}
            dense={dense}
        />);
    };

    // @ts-ignore
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    const translate = useTranslate();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const { permissions } = usePermissions();
    const { authenticated } = useAuthState();
    if(!authenticated) {
        return null;
    } else {
        return (
            <React.Fragment>
                <SubMenu
                    handleToggle={() => handleToggle("menuIsinAccess")}
                    isOpen={state.menuIsinAccess}
                    sidebarIsOpen={open}
                    name="anna.menu.ISINAccess.title"
                    icon={<SearchIcon/>}
                    dense={dense}
                    visible={authenticated && permissions && (permissions.admin || permissions.nna || permissions.user)}
                >
                    <SubMenuItem
                        to="/isin"
                        name="anna.menu.ISINAccess.items.ISINDataSearch"
                        leftIcon={<SearchIcon/>}
                    />
                    {/*<SubMenuItem*/}
                    {/*    to="/challenge"*/}
                    {/*    name="anna.menu.ISINAccess.items.SubmittedInquiries"*/}
                    {/*    leftIcon={<ViewList/>}*/}
                    {/*/>*/}
                    <SubMenuItem
                        to={"/tickets"}
                        name="anna.menu.ISINAccess.items.DataChallenge"
                        leftIcon={<AnnouncementIcon/>}
                    />
                    <SubMenuItem
                        to={"/ticketadmin"}
                        name="anna.menu.ISINAccess.items.DataChallengeAdmin"
                        leftIcon={<Build/>}
                        visible={authenticated && permissions && (permissions.admin)}
                    />
                    <SubMenuItem
                        to="/portfolio/create"
                        name="anna.menu.ISINAccess.items.portfolio"
                        leftIcon={<FolderSpecialIcon/>}
                        visible={authenticated && permissions && (permissions.admin || permissions.portfolio)}
                    />
                </SubMenu>

                <SubMenu
                    handleToggle={() => handleToggle("menuIsinRecordManagement")}
                    isOpen={state.menuIsinRecordManagement}
                    sidebarIsOpen={open}
                    name="anna.menu.ISINRecordManagement.title"
                    icon={<FormatListBulleted/>}
                    dense={dense}
                    visible={authenticated && permissions && (permissions.admin || permissions.nna)}
                >
                    <SubMenuItem
                        to="/isin/create"
                        name="anna.menu.ISINRecordManagement.items.AddMaintainISINRecord"
                        leftIcon={<FormatListBulleted/>}
                    />
                    <SubMenuItem
                        to="/audit"
                        name="anna.menu.ISINRecordManagement.items.UploadISINRecords"
                        leftIcon={<CloudUpload/>}
                    />
                    <SubMenuItem
                        to={"/reports/quality"}
                        name="anna.menu.ISINRecordManagement.items.DataQualityReporting"
                        leftIcon={<ShowChart/>}
                    />
                    <SubMenuItem
                        to={"/reports/completeness"}
                        name="anna.menu.ISINRecordManagement.items.DataCompletenessScoring"
                        leftIcon={<Score/>}
                    />
                </SubMenu>

                <SubMenu
                    handleToggle={() => handleToggle("menuSystemadmin")}
                    isOpen={state.menuSystemadmin}
                    sidebarIsOpen={open}
                    name="anna.menu.ASBAdministration.title"
                    icon={<Build/>}
                    dense={dense}
                    visible={authenticated && permissions && permissions.admin}
                >
                    <SubMenuItem
                        to={"/anna/customer"}
                        name="anna.menu.ASBAdministration.items.QuerybyCustomer"
                        leftIcon={<MenuBook/>}
                    />
                    <SubMenuItem
                        to={"/anna/user"}
                        name="anna.menu.ASBAdministration.items.QueryByUser"
                        leftIcon={<MenuBook/>}
                    />
                    <SubMenuItem
                        to={"/anna/newCustomer"}
                        name="anna.menu.ASBAdministration.items.AddNewCustomer"
                        leftIcon={<AddCircle/>}
                    />
                    <SubMenuItem
                        to={"/anna/newUser"}
                        name="anna.menu.ASBAdministration.items.AddNewUser"
                        leftIcon={<AddCircle/>}
                    />
                    <SubMenuItem
                        to={"/cleanupisins/create"}
                        name="anna.menu.ASBAdministration.items.cleanupISINs"
                        leftIcon={<Delete/>}
                    />
                    <SubMenuItem
                        to={"/reports/activity"}
                        name="anna.menu.ASBAdministration.items.BatchActivity"
                        leftIcon={<Score/>}
                    />
                    <SubMenuItem
                        to={"/list/currencies"}
                        name="anna.menu.ASBAdministration.items.Currencies"
                        leftIcon={<MenuBook/>}
                    />
                    <SubMenuItem
                        to={"/list/countries"}
                        name="anna.menu.ASBAdministration.items.Countries"
                        leftIcon={<MenuBook/>}
                    />
                </SubMenu>

                <SubMenuItem
                    to={"/contact"}
                    name="anna.menu.About.items.ContactUs"
                    leftIcon={<InfoIcon/>}
                />

            </React.Fragment>
        );
    }
};

export default AnnaMenu;
