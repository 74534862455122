// fields of AnnaIsinDto
enum ISIN_FIELD {
    id = "id", // getter
    oldIsin = "oldIsin",
    newIsin = "newIsin",
    issuerNameLong = "issuerNameLong",
    issuerNameShort = "issuerNameShort",
    issueDescription = "issueDescription",
    cfiCode = "cfiCode",
    cfiVersion = "cfiVersion",
    nominalValue = "nominalValue",
    issueCurrency = "issueCurrency",
    interestRate = "interestRate",
    interestType = "interestType",
    interestFrequency = "interestFrequency",
    interestFirstPayDate = "interestFirstPayDate",
    interestPayMonth = "interestPayMonth",
    interestPayDay = "interestPayDay",
    maturityDate = "maturityDate",
    maturityDateCode = "maturityDateCode",
    isinStatus = "isinStatus",
    instrumentCategory = "instrumentCategory",
    fisn = "fisn",
    preliminaryTerms = "preliminaryTerms",
    smallestDenomination = "smallestDenomination",
    conversionRatio = "conversionRatio",
    callPut = "callPut",
    additionalInformationText = "additionalInformationText",
    underlyings = "underlyings",
    underlyingInstrumentName = "underlyingInstrumentName",
    exercisePrice = "exercisePrice",
    exercisePriceCurrency = "exercisePriceCurrency",
    marketIdCodes = "marketIdCodes",
    leadManagerName = "leadManagerName",
    leadManagerLEI = "leadManagerLEI",
    fundManagerName = "fundManagerName",
    fundManagerLEI = "fundManagerLEI",

    centralSecDepsAndLEIs = "centralSecDepsAndLEIs", // array of maps
    csdLei = "csdLei", // nested property in centralSecDepsAndLEIs
    csdName = "csdName", // nested property in centralSecDepsAndLEIs

    issuerLEI = "issuerLEI",
    issuerSupranational = "issuerSupranational",
    issuerHQAddress1 = "issuerHQAddress1",
    issuerHQAddress2 = "issuerHQAddress2",
    issuerHQPostCode = "issuerHQPostCode",
    issuerHQCity = "issuerHQCity",
    issuerHQCountry = "issuerHQCountry",
    headOfficeLEI = "headOfficeLEI",
    headOfficeName = "headOfficeName",
    issuerLegalRegAddress1 = "issuerLegalRegAddress1",
    issuerLegalRegAddress2 = "issuerLegalRegAddress2",
    issuerLegalRegPostCode = "issuerLegalRegPostCode",
    issuerLegalRegCity = "issuerLegalRegCity",
    issuerHQStateProvince = "issuerHQStateProvince",
    issuerLegalRegStateProvince = "issuerLegalRegStateProvince",
    issuerLegalRegCountry = "issuerLegalRegCountry",

    dateAdd = "dateAdd",
    userIdAdd = "userIdAdd",
    dateModify = "dateModify",
    userIdModify = "userIdModify",
}

export default ISIN_FIELD;