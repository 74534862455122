import React, { Children, ReactElement } from "react";
import get from 'lodash/get';
import { hasFieldData } from "../validation/fieldValidation";
import { Labeled } from 'react-admin';
import { Typography } from "@material-ui/core";

export interface LabeledProps {
    source: string;
    format?: (s) => {};
    children?: ReactElement;

    // should be provided from the context
    resource?: string;
    record?: any;
}

const DefaultRender = ({ value }) => {
    let text: string;
    if (Array.isArray(value)) {
        text = value.join(", ");
    } else {
        text = value.toString()
    }

    // body2 in order to match SelectField
    return <Typography variant={"body2"}>{text}</Typography>;
};

export class ShowField extends React.Component<LabeledProps> {

    render() {
        let { source, resource, record, children, format } = this.props;
        if (!record) {
            throw new Error("record attribute is not provided")
        }

        const value = !format ? get(record, source) : format(get(record, source));
        if (!hasFieldData(value))
            return null

        let inner: React.ReactElement;
        if (React.Children.count(children) === 1) {
            const child = Children.only(children);
            inner = React.cloneElement(child, { source, resource, value, record }, child.props.children);
        } else {
            inner = <DefaultRender value={value}/>
        }

        return <Labeled source={source} resource={resource}>{inner}</Labeled>
    }
}