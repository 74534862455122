export default () => ({

    hasLowerCase: (value: string) => {
        return (/[a-z]/.test(value));
    },

    hasUpperCase: (value: string) => {
        return (/[A-Z]/.test(value));
    },
    hasNumber: (value: string) => {
        return (/[0-9]/.test(value));
    },
    hasSymbol: (value: string) => {
        const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        return format.test(value);
    },


});
