import React from 'react';
import { Edit, useNotify, useRedirect } from 'react-admin';
import { useTranslate } from 'ra-core';
import IsinForm, { cleanupIsinBeforeSave } from './IsinForm';
import { SearchResults } from "./SearchResults";
import { hasFieldData } from '../validation/fieldValidation';
import { ValidationResultsDialog } from "./IsinValidationDialog";
import FLD from "../model/ISIN";
import ChallengeShortList from "../challenge/ChallengeShortList";

export const IsinPageTitle = (props) => {
    const translate = useTranslate();
    return <span>{translate('resources.isin.editPageTitle')} {props.record ? ' - ' + props.record.id : ''}</span>;
};

const IsinEdit = ({ permissions, ...props }) => {

    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate();

    const [dateModify, setDateModify] = React.useState();
    const [responseState, setResponseState] = React.useState();

    const closeDialog = () => {
        setResponseState(undefined)
    };

    function onSubmitSuccess(response) {
        const { success, errors, warnings } = response;

        if (!success || hasFieldData(errors) || hasFieldData(warnings)) {
            setResponseState(response)
            const freshTimestamp = response.data[FLD.dateModify];
            if (freshTimestamp) {
                // refresh dateModify time from the server to prevent conflicts during the second save
                setDateModify(freshTimestamp)
            }

        } else {
            notify(translate('resources.isin.changeSuccessfullySaved', { isin: props.id }));
            redirect('list', props.basePath);
        }
    }

    function refreshDateModify(data) {
        data.dateModify = dateModify || data.dateModify;
        return cleanupIsinBeforeSave(data)
    }

    function gotoList() {
        redirect('list', props.basePath);
    }

    return <>
        <ValidationResultsDialog responseState={responseState}
                                 onClose={closeDialog}
                                 onDone={gotoList}/>

        <ChallengeShortList isin={props.id} type="open"/>

        <Edit {...props}
              onSuccess={onSubmitSuccess}
              title={<IsinPageTitle/>}
              undoable={false} // call API with no delays
              actions={<></>} // hide default [Show] button
              aside={<SearchResults currentIsin={props.id}/>}
              transform={refreshDateModify}
        >
            <IsinForm {...props}/>
        </Edit>
    </>
};

export default IsinEdit;
