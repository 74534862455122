import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Title } from 'react-admin';
import { useTranslate } from 'ra-core';

export default function AboutAnna() {
    const translate = useTranslate();

    return <>
        <Title title="About ANNA"/>
        <Card className="Card">
            <CardContent>
                <div className="textcontent">
                    ANNA "numbers the world" by assigning unique codes that identify securities in 120+ nations.
                    <br/><br/>
                    ANNA's members are agencies that are part of financial banks, exchanges, depositories and
                    regulatory agencies. But its people are senior experts from the national numbering agencies who
                    contribute their time and expertise to help new numbering agencies and to keep the ISIN at the
                    cutting edge of financial industry evolution.
                    <br/><br/>
                    {translate("anna.about")}
                    <br/>
                </div>
            </CardContent>
            <CardActions>
                <a href="https://www.anna-web.org/members/about-anna-members/"
                   target="_blank" rel="noopener noreferrer" className="externallink">
                    Read more.
                </a>
            </CardActions>
        </Card>
    </>;
}
