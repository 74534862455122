// fields of AnnaIsinDto
enum ISIN_SEARCH_FIELDS {
    maturityType = "maturityType",
    maturityDateRangeFrom = "maturityDateRangeFrom",
    maturityDateRangeTo = "maturityDateRangeTo",
    dateModifyRangeFrom = "dateModifyRangeFrom",
    dateModifyRangeTo = "dateModifyRangeTo",
    dateAddRangeFrom = "dateAddRangeFrom",
    dateAddRangeTo = "dateAddRangeTo",
    interestRateRangeFrom = "interestRateRangeFrom",
    interestRateRangeTo = "interestRateRangeTo",
    maturityCode = "maturityCode",
}

export default ISIN_SEARCH_FIELDS;