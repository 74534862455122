import React, { FC } from 'react';
import { composeSyncValidators, maxLength, TextInput, useInput } from 'react-admin';
import validateLei from "../validation/lei";
import { useForm } from 'react-final-form';
import { FormApi } from 'final-form';
import gleif from "../api/gleif";
import { get } from 'lodash';
import setFieldData from 'final-form-set-field-data'
import setFieldTouched from 'final-form-set-field-touched'
import ISIN_FIELD from "../model/ISIN";
import { useNotify } from 'ra-core';
import createOverrideButton from "./SoftMandatoryOverrideButton";
import { ConfirmationDialogRaw } from "./LeiInputDialog";

interface Props {
    source?: string;
    dependentFields: Array<[string, string]>;
    helperText?: string;
    disabled?: boolean;
}

interface LeiValidationData {
    expectedValue: string,
    source: string, // source with LEI itself
    lei: string
}

/**
 * These form mutators are needed to update LEI fields data and validation errors
 */
export const LeiFormMutators = { setFieldData, setFieldTouched };

/**
 * This control will call GLEIF.org API to get the JSON object and will store results twice:
 *
 *  1. A raw response JSON data of the form field itself.
 *  2. A relevant selection of the data in each related derived field like CSD name.
 *
 * TODO:
 *  - Fetch will happen only upon LEI change. We should also fetch information once the form is open.
 */
export const LeiInput: FC<Props> = ({ source, dependentFields, ...props }) => {

    const form: FormApi = useForm();

    const notify = useNotify();

    // 549300WKEX26S4CUA403 active
    // 213800EW3O3X6AR8AT04 inactive

    function validateLeiStatus(value: string, values, meta): string {
        if (!value || !values)
            return undefined;

        const isinStatus = values[ISIN_FIELD.isinStatus];
        if (!isinStatus || isinStatus === 'D')
            return undefined;

        const entityStatus = get(meta, "data.attributes.entity.status");
        if (entityStatus && entityStatus !== "ACTIVE") {
            return "GLEIF Entity Status is " + entityStatus;
        }
    }

    function applyLeiData(data, enteredLei: string) {
        form.pauseValidation();
        form.batch(() => {
            applyLeiDataInBatch(data, enteredLei);
        });

        // show validation errors after a delay to let screen refresh
        setTimeout(form.resumeValidation, 100);
    }

    function applyLeiDataInBatch(data, enteredLei: string) {
        // remember LEI data in the form field for LEI number itself
        form.mutators.setFieldData(source, { gleif: data });
        console.debug("LEI " + enteredLei, data);

        for (const [depField, path] of dependentFields) {
            // supports both normal fields, and array data fields
            const arrayPrefix = source.substring(0, source.lastIndexOf('.') + 1);
            const depFieldInArray = arrayPrefix + depField;

            const expectedValue = get(data, path) || "";
            const validationData = { expectedValue, source, lei: enteredLei };

            setLeiDataValidation(depFieldInArray, validationData);
        }
    }

    function setLeiDataValidation(target: string, validationData: LeiValidationData) {
        // remember validation data in the target field (e.g. NAME)
        form.mutators.setFieldData(target, validationData);

        let currentValue = form.getFieldState(target).value;
        let expectedValue = validationData.expectedValue;

        if (expectedValue && !currentValue) {
            // case 1: auto fill - replace empty field value
            form.change(target, expectedValue);

        } else if (currentValue === expectedValue) {
            // no need to update

        } else {
            // trigger visible validation error
            form.mutators.setFieldTouched(target, true);
        }
    }

    function updateLei() {

        const state = form.getFieldState(source);
        if (!state || !state.value) {
            if (state.data.gleif) {
                // LEI field is empty, but it had some validation data attached to it
                // remove validation from this field, and all related fields
                applyLeiData({}, "");
            }
            return;
        }

        const enteredLei = state.value;

        if (state.invalid || validateLei(enteredLei))
            return;

        gleif(enteredLei)
            .then(({ data }) => {
                applyLeiData(data, enteredLei);
            })
            .catch((error) => {
                if (error.message) {
                    // maybe it should be treated as a validation error?
                    notify('resources.lei.leiFetchError', 'error', { error: error.message });
                } else {
                    notify('resources.lei.leiFetchError', 'error', { error: error.toString() });
                }
                applyLeiData({}, enteredLei);
            });

    }

    return <TextInput {...props}
                      source={source}
                      validate={composeSyncValidators(validateLei, validateLeiStatus)}
                      onChange={updateLei}
    />

};

export const LeiDataInput = (props) => {

    const source = props.source;
    const form: FormApi = useForm();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { input: { value }, meta: { touched, error, data } } = useInput({ source, ...props });

    // data as LeiValidationData;

    function gleifValidation(value: string, values, meta) {

        const validationData: LeiValidationData = meta.data;

        if (!validationData || !validationData.expectedValue)
            return undefined;

        if (value === validationData.expectedValue)
            return undefined;

        return { message: 'resources.lei.leiMatchError', args: validationData }
    }

    function acceptUserChoice(userInput) {
        setDialogOpen(false);
        if (!userInput)
            return; // cancel

        switch (userInput) {
            case value:
                // override with user value - disable validation
                disableValidation();
                break;

            case data.expectedValue:
                // override with GLEIF data
                form.change(source, data.expectedValue);
                // reset validation error
                form.resetFieldState(source);
                break;

            default:
                throw new Error("Unexpected reply: " + userInput)
        }
    }

    function disableValidation() {
        const noValidation: LeiValidationData = { expectedValue: '', source: '', lei: '' };

        // force new call to validation (right away, hence override trick above),
        // and remove shown error message
        form.mutators.setFieldData(source, noValidation);
        form.resetFieldState(source);
    }

    if (props.validate)
        throw new Error("LeiDataInput.validate is not implemented");

    const maxLengthReached = value.length > props.maxLength;

    return <>
        <TextInput {...props}
                   source={source}
                   InputProps={!maxLengthReached && createOverrideButton(
                       touched && error, () => setDialogOpen(true)
                   )}
                   validate={composeSyncValidators(maxLength(props.maxLength), gleifValidation)}/>

        {/* We are building the dialog in advance, so that validation error doesn't have to re-render it too much */}
        <ConfirmationDialogRaw optionGleif={data.expectedValue}
                               optionUser={value}
                               lei={data.lei}
                               id={source + "-dialog"}
                               open={dialogOpen}
                               defaultValue={value}
                               onClose={acceptUserChoice}
        />
    </>

};