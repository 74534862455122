import React, { cloneElement, Fragment } from 'react';
import { useEffect, useState } from 'react';
import {
    AutocompleteInput,
    Button,
    Datagrid,
    Filter,
    List,
    Loading,
    maxLength,
    Pagination,
    PaginationLimit,
    sanitizeListRestProps,
    SelectInput,
    SelectArrayInput,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    useRedirect,
    useTranslate,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import InstrumentCategory from "../model/InstrumentCategory"
import IsinStatus from "../model/IsinStatus"
import CfiStandard from "../model/CfiStandard"
import FLD from "../model/ISIN"
import SEARCH_FLD from "../model/ISINSearch";
import { fillEnums, getRoles, PermissionInterface } from '../api/commonFunctions';
import { validateNumber_3_6 } from "../validation/numberValidation";
import { isinValidation } from '../validation/isinValidation';
import './IsinList.css'
import YesNo from "../model/YesNo";
import { IE11DateInput } from "./IE11DateInput";
import MaturityTypes from "../model/MaturityTypes";
import { rememberLastSeenResults } from "./SearchResults";
import MissingIsinDialog from "./MissingIsinDialog";
import IconHistory from '@material-ui/icons/History';
import DownloadIcon from '@material-ui/icons/GetApp';
import BugReportIcon from "@material-ui/icons/BugReport";
import AutocompleteReferenceInput from "./AutocompleteReferenceInput";
import { getCsrfToken } from "../api/authProvider";
import CallPutType from "../model/CallPut";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";

const IsinFilter = (props: any) => {

    return <Filter {...props}>

        {/* Always-on filters */}

        <TextInput source={FLD.id}
                   multiline rowsMax={5}
                   parse={value => value ? value.toUpperCase() : ''}
                   alwaysOn
        />

        <TextInput source={FLD.underlyings}
                   validate={maxLength(12)}
                   alwaysOn
        />

        <TextInput source={FLD.issuerNameLong}
                   validate={maxLength(400)}
                   alwaysOn/>

        <TextInput source={FLD.issueDescription}
                   validate={maxLength(120)}
                   alwaysOn/>

        <AutocompleteInput source={FLD.instrumentCategory}
                           choices={fillEnums(InstrumentCategory)}
                           allowEmpty={true}
                           alwaysOn/>

        <AutocompleteReferenceInput source={FLD.issuerLegalRegCountry} reference='countries'
                                    alwaysOn/>

        <TextInput source={FLD.cfiCode}
                   validate={maxLength(6)}
                   alwaysOn/>

        <AutocompleteReferenceInput source={FLD.issueCurrency} reference='currencies'
                                    alwaysOn/>

        <TextInput source={FLD.fisn} validate={maxLength(35)} alwaysOn/>

        {/* User-configurable filters */}

        <TextInput source={FLD.issuerNameShort} validate={maxLength(30)}/>
        <TextInput source={FLD.leadManagerName} validate={maxLength(255)}/>
        <TextInput source={FLD.fundManagerName} validate={maxLength(255)}/>
        <TextInput source={FLD.csdName} validate={maxLength(255)}/>
        <TextInput source={FLD.csdLei} validate={maxLength(20)}/>
        <TextInput source={FLD.fundManagerLEI} validate={maxLength(20)}/>
        <TextInput source={FLD.issuerLEI} validate={maxLength(20)}/>
        <TextInput source={FLD.leadManagerLEI} validate={maxLength(20)}/>
        <TextInput source={FLD.marketIdCodes} validate={maxLength(6)}/>

        <SelectArrayInput source={FLD.isinStatus} choices={fillEnums(IsinStatus)} allowEmpty={true}/>

        <SelectInput source={FLD.issuerSupranational} choices={fillEnums(YesNo)}/>
        <SelectInput source={FLD.cfiVersion} choices={fillEnums(CfiStandard, false)}/>

        <TextInput source={SEARCH_FLD.interestRateRangeFrom}
                   label="resources.isin.fields.interestRateRangeFrom"
                   validate={validateNumber_3_6}/>
        <TextInput source={SEARCH_FLD.interestRateRangeTo}
                   label="resources.isin.fields.interestRateRangeTo"
                   validate={validateNumber_3_6}/>

        <SelectInput label="resources.isin.fields.maturityType" source={SEARCH_FLD.maturityCode}
                     choices={fillEnums(MaturityTypes, false)}
        />
        <IE11DateInput label="resources.isin.fields.maturityDateRangeFrom"
                       source={SEARCH_FLD.maturityDateRangeFrom}/>
        <IE11DateInput label="resources.isin.fields.maturityDateRangeTo"
                       source={SEARCH_FLD.maturityDateRangeTo}/>

        <IE11DateInput label="resources.isin.fields.dateModifyRangeFrom"
                       source={SEARCH_FLD.dateModifyRangeFrom}/>
        <IE11DateInput label="resources.isin.fields.dateModifyRangeTo"
                       source={SEARCH_FLD.dateModifyRangeTo}/>

        <IE11DateInput label="resources.isin.fields.dateAddRangeFrom"
                       source={SEARCH_FLD.dateAddRangeFrom}/>
        <IE11DateInput label="resources.isin.fields.dateAddRangeTo"
                       source={SEARCH_FLD.dateAddRangeTo}/>

        <AutocompleteInput source={FLD.callPut} choices={fillEnums(CallPutType)} allowEmpty={true}/>

        <TextInput source={FLD.headOfficeName} validate={maxLength(255)}/>
        <TextInput source={FLD.headOfficeLEI} validate={maxLength(20)}/>
        <TextInput source={FLD.newIsin} validate={maxLength(12)}/>
        <TextInput source={FLD.oldIsin} validate={maxLength(12)}/>
        <TextInput source={FLD.underlyingInstrumentName} validate={maxLength(255)}/>

    </Filter>
};

const MissingIsin = () => {
    const { total, filterValues } = useListContext();
    const isin = filterValues.id;
    const validIsin = isin && isinValidation(isin) === undefined;
    const missingIsin: boolean = total === 0 // there are no results
        && validIsin // one valid ISIN is given
        && Object.keys(filterValues).length === 1; // ISIN is the only active filter

    return (
        <>
            <PaginationLimit/> {/* Show original message "No results found" */}
            {missingIsin && <MissingIsinDialog isin={isin} filterValues={filterValues} />}
        </>
    );
};

function onRowClick(record, listResults) {
    if (!record.isinStatus) {
        // no click on invisible ISIN
        return undefined;
    }
    rememberLastSeenResults(listResults);
    return "show";
}

function ResultCountMessage({ total, isAdmin }) {
    const RESULT_LIMIT = 400;
    const EXPORT_LIMIT = 3000000;  // Userstory 3794456

    const messageColor = (Number(total) >= RESULT_LIMIT && !isAdmin) || Number(total) >= EXPORT_LIMIT ? 'red' : 'black';
    const translate = useTranslate();

    let message;

    if (total == null) {
        message = "" // Show empty <Typography/> just to keep a fragile alignment of icons
    } else if (Number(total) >= EXPORT_LIMIT) {
        message = translate('resources.isin.exportWarningMessage', { smart_count: total })
    } else if (Number(total) >= RESULT_LIMIT && !isAdmin) {
        message = translate('resources.isin.resultLimitReachedMessage')
    } else {
        message = translate('resources.isin.resultCountMessage', { smart_count: total })
    }

    return <Typography variant="body2" style={{ alignSelf: 'center', color: messageColor }}>
        {message}
    </Typography>;
}

const ListActions = (props) => {
    const {
        className,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
        loaded,
        setFilters
    } = useListContext();
    const translate = useTranslate();

    const [roles, setRoles] = useState<PermissionInterface>({ nna: false, portfolio: false, admin: false, user: false });

    useEffect(() => {
        async function fetchRoles() {
            const fetchedRoles = await getRoles();
            setRoles(fetchedRoles);
        }

        fetchRoles();
    }, []);

    return (
        <Box display="flex" justifyContent="space-between" width="100%">
            <ResultCountMessage total={loaded ? total : null} isAdmin={roles.admin}/>

            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters && cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
                {Object.keys(filterValues).length > 0 &&
                <Button onClick={() => {
                    setFilters({}, []);
                }}
                        label={translate('resources.isin.resetFilterAction')}>
                    <IconHistory/>
                </Button>}
                <Button onClick={() => doDownloadExport(filterValues)}
                        label={'ra.action.export'}
                        disabled={!total}>
                    <DownloadIcon/>
                </Button>
            </TopToolbar>
        </Box>
    );
};

function doDownloadExport(filterIds: { id: any }) {

    const csrfToken = getCsrfToken();

    const apiUrl = '/anna/api/v1/export';
    console.log(' callExportBackend apiUrl Post', apiUrl);
    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', apiUrl);

    // Create CSRF Field
    const csrfField = document.createElement("input");
    csrfField.hidden = true;
    csrfField.name = csrfToken.parameterName;
    csrfField.value = csrfToken.token;
    form.appendChild(csrfField);

    // Create Filter Field
    const filterInput = document.createElement("input");
    filterInput.name = 'filter';
    filterInput.value = JSON.stringify(filterIds);
    filterInput.hidden = true;
    form.appendChild(filterInput);

    document.body.appendChild(form);
    form.submit();

}

const BulkActions = props => {

    const redirect = useRedirect();
    const handleChallenge = () => {
        redirect('edit', '/challenge', props.selectedIds.join(' '));
    }
    const handleExport = () => {
        const filterIds = { id: props.selectedIds };
        doDownloadExport(filterIds);
    }
    return <Fragment>
        <Button label="resources.isin.challengeAction" onClick={handleChallenge}>
            <BugReportIcon/>
        </Button>
        <Button label="ra.action.export" onClick={handleExport}>
            <DownloadIcon/>
        </Button>
    </Fragment>
};

const Empty = () => {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="body1" paragraph>
                Search is not available temporarily.
                Please check back soon.
            </Typography>
        </Box>
    );
};

const IsinList = (props) => {
    return <CardContent> {/* For margin-top */}
        <List empty={<Empty/>} {...props}
              filters={<IsinFilter/>}
              pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 400]}
                                      limit={<MissingIsin/>}
                                      actions={TablePaginationActions}/>}
              perPage={50}
              bulkActionButtons={<BulkActions/>}
              actions={<ListActions/>}
              title='resources.isin.isinSearchTitle'>
            <ListResultsGrid/>
        </List>
    </CardContent>
}

function ListResultsGrid(props) {
    const { loading } = useListContext();
    const listResults = useListContext();

    if (loading) {
        return <Loading loadingPrimary="resources.isin.loadingPrimary"
                        loadingSecondary="resources.isin.loadingSecondary"/>;
    }
    return <Datagrid rowClick={(id, basePath, record) => onRowClick(record, listResults)}
                     isRowSelectable={record => !!record.isinStatus}
                     {...props}
                     optimized>
        {/* sortable is false to all fields, because we let solr decide the order. */}
        <TextField source={FLD.id}
                   sortable={false}/>

        <TextField source={FLD.issuerNameLong}
                   sortable={false}/>

        <TextField source={FLD.issuerLegalRegCountry}
                   sortable={false}/>

        <TextField source={FLD.isinStatus}
                   sortable={false}/>

        <TextField source={FLD.issueDescription}
                   sortable={false}/>

    </Datagrid>;
}

export default IsinList;
