import React from 'react';
import { useTranslate } from 'ra-core';
import { FileInput, FunctionField, maxLength, required, SelectInput, TextInput } from 'react-admin';
import CHALLENGE_FIELD from "../model/ChallengeFields";
import { fillEnums } from "../api/commonFunctions";
import ChallengePriority from "../model/ChallengePriority";
import { Full, Half, Row, TwoColumnLayout } from "../layout/LayoutComponents";
import ISIN_FIELD from "../model/ISIN";

function validateIsin(value: string, form): string {
    if (value && value.length >= 12) return undefined

    const upload = form[CHALLENGE_FIELD.uploadChallengeISINs];
    if (upload) return undefined

    return 'resources.challenge.isinMissing'
}

// See also ChallengeValidator.kt
const allowedFileTypes = ["txt", "csv", "tsv", "doc", "docx", "xlsx", "xls", "pdf", "xps", "png", "jpg", "jpeg", "gif", "webp", "msg"].map(e => "." + e)
    .concat("text/plain", "application/vnd.ms-excel", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/pdf", "image/*", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml")
    .join(",")

function ChallengeFields({ resource }) {

    const translate = useTranslate();

    // The resource is required in order to have the field label humanized correctly
    return <TwoColumnLayout resource={resource}>
        <Row>
            <Half>
                <TextInput source={ISIN_FIELD.id}
                           label='resources.challenge.fields.challengeISINs'
                           helperText='resources.challenge.fields.challengeISINsHelper'
                           parse={value => value ? value.toUpperCase() : ''}
                           validate={validateIsin}
                           fullWidth
                           multiline rowsMax={6}/>
            </Half>
            <Half>
                <SelectInput source={CHALLENGE_FIELD.challengePriority}
                             choices={fillEnums(ChallengePriority, false)}
                             validate={required()}/>
            </Half>
        </Row>
        <Row>
            <Full>
                <TextInput source={CHALLENGE_FIELD.challengeComment}
                           multiline
                           validate={required()}/>
            </Full>
        </Row>
        <Row>
            <Half>
                <FileInput source={CHALLENGE_FIELD.uploadChallengeISINs}
                           accept={"text/plain,application/vnd.ms-excel,text/csv,.csv"}
                           placeholder={translate('resources.challenge.uploadChallengeISINsDescription')}
                           fullWidth
                           type="file"
                           variant="outlined"
                           multiple={false}
                           validate={maxLength(1)}>

                    <FunctionField render={file => {
                        return file.rawFile.name;
                    }}
                    />

                </FileInput>
            </Half>
            <Half>
                <FileInput source={CHALLENGE_FIELD.additionalDocuments}
                           accept={allowedFileTypes}
                           placeholder={translate('resources.challenge.additionalDocumentsDescription')}
                           fullWidth
                           type="file"
                           variant="outlined"
                           multiple={true}
                           validate={maxLength(5)}>

                    <FunctionField render={file => {
                        return file.rawFile.name;
                    }}
                    />

                </FileInput>
            </Half>
        </Row>
    </TwoColumnLayout>
}

export default ChallengeFields;
