import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { Alert } from '@material-ui/lab';
import { hasFieldData } from '../validation/fieldValidation';
import './IsinValidationDialog.css'

export interface IsinValidationDialogProps {
    title: string,
    errors: string[],
    warnings: string[],
    open: boolean,
    onClose: () => void
    onDone?: () => void
}


export interface IsinValidationResultsDialogProps {
    responseState: any,
    onClose: () => void,
    onDone?: () => void
}

export function ValidationResultsDialog({ responseState, onClose, onDone }: IsinValidationResultsDialogProps) {
    const translate = useTranslate();

    if (!responseState) return null;

    const errors = responseState.errors;
    const warnings = responseState.warnings;
    const success = responseState.success;

    const hasErrors = hasFieldData(errors);
    const open = (!success || hasErrors || hasFieldData(warnings));

    let title;
    if (success) {
        title = translate('resources.isin.successfullySaved');
    } else {
        title = translate('resources.isin.saveFailed');
    }

    return <ValidationDialog title={title}
                             errors={errors}
                             warnings={warnings}
                             open={open}
                             onClose={onClose}
                             onDone={!hasErrors && onDone}/>; // Done is shown only is there are no errors
}

function ValidationDialog({ title, errors, warnings, onDone, onClose, open }: IsinValidationDialogProps) {
    const translate = useTranslate();

    const renderList = (list: string[]) => {
        return <ul className="validationList">
            {list.map(message => <li key={message}>{message}</li>)}
        </ul>;
    }

    const renderErrors = () => {
        if (!hasFieldData(errors)) {
            return null;
        }

        return renderList(errors);
    };

    const renderWarnings = () => {
        if (!hasFieldData(warnings)) {
            return null;
        }

        return <>
            <Alert severity="warning">
                {translate('resources.isin.dataValidationWarning')}
            </Alert>
            {renderList(warnings)}
        </>;
    };

    const renderActions = () => {
        return <>
            <Button onClick={onClose} color="primary">
                {translate('resources.isin.buttons.backToEdit')}
            </Button>
            {onDone && <Button onClick={onDone} color="primary">
                {translate('resources.isin.buttons.done')}
            </Button>}
        </>;
    };

    return <Dialog open={open} scroll="paper" onClose={onClose}>

        <DialogTitle id="confirmation-dialog-title">
            <Alert severity={hasFieldData(errors) ? 'error' : 'success'}>
                {title}
            </Alert>
        </DialogTitle>

        <DialogContent dividers>
            {renderErrors()}
            {renderWarnings()}
        </DialogContent>

        <DialogActions>
            {renderActions()}
        </DialogActions>
    </Dialog>;
}