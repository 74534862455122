import React from 'react';
import { Button, useListContext } from 'react-admin';
import ClearIcon from "@material-ui/icons/Clear";

const ClearFilters = () => {
    const { setFilters, displayedFilters, filterValues } = useListContext();

    const handleClick = () => {
        const clearedFilters = Object.keys(filterValues).reduce((acc, key) => {
            acc[key] = Array.isArray(filterValues[key]) ? [] : '';
            return acc;
        }, {});

        setFilters(clearedFilters, displayedFilters);
    };
    return (
        <>
            <Button startIcon={<ClearIcon />} label="Clear Filters" onClick={handleClick} />
        </>
    );
};

export default ClearFilters;