import { CircularProgress } from "@material-ui/core";
import React from "react";

export function LoadingAnimation(props) {
    return <CircularProgress className="absoluteCenter" id="loadingAnimation"/>
}

export function removeLoadingAnimation() {
    try {
        document.getElementById("loadingAnimation").remove()
    } catch (ignored) {
    }
}