export function validateTextFile(file: { rawFile: File }) {
    if (!file || !file.rawFile) return undefined

    const originalName = file.rawFile.name;
    const name = originalName.toLowerCase();

    if (name.endsWith(".txt") || name.endsWith(".csv") || name.endsWith(".tsv") || name.indexOf('.') === -1) {
        return undefined
    }
    return {
        message: "resources.portfolio.wrongFile",
        args: {
            name: originalName,
            extension: name.substring(name.lastIndexOf('.') + 1).toUpperCase()
        }
    }

}