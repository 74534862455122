// https://leilookup.gleif.org/docs/v2
import { fetchUtils, GetOneResult, Identifier } from 'ra-core';
import { cached } from "./dataProvider";

const url = "https://api.gleif.org/api/v1/lei-records/"

export default async function gleif(lei: Identifier): Promise<GetOneResult> {
    if (!lei || lei.toString().length !== 20) return Promise.reject("")

    let response = await fetchUtils.fetchJson(url + lei);
    let data = response.json;

    if (!(data instanceof Object)) {
        return Promise.reject("Unexpected response from GLEIF database");
    }

    let element = data.data.attributes;
    console.assert(element.lei === lei, "Request and response LEI must match", element.lei, lei)
    return {
        data: element.entity,
        validUntil: cached(20)
    };
}