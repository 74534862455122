import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from "@material-ui/icons/Search";

declare global {
    interface Window {
        LastSeenSearchResults: string[];
        challengingIds: string[];
        LastSeenSearchUrl: string;
    }
}

const SearchResults = ({ currentIsin }) => {

    const list = window.LastSeenSearchResults;
    if (!list) return null;


    return <List component="nav" aria-label="main mailbox folders">

        <ListItem button
                  key="BackToSearch"
                  onClick={() =>
                      window.location.hash = window.LastSeenSearchUrl
                  }>
            <SearchIcon/>
            <ListItemText primary="Search Results"/>
        </ListItem>

        {list.map(id =>
            <ListItem key={id}
                      button
                      selected={id === currentIsin}
                      component="a"
                      href={"#isin/" + id + "/show"} /* todo keep current view: either show or edit */ >
                <ListItemText primary={id}/>
            </ListItem>
        )}
    </List>
};

// type ListControllerProps in React-admin
function rememberLastSeenResults({
                                     currentSort,
                                     filterValues,
                                     displayedFilters,
                                     page,
                                     perPage,

                                     ids,
                                     data,
                                     selectedIds
                                 }) {

    window.LastSeenSearchResults = ids;
    window.LastSeenSearchUrl = window.location.hash;
}

export { SearchResults, rememberLastSeenResults };