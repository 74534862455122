export default function validateIsinStatus(newStatus: string, exists: boolean) {
    // see also StatusValidator::validateTransition in Java

    if (newStatus === 'N' && exists) {
        return 'anna.validation.wrongIsinStatusNew';
    }
    if ((newStatus === 'U' || newStatus === 'D') && !exists) {
        return 'anna.validation.wrongIsinStatusNewIsin';
    }
    return undefined;
}