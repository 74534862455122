enum InterestFrequency {
    D = "Daily",
    W = "Weekly",
    M = "Monthly",
    Q = "Quarterly",
    S = "Semi-annual",
    A = "Annual",
    B = "Bi-annual",
    X = "Other",
}

export default InterestFrequency;