import * as React from 'react';
import { Link } from "@material-ui/core";

export default ({ action, source, id,reconFileName,  record = {}, basePath = "", sortable = false, ...rest }) =>{

    const maxLifetimeInDays = 90;

    const isDateOlderThan30Days = (dateString: string): boolean => {
        const dateStr = dateString.substr(0, 8);
        const year = parseInt(dateStr.substr(0, 4));
        const month = parseInt(dateStr.substr(4, 2)) - 1;
        const day = parseInt(dateStr.substr(6, 2));
        // Current Date
        const currentDate = new Date();

        const inputDate = new Date(year, month, day);

        // Compare the dates
        const timeDiff = currentDate.getTime() - inputDate.getTime();
        const daysDiff = timeDiff / (1000 * 3600 * 24);
        return daysDiff > maxLifetimeInDays;
    };

    const filename: any = reconFileName(record);
    const hasNoDate = !filename || filename === null || filename.length < 8;
    if(hasNoDate || isDateOlderThan30Days(filename)){
        return null
    }else{
        return <Link href={"/anna/api/v1/reconreport/" + id(record) + "/" + action(record) + "/" + filename}>{filename}</Link>
    }
}