import React from "react";
import { AppBar, useAuthState, useDataProvider } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import './header.css';
import WindowFocusListener from "../login/WindowFocusListener";
import ScrollToTop from "./ScrollToTop";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },

    spacer: {
        flex: 1,
    },

    logo: {
        paddingTop: "5px",
        // paddingBottom: "10px" // logo already has bottom padding
        paddingRight: "20px"
    },

    header: {
        backgroundColor: "#fff" // to match ANNA logo
    }
});

const MyAppBar = props => {
    const classes = useStyles();
    const { loading, authenticated } = useAuthState();
    const dataProvider = useDataProvider();

    return (
        <AppBar {...props}
                position="static"
                className={classes.header}
                classes={{
                    toolbar: (loading || authenticated) ? "toolbar authenticated" : "toolbar notAuthenticated",
                    menuButton: "hamburger"
                }}>

            <a href="https://www.anna-web.org/" target="_blank" rel="noopener noreferrer">
                <img src="./logo-anna.svg" width="200px" height="100" alt="ANNA Logo" className={classes.logo}/>
            </a>

            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"/>

            <span className={classes.spacer}/>

            <WindowFocusListener onFocus={() => {
                if (authenticated) {
                    // only if we think login is currently ok
                    dataProvider
                        .getOne('login', { id: "status" })  // /anna/api/v1/login/status
                        .catch() // ignore errors here, it will be handled by useLogoutIfAccessDenied internally
                }
            }}/>

            {/* Globally scroll to the top of each page upon navigation.
                This is useful for ISIN pages, but might interfere with back button */}
            <ScrollToTop/>

        </AppBar>
    );
};

export default MyAppBar;