import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import AboutAsb from './about/AboutAsb';
import AboutAnna from './about/AboutAnna';
import Contact from './about/Contact';
import FieldDefinitions from './about/FieldDefinitions';
import ResetPassword from './login/ResetPassword';
import RequestResetPassword from './login/RequestResetPassword';
import Admin from './admin/Admin';
import { CCList } from "./countries/CCList";
import Report from './report/Report';

export default [
    <Route exact path="/aboutanna" render={() => <AboutAnna/>}/>,
    <Route exact path="/aboutasb" render={() => <AboutAsb/>}/>,
    <Route exact path="/contact" render={() => <Contact/>}/>,
    <Route exact path="/fielddefinitions" render={() => <FieldDefinitions/>}/>,
    /* This page is called from email-link, hard-coded in UserService.java */
    <Route exact path="/resetPassword/:token" render={({ match }) => <ResetPassword token={match.params.token}/>}/>,
    <Route exact path="/requestPasswordReset" render={() => <RequestResetPassword/>}/>,

    /* Not using standard /countries and /currencies because they require login */
    <Route path="/list/countries" exact render={() => <CCList resource={"countries"}/>}/>,
    <Route path="/list/currencies" exact render={() => <CCList resource={"currencies"}/>}/>,

    <Route exact path="/">
        <Redirect to="/login"/>
    </Route>,
    <Route path="/anna/user" render={() => <Admin/>}/>,
    <Route path="/anna/customer" render={() => <Admin/>}/>,
    <Route path="/anna/newUser" render={() => <Admin/>}/>,
    <Route path="/anna/newCustomer" render={() => <Admin/>}/>,
    <Route path="/reports/quality" render={() => <Report type="quality"/>}/>,
    <Route path="/reports/completeness" render={() => <Report type="completeness"/>}/>,
    <Route path="/reports/activity" render={() => <Report type="activity"/>}/>,

];