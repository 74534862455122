import React from "react";
import { composeSyncValidators, SelectInput } from "react-admin";

/**
 * Works in the same way as React-Admin https://marmelab.com/react-admin/Inputs.html#selectinput
 * Adds a custom field validation to verify that the value (returned from DB) is within allowed domain.
 */
export function SafeSelectInput({ choices, validate = [], ...props }) {

    const allowedValues = new Set(choices.map(pair => pair.id))

    function validateDomain(value) {
        if (value && !allowedValues.has(value))
            return { message: 'anna.validation.unexpectedValue', args: { value } };

        return undefined
    }

    const existingValidation = validate || [];
    return <SelectInput translateChoice={false}
                        {...props}
                        choices={choices}
                        validate={composeSyncValidators(validateDomain, ...existingValidation)}/>
}