import ISIN_FIELD from '../model/ISIN';
import InstrumentCategory from '../model/InstrumentCategory';
import CompletenessScoreType from "../model/CompletenessScoreType";
import { hasFieldData, shouldBeRendered } from '../validation/fieldValidation';
import { InstrumentCategoryRulesMap } from '../validation/rules/CompletenessScoreRules';
import { matchesPattern } from "../validation/cfiMatcher";
import InterestType from "../model/InterestType";

interface Csds {
    csdLei: string;
    csdName: string;
}

function scoreCSD(fieldValue: any): CompletenessScoreType {
    if (!hasFieldData(fieldValue)) {
        return CompletenessScoreType.GOOD
    }
    const csdArray: Csds[] = fieldValue as Array<Csds>;
    for (const csd of csdArray) {
        if (!csd || !csd.csdName) {
            return CompletenessScoreType.GOOD;
        }
        if (!csd.csdLei) {
            return CompletenessScoreType.VERY_GOOD;
        }
    }
    return CompletenessScoreType.EXCELLENT
}

function getCompletenessScoreForEmptyField(isinField: ISIN_FIELD, fieldValue: any, instrumentCategory: InstrumentCategory): CompletenessScoreType {

    let rule = InstrumentCategoryRulesMap.get(InstrumentCategory[instrumentCategory]);
    if (!rule) return null;

    if (isinField === ISIN_FIELD.centralSecDepsAndLEIs) {
        if(instrumentCategory.toString() === 'RI'){
            return null
        }else{
            return scoreCSD(fieldValue)
        }
    } else {
        return rule.has(isinField) ? rule.get(isinField) : null;
    }
}

/**
 * According to Story "Amendments of Data Quality Reporting - Calculate Completeness Score" (3482166) calculation should be
 * ignored in special cases.
 * @param isinField
 * @param instrumentCategory
 * @param cfiCode
 * @param interestType
 */
function toIgnore(isinField, instrumentCategory: InstrumentCategory, cfiCode, interestType: InterestType) {

    if ((isinField === ISIN_FIELD.conversionRatio || isinField === ISIN_FIELD.underlyings) && InstrumentCategory.EQ === instrumentCategory && !(cfiCode && (cfiCode.startsWith('EC') || cfiCode.startsWith('EF')))) {
        return true
    } else if(isinField === ISIN_FIELD.maturityDateCode && InstrumentCategory.EQ === instrumentCategory
        && !matchesPattern(cfiCode, ["EP*R**", "EP*E**", "EP*T**", "EP*G**", "EP*A**", "EP*C**", "EP*N**", "EF*R**", "EF*E**", "EF*T**", "EF*G**",
            "EF*A**", "EF*C**", "EF*N**", "ED*R**", "ED*N**", "ED*D**"])) {
        return true
    } else if((isinField === ISIN_FIELD.conversionRatio || isinField === ISIN_FIELD.underlyings) && InstrumentCategory.DT === instrumentCategory
        && !matchesPattern(cfiCode, ["DC****"])) {
        return true
    } else if((isinField === ISIN_FIELD.interestRate
        || isinField === ISIN_FIELD.interestPayDay
        || isinField === ISIN_FIELD.interestPayMonth
        || isinField === ISIN_FIELD.interestFrequency) && InstrumentCategory.DT === instrumentCategory && InterestType.F !== interestType) {
        return true
    } else if(isinField === ISIN_FIELD.interestRate && InstrumentCategory.SP === instrumentCategory && InterestType.F !== interestType) {
        return true
    } else {
        return false
    }
}

function getFieldScore(isinField, formFields){
    let category = formFields[ISIN_FIELD.instrumentCategory];
    let cfiCode = formFields[ISIN_FIELD.cfiCode];
    let interestType = formFields[ISIN_FIELD.interestType];

    if(toIgnore(isinField, InstrumentCategory[category], cfiCode, interestType)){
        // Ignore Scoring
        return null
    } else if(isinField === ISIN_FIELD.centralSecDepsAndLEIs){
        if(category !== 'RI'){
            return scoreCSD(formFields[isinField])
        }else{
            return CompletenessScoreType.EXCELLENT
        }
    }else if(!hasFieldData(formFields[isinField])){
        return getCompletenessScoreForEmptyField(isinField, formFields[isinField], category);
    }else{
        return CompletenessScoreType.EXCELLENT
    }
}

export function getFieldsForValues(values): ISIN_FIELD[] {
    return Object.values(ISIN_FIELD)
        .filter(field => shouldBeRendered(field, values[ISIN_FIELD.instrumentCategory], values[ISIN_FIELD.interestType], values[ISIN_FIELD.preliminaryTerms], values[ISIN_FIELD.isinStatus]));
} 

export function getScoredFieldsForValues(fields, values) {
    return fields.map(field => ({
        field,
        score: getFieldScore(field, values)
    }));
}

export function getCompletenessScoreForValues(values) {
    const fields = getFieldsForValues(values);

    const scoredFields = getScoredFieldsForValues(fields, values);

    const lowestScore = scoredFields.reduce((acc, value) => {
        if (value.score !== null && value.score <= acc.score) {
            return value;
        } else {
            return acc;
        }
    });
    return lowestScore.score;
}

export function getFieldsToImproveForScore(fields, values, score) {
    if (score === CompletenessScoreType.EXCELLENT) {
        return [];
    }
    return getScoredFieldsForValues(fields, values).filter(field => field.score === score)
}