import CompletenessScoreType from "../../model/CompletenessScoreType";
import ISIN_FIELD from "../../model/ISIN";
import InstrumentCategory from "../../model/InstrumentCategory";

const EQ = new Map<ISIN_FIELD, any>([ [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.nominalValue,CompletenessScoreType.GOOD],
    [ISIN_FIELD.maturityDateCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.conversionRatio,CompletenessScoreType.GOOD],
    [ISIN_FIELD.underlyings,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.marketIdCodes,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLEI,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.issuerNameShort,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerSupranational,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQAddress1,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCity,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCountry,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegAddress1,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegPostCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegCity,CompletenessScoreType.VERY_GOOD] ]);

const CV = new Map<ISIN_FIELD, any>([ [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.preliminaryTerms,CompletenessScoreType.GOOD],
    [ISIN_FIELD.marketIdCodes,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.fundManagerLEI,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLEI,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerNameShort,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQAddress1,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQAddress2,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCity,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCountry,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegAddress1,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegPostCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegCity,CompletenessScoreType.VERY_GOOD] ]);


const DT = new Map<ISIN_FIELD, any>([ [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.preliminaryTerms,CompletenessScoreType.GOOD],
    [ISIN_FIELD.nominalValue,CompletenessScoreType.GOOD],
    [ISIN_FIELD.smallestDenomination,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.conversionRatio,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.underlyings,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.interestRate,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.interestPayMonth,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.interestPayDay,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.interestFrequency,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.interestFirstPayDate,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.additionalInformationText,CompletenessScoreType.GOOD],
    [ISIN_FIELD.marketIdCodes,CompletenessScoreType.GOOD],
    [ISIN_FIELD.leadManagerName,CompletenessScoreType.GOOD],
    [ISIN_FIELD.leadManagerLEI,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLEI,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.issuerNameShort,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerSupranational,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQAddress1,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCity,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCountry,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegAddress1,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegPostCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegCity,CompletenessScoreType.VERY_GOOD] ]);

const EN = new Map<ISIN_FIELD, any>([ [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.conversionRatio,CompletenessScoreType.GOOD],
    [ISIN_FIELD.maturityDateCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.exercisePrice,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.exercisePriceCurrency,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.underlyings,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.additionalInformationText,CompletenessScoreType.GOOD],
    [ISIN_FIELD.marketIdCodes,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLEI,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.issuerNameShort,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQAddress1,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCity,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCountry,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegAddress1,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegPostCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegCity,CompletenessScoreType.VERY_GOOD] ]);

const FT = new Map<ISIN_FIELD, any>([ [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.conversionRatio,CompletenessScoreType.GOOD],
    [ISIN_FIELD.maturityDateCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.exercisePriceCurrency,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.exercisePrice,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.underlyings,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.additionalInformationText,CompletenessScoreType.GOOD],
    [ISIN_FIELD.marketIdCodes,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLEI,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.issuerNameShort,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQAddress1,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQAddress2,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCity,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQCountry,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegAddress1,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegCity,CompletenessScoreType.VERY_GOOD] ]);

const OP = new Map<ISIN_FIELD, any>([ [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.conversionRatio,CompletenessScoreType.GOOD],
    [ISIN_FIELD.maturityDateCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.exercisePriceCurrency,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.exercisePrice,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.underlyings,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.additionalInformationText,CompletenessScoreType.GOOD],
    [ISIN_FIELD.marketIdCodes,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLEI,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.issuerNameShort,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQAddress1,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQAddress2,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCity,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQCountry,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegAddress1,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegCity,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.callPut,CompletenessScoreType.SUFFICIENT] ]);

const SP = new Map<ISIN_FIELD, any>([ [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.preliminaryTerms,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.nominalValue,CompletenessScoreType.GOOD],
    [ISIN_FIELD.maturityDateCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.underlyings,CompletenessScoreType.GOOD],
    [ISIN_FIELD.interestRate,CompletenessScoreType.GOOD],
    [ISIN_FIELD.additionalInformationText,CompletenessScoreType.GOOD],
    [ISIN_FIELD.marketIdCodes,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLEI,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerNameShort,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.issuerSupranational,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQAddress1,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCity,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCountry,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegAddress1,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegPostCode,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegCity,CompletenessScoreType.VERY_GOOD] ]);

const OT = new Map<ISIN_FIELD, any>([ [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.nominalValue,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issueCurrency,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.maturityDateCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.underlyings,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.interestType,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.interestRate,CompletenessScoreType.GOOD],
    [ISIN_FIELD.interestPayMonth,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.interestPayDay,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.additionalInformationText,CompletenessScoreType.GOOD],
    [ISIN_FIELD.marketIdCodes,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLEI,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.issuerNameShort,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerSupranational,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQAddress1,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQAddress2,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCity,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQCountry,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegAddress1,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegCity,CompletenessScoreType.VERY_GOOD] ]);

const RI = new Map<ISIN_FIELD, any>([ [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.nominalValue,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issueCurrency,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.underlyings,CompletenessScoreType.GOOD],
    [ISIN_FIELD.interestType,CompletenessScoreType.GOOD],
    [ISIN_FIELD.additionalInformationText,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLEI,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.issuerNameShort,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQAddress1,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQAddress2,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerHQCity,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerHQCountry,CompletenessScoreType.GOOD],
    [ISIN_FIELD.issuerLegalRegAddress1,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegStateProvince,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegPostCode,CompletenessScoreType.VERY_GOOD],
    [ISIN_FIELD.issuerLegalRegCity,CompletenessScoreType.VERY_GOOD] ]);

const SO = new Map<ISIN_FIELD, any>([
    [ISIN_FIELD.cfiCode,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.fisn,CompletenessScoreType.SUFFICIENT],
    [ISIN_FIELD.additionalInformationText,CompletenessScoreType.GOOD],
    [ISIN_FIELD.marketIdCodes,CompletenessScoreType.GOOD] ]);

export const InstrumentCategoryRulesMap = new Map<String, Map<ISIN_FIELD, any>>([
    [InstrumentCategory.EQ, EQ],
    [InstrumentCategory.DT, DT],
    [InstrumentCategory.EN, EN],
    [InstrumentCategory.CV, CV],
    [InstrumentCategory.FT, FT],
    [InstrumentCategory.OP, OP],
    [InstrumentCategory.OT, OT],
    [InstrumentCategory.RI, RI],
    [InstrumentCategory.SO, SO],
    [InstrumentCategory.SP, SP]]);
