import ValidationRulesProvider from "./validationRules";
import FIELD_TYPE from "./FieldType";
import InstrumentCategory from "../model/InstrumentCategory";
import ISIN_FIELD from "../model/ISIN";
import InterestType from "../model/InterestType";
import YesNo from "../model/YesNo";
import MaturityTypes from "../model/MaturityTypes";

const validationRules = ValidationRulesProvider();
const fieldValidationRules: Map<ISIN_FIELD, Map<InstrumentCategory, FIELD_TYPE>> = validationRules.getFieldValidationRules()
const interestfieldValidationRules: Map<ISIN_FIELD, Map<InterestType, FIELD_TYPE>> = validationRules.getInterestFieldValidationRules()
const preliminaryTermsOptionalFields = new Set([ISIN_FIELD.maturityDate, ISIN_FIELD.maturityDateCode, ISIN_FIELD.interestType, ISIN_FIELD.interestRate])
const optionalInDeleteStatus = new Set([ISIN_FIELD.cfiCode, ISIN_FIELD.fisn])
const interestFields = new Set([ISIN_FIELD.interestRate, ISIN_FIELD.interestFrequency, ISIN_FIELD.interestPayMonth, ISIN_FIELD.interestPayDay, ISIN_FIELD.interestFirstPayDate])

interface FieldType {
    type: FIELD_TYPE;
    // Which isin field determines the fieldtype
    relatedField: ISIN_FIELD
}

export function getFieldType(isinField: ISIN_FIELD, instrumentCategory: InstrumentCategory, interestType: InterestType, preliminaryTermsCode: string, isinStatus?: string): FieldType {

    const preliminaryTerms = YesNo[preliminaryTermsCode];

    const isOptional = isOptionalDeptField(isinField, instrumentCategory, preliminaryTerms);
    if (isOptional === true) {
        return { type: FIELD_TYPE.O, relatedField: ISIN_FIELD.preliminaryTerms }
    }

    if (isinStatus !== undefined && isOptionalFieldFromDeleteStatus(isinField, isinStatus) === true) {
        return { type: FIELD_TYPE.O, relatedField: isinField }
    }

    const fieldType: FIELD_TYPE = getFieldTypeByCategory(isinField, instrumentCategory);

    if(fieldType === FIELD_TYPE.O && interestFields.has(isinField)){
        return getFieldTypeByInterestType(isinField, interestType)
    }

    return { type: fieldType, relatedField: ISIN_FIELD.instrumentCategory };
}

function getFieldTypeByCategory(fieldName: ISIN_FIELD, instrumentCategory: InstrumentCategory): FIELD_TYPE {
    const fieldtypes = fieldValidationRules.get(fieldName);
    if (!fieldtypes) {
        return undefined;
    }
    const fieldType: FIELD_TYPE = fieldtypes.get(instrumentCategory);
    if (!fieldType) {
        return undefined;
    }
    return fieldType;
}

function getFieldTypeByInterestType(fieldName: ISIN_FIELD, interestType: InterestType): FieldType {
    const fieldtypes = interestfieldValidationRules.get(fieldName);
    if (!fieldtypes) {
        return { type: undefined, relatedField: undefined };
    }
    const fieldType: FIELD_TYPE = fieldtypes.get(interestType);
    if (!fieldType) {
        return { type: undefined, relatedField: undefined };
    }
    return { type: fieldType, relatedField: ISIN_FIELD.interestType };
}

function ignoreMaturityDateValidation(values, isinField: string) {
    if (isinField === ISIN_FIELD.maturityDate) {
        const fieldValueMatCode = values[ISIN_FIELD.maturityDateCode];
        if (fieldValueMatCode !== null && Object.keys(MaturityTypes).includes(fieldValueMatCode)) {
            return true;
        }
    }
}

/**
 * validates mandatory fields and not expected fields based in validation rules and instrument category.
 * @param values
 * @param errors
 */
export function validateMandatoryFields(values, errors) {

    for (const isinField in ISIN_FIELD) {
        if (!ISIN_FIELD.hasOwnProperty(isinField)) continue;

        if (ignoreMaturityDateValidation(values, isinField)) {
            continue;
        }

        const fieldType: FieldType = getFieldType(ISIN_FIELD[isinField], InstrumentCategory[values.instrumentCategory], InterestType[values.interestType], values.preliminaryTerms, values.isinStatus);

        const fieldValue = values[isinField];

        const hasData = hasFieldData(fieldValue);

        const instrumentCategoryValue: InstrumentCategory = InstrumentCategory[values.instrumentCategory];

        if (fieldType.type === FIELD_TYPE.M && !hasData) {
            errors[isinField] = {
                message: 'anna.validation.mandatoryField',
                args: {
                    instrumentCategoryName: instrumentCategoryValue,
                    instrumentCategoryCode: values.instrumentCategory
                }
            }
        } else if (fieldType.type === FIELD_TYPE.N && hasData) {
            // TODO: Cant show not expected Field message for CSD LEI
            const fieldName = isinField === ISIN_FIELD.centralSecDepsAndLEIs ? ISIN_FIELD.csdLei : isinField;
            if(fieldType.relatedField === ISIN_FIELD.interestType){
                const interestTypeValue: InterestType = InterestType[values.interestType];
                errors[fieldName] = {
                    message: 'anna.validation.notExpectedFieldForInteresttype',
                    args: {
                        interestTypeName: interestTypeValue,
                        interestTypeCode: values.interestType,
                        instrumentCategoryCode: values.instrumentCategory
                    }
                }
            }else{
                errors[fieldName] = {
                    message: 'anna.validation.notExpectedField',
                    args: {
                        instrumentCategoryName: instrumentCategoryValue,
                        instrumentCategoryCode: values.instrumentCategory
                    }
                }
            }
        }
    }
}

/**
 * determines if a ISIN Field should be rendered based on a selected Intstrument Category and Interest Type and Preliminary Terms Code.
 * @param isinField
 * @param instrumentCategoryCode
 * @param interestTypeCode
 * @param preliminaryTermsCode
 */
export function shouldBeRendered(isinField: ISIN_FIELD, instrumentCategoryCode: string, interestTypeCode: string, preliminaryTermsCode: string, isinStatus?: string): boolean {

    const fieldType: FieldType = getFieldType(ISIN_FIELD[isinField], InstrumentCategory[instrumentCategoryCode], InterestType[interestTypeCode], preliminaryTermsCode, isinStatus);
    return fieldType.type !== FIELD_TYPE.N;

}

export function isRequired(isinField: ISIN_FIELD, instrumentCategoryCode: string, preliminaryTerms: string, interestTypeCode: string, preliminaryTermsCode: string, isinStatus?: string): boolean {

    return FIELD_TYPE.M === getFieldType(ISIN_FIELD[isinField], InstrumentCategory[instrumentCategoryCode], InterestType[interestTypeCode], preliminaryTermsCode, isinStatus).type;

}

function isOptionalDeptField(isinField: ISIN_FIELD, instrumentCategory: InstrumentCategory, preliminaryTerms: YesNo): boolean {
    return preliminaryTerms === YesNo.Y && instrumentCategory === InstrumentCategory.DT && preliminaryTermsOptionalFields.has(isinField);
}

function isOptionalFieldFromDeleteStatus(isinField: ISIN_FIELD, isinStatus: string): boolean {
    return isinStatus === 'D' && optionalInDeleteStatus.has(isinField);

}

/**
 * Checks is ISIN Fields is of specific validation Type
 * @param isinField
 * @param fieldType
 */
export function anyOfTypeInCategory(isinField: ISIN_FIELD, fieldType: FIELD_TYPE): boolean {
    const fieldTypes: Map<InstrumentCategory, FIELD_TYPE> = fieldValidationRules.get(ISIN_FIELD[isinField]);
    if(fieldTypes === undefined){
        return false
    }
    let entries;
    entries = fieldTypes.entries();

    for (const [, value] of entries) {
        if (value === fieldType) {
            return true
        }
    }
    return false;
}

export function anyOfTypeInInterestType(isinField: ISIN_FIELD, fieldType: FIELD_TYPE): boolean {
    if(!interestFields.has(isinField)){
        return false;
    }
    const fieldTypes: Map<InterestType, FIELD_TYPE> = interestfieldValidationRules.get(ISIN_FIELD[isinField]);
    let entries;
    entries = fieldTypes.entries();

    for (const [, value] of entries) {
        if (value === fieldType) {
            return true;
        }
    }
    return false;
}

/**
 * Checks if ISIN Field is not expected for any InstrumentCategory.
 * @param isinField
 */
export function anyNotExpected(isinField: ISIN_FIELD): boolean {
    return anyOfTypeInCategory(isinField, FIELD_TYPE.N) || anyOfTypeInInterestType(isinField, FIELD_TYPE.N);
}

/**
 * Checks if ISIN Field is mandatory for any InstrumentCategory.
 * @param isinField
 */
export function anyMandatory(isinField: ISIN_FIELD): boolean {
    return anyOfTypeInCategory(isinField, FIELD_TYPE.M) || anyOfTypeInInterestType(isinField, FIELD_TYPE.M);
}

/**
 * exercisePriceCurrency should be provided if exercisePrice is provided for specific InstrumentCategories.
 * exercisePrice should be provided if exercisePriceCurrency is provided.
 * @param values
 * @param errors
 */
export function validateExercisePrice(values, errors) {
    if (values.exercisePrice && !values.exercisePriceCurrency) {
        const instrumentCategoryValue: InstrumentCategory = InstrumentCategory[values.instrumentCategory];
        const fieldType: FIELD_TYPE = getFieldTypeByCategory(ISIN_FIELD.exercisePriceCurrency, instrumentCategoryValue);
        if (fieldType === FIELD_TYPE.O) {
            errors[ISIN_FIELD.exercisePriceCurrency] = {
                message: 'anna.validation.missingExercisePriceCurrency',
                args: { exercisePriceCurrency: values.exercisePrice }
            }
        }
    }
    if (values.exercisePriceCurrency && !values.exercisePrice) {
        errors[ISIN_FIELD.exercisePrice] = {
            message: 'anna.validation.missingExercisePriceForExistingCurrency',
            args: { exercisePriceCurrency: values.exercisePriceCurrency }
        }
    }
}

/**
 * interestrate must be provided for specific instrument categories, if type of interest is set to Fixed Rate.
 * @param values
 * @param errors
 */
export function validateInterestRateContent(values, errors) {

    const interestType = InterestType[values.interestType];
    const interestRate = values.interestRate;
    const instrumentCategoryValue: InstrumentCategory = InstrumentCategory[values.instrumentCategory];
    const fieldType: FIELD_TYPE = getFieldTypeByCategory(ISIN_FIELD.interestRate, instrumentCategoryValue);

    if (fieldType === FIELD_TYPE.O && interestType === InterestType.F && !interestRate) {
        errors[ISIN_FIELD.interestRate] = { message: 'anna.validation.missingInterestRate' };
    }
    const hasNoRate = interestType === InterestType.Z;
    const isZero = !interestRate || (isNumeric(interestRate) && parseFloat(interestRate) === 0);
    if (fieldType === FIELD_TYPE.O && hasNoRate && !isZero) {
        errors[ISIN_FIELD.interestRate] = {
            message: 'anna.validation.notExpectedInterestRate',
            args: { interestRate: interestRate }
        };
    }
}

export function validateDoublicated(values, errors) {
    const issuerLEI = values.issuerLEI;
    const headOfficeLEI = values.headOfficeLEI;
    if(issuerLEI && headOfficeLEI && issuerLEI === headOfficeLEI){
        errors[ISIN_FIELD.headOfficeLEI] = { message: 'anna.validation.headLeiEqualIssuerLei' };
    }
}

export function hasFieldData(field) {
    if (Array.isArray(field)) {
        return field.length > 0;
    }
    return !!field || field === 0;
}

function isNumeric(num) {
    return !isNaN(num)
}

