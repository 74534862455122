import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import React from "react";
import { getNNACountries } from "../api/commonFunctions";
import { LoadingAnimation } from "../admin/LoadingAnimation";

async function getReportURL(type: string): Promise<string | null> {
    const url = `/anna/api/v1/report/${type}`;
    try {
        return await (await fetch(url)).text();
    } catch (error) {
        console.error('Cannot fetch report URL', error);
        return null;
    }
}

function createQuickSightHTML(url: string) {

    // following tag is created only temporarly to create the iframe. the iframe is added to the container later.
    let dummy = document.createElement("div");
    const options = {
        url: url,
        container: dummy,
        parameters: {
            PCountry: getNNACountries()
        },
        height: "100%",
        width: "100%"
    };

    var embedded = embedDashboard(options)
    var outhtml = embedded.iframe.outerHTML;
    return outhtml;
}

async function embed(type: string, elementId) {
    const url = await getReportURL(type);

    const container = document.getElementById(elementId);

    if (!url) {
        container.innerText = "Dashboard cannot be loaded right now"
        return
    } else {
        // repeated calls to embedDashboard add several copies; also removes loading animation
        container.innerHTML = "";
    }

    // iframe will be added to the container.
    var quickSightHTML = createQuickSightHTML(url);
    container.insertAdjacentHTML('beforeend', quickSightHTML);
    // adding sandbox attribute (Pentest: unsecure iframe integration)
    addSandboxToIframes()
}


function addSandboxToIframes() {
    const container = document.getElementById('dashboardContainer');
    container.querySelectorAll('iframe')
        .forEach((input) => {
            input.setAttribute("sandbox", "");
        });
}

const Report = ({ type }) => {
    console.debug("Opening dashboard for ", type)

    React.useEffect(() => {
        embed(type, "dashboardContainer")
            .then(r => console.debug("Opened dashboard for ", type, r));
    }, [type]);

    return <div id="dashboardContainer" className="container">
        <LoadingAnimation/>
    </div>;
};

export default Report;