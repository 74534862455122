import React, { useEffect, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { isinFields } from "../i18n/en";
import { convertToTicketTags } from "./Tickets";
import Select from 'react-select';

const generalInquiry = { label: 'General Inquiry', value: 'General Inquiry' };
function convertArray(items) {
    const convertedItems = items.map(item => ({
        label: item.name,
        value: item.id
    }));
    convertedItems.unshift(generalInquiry);
    convertedItems.unshift({ label: 'IT support', value: 'IT support' });
    return convertedItems;
}

const ticketTags = convertArray(convertToTicketTags(isinFields));
const customStyles = {
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        zIndex: 10,
    }),
    menuList: (provided) => ({
        ...provided,
        backgroundColor: 'white',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
        color: 'black',
    }),
};

const TagsInput = ({ source, ...rest }) => {
    const form = useForm();

    useEffect(() => {
        const initialValue = form.getFieldState(source)?.initial;
        if (!initialValue) {
            form.change(source, 'General Inquiry');
        }
    }, [form, source]);

    return (
        <Field name={source}>
            {({ input }) => {
                const handleChange = (value) => {
                    input.onChange(value.map(v => v.value).join('|'));
                };

                let value = input.value
                    ? input.value.split('|').map(tag => ({ value: tag, label: tag }))
                    : [];

                return (
                    <Select
                        isMulti
                        options={ticketTags}
                        value={value}
                        onChange={handleChange}
                        styles={customStyles}
                        placeholder="Add or select tag"
                        {...rest}
                    />
                );
            }}
        </Field>
    );
};

export default TagsInput;
