import React from 'react';
import "./footer.css"

export const AnnaFooter = () => (
    <footer style={{
        backgroundColor: '#f2f2f2',
        position: 'relative',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px'
    }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <small style={{ "float": "left" }}>
                CUSIP is a registered trademark of the <a href="https://www.aba.com/" target="_blank"
                                                          rel="noopener noreferrer">American Bankers Association</a>.
                <br/>
                CUSIP Global Services (CGS) is managed on behalf of the American Bankers Association by FactSet Research
                Systems Inc.
                <br/>
                Copyright © {new Date().getFullYear()} CUSIP Global Services. All rights reserved.
                <br/>
                <a href="https://www.cusip.com/legal.html#/privacyAndCookieNotice" target="_blank"
                   rel="noopener noreferrer">Privacy Policy and Cookie Notice</a>
                &nbsp;|&nbsp;
                <a href="https://www.cusip.com/legal.html?section=termsOfUse" target="_blank" rel="noopener noreferrer">Terms
                    of Use</a>
                &nbsp;
                <br/>
            </small>
        </div>
        <div style={{ "float": "right" }}>
            <img src="logo-cusip.svg" style={{ "height": "5em" }} alt=""/>
            <img src="logo-six.svg" style={{ "height": "3em" }} alt=""/>
        </div>

    </footer>
);