const globalApiCache = new Map();

async function cachingWrapper(dataProvider, name, resource, params, cache) {
    if (cache.has(resource)) {
        return cache.get(resource);
    }

    // cache is not available - follow the request
    const data = await dataProvider[name](resource, params);
    cache.set(resource, data);
    return data;
}

export default (dataProvider) => new Proxy(dataProvider, {
    get: (target, name) => (resource, params) => {
        switch (resource) {
            case 'countries':
            case 'currencies':

                switch (name) {
                    case 'getList':
                    case 'getMany':
                        // load data from local cache only
                        return cachingWrapper(target, name, resource, params, globalApiCache);

                    case 'update':
                    case 'create':
                        // invalidate the cache and continue with the real data provider
                        globalApiCache.delete(resource);
                }

        }

        return target[name](resource, params);
    }
});
