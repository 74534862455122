import React from 'react';
import classnames from 'classnames';
import { useTranslate } from 'react-admin';

import CompletenessScoreType from "../model/CompletenessScoreType";
import "./CompletenessScore.css"

export interface CompletenessScoreElementProps {
    fieldsToImprove: any[],
    score: number,
}

export const CompletenessScoreElement = (props: CompletenessScoreElementProps) => {
    const translate = useTranslate();
   

    function getScoreText() {
        return translate('enums.completenessScoreType.'+CompletenessScoreType[props.score]);
    }

    const styleForScore = () => {
        switch (props.score) {
            case CompletenessScoreType.SUFFICIENT:
                return 'sufficient';
            case CompletenessScoreType.GOOD:
                return 'good';
            case CompletenessScoreType.VERY_GOOD:
                return 'verygood';
            case CompletenessScoreType.EXCELLENT:
                return 'excellent';
            default:
                return ""
        }
    }

    function fieldsToImproveToString(fieldsToImprove, limit: number = 5) {
        if (fieldsToImprove.length === 0) {
            return '';
        }
        
        let fieldsToImproveString = fieldsToImprove.slice(0, limit)
            .map(reason => translate(`resources.isin.fields.${reason.field}`))
            .join(", ");
        if (fieldsToImprove.length > limit) {
            fieldsToImproveString += translate('component.completenessScore.andMore');
        }
        return fieldsToImproveString;
    }

    return <div className={classnames('score-info', styleForScore())}>
        <div className="score">
            {translate('component.completenessScore.recordScore')}: <b>{getScoreText()}</b>
        </div>
        {props.fieldsToImprove.length > 0 && <div>
            {translate('component.completenessScore.improveScoreMessage', props.fieldsToImprove.length)}: <i>{fieldsToImproveToString(props.fieldsToImprove)}</i>
        </div>}
        {props.fieldsToImprove.length > 0 && <div>
            {translate('component.completenessScore.highlightInfo')}
        </div>}
    </div>;
};
