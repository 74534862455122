import React, { useEffect, useState } from 'react';
import { useNotify, useRefresh, useTranslate } from 'ra-core';
import {
    Create,
    Datagrid,
    DateField,
    ExportButton,
    Filter,
    FormDataConsumer,
    List, Pagination,
    RadioButtonGroupInput,
    required,
    sanitizeListRestProps,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useListContext
} from 'react-admin';
import { HiddenInputCsrfToken } from "../portfolio/HiddenInputCsrfToken";

import MatButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MatTextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Delete from "@material-ui/icons/Delete";
import { CardContent, makeStyles } from "@material-ui/core";
import { getRoles, PermissionInterface } from "../api/commonFunctions";

let physicalId = 'physical';

const CleanupFilter = (props: any) => {

    return <Filter {...props}>

        {/* Always-on filters */}

        <TextInput source={"isinId"}
                   parse={value => value ? value.toUpperCase() : ''}
                   alwaysOn
        />
        <TextInput source={"enteredBy"}
                   alwaysOn
        />


    </Filter>
};

function validateAll(values) {
    const errors = {} as any
    let cleanupIsinsType = values['cleanupIsinsType']
    if(!values['reason'] && cleanupIsinsType === physicalId){
        errors.reason = 'resources.cleanupisins.reasonRequired'
    }
    return errors
}

export function CleanupIsins(props) {

    const useStyles = makeStyles({
        warningred: {
            color: 'red',
            fontWeight: 600,
            width: 700
        },
        warningblack: {
            color: 'black',
            fontWeight: 600,
            width: 700
        },
        buttonred: {
            color: 'black',
            backgroundColor: 'red'
        },
    });

    const translate = useTranslate();
    const styles = useStyles()

    var [deleteReport, setDeleteReport] = useState(undefined)
    const [roles, setRoles] = useState<PermissionInterface>({ nna: false, portfolio: false, admin: false, user: false });

    useEffect(() => {
        async function fetchRoles() {
            const fetchedRoles = await getRoles();
            setRoles(fetchedRoles);
            console.log('fetchRoles ' + roles.admin);
        }

        fetchRoles();
    }, []);

    function DeletionReportDialog(props) {

        const handleClose = () => {
            setDeleteReport(undefined);
        }
        if (!props.values) {
            return null
        }

        return <Dialog
            open={deleteReport !== undefined}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">{"Deletion status"}</DialogTitle>
            <DialogContent>
                <div><b>Deleted ISINs :</b> {props.values.isinsDeleted.length}</div>
                <div><b>Not found ISINs :</b> {props.values.isinsNotDeleted.length}</div>
                <div><b>Deletion type :</b> {props.values.transType}</div>

                <MatTextField
                    fullWidth
                    label="ISINs that couldn't be deleted"
                    multiline
                    rowsMax="200"
                    value={props.values.isinsNotDeleted.join(", ")}
                    margin="normal"
                    variant="outlined"
                />

                <MatTextField
                    fullWidth
                    label="ISINs logically deleted"
                    multiline
                    rowsMax="20"
                    value={props.values.isinsDeleted.join(", ")}
                    margin="normal"
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <MatButton onClick={handleClose} color="primary" autoFocus>
                    Ok
                </MatButton>
            </DialogActions>
        </Dialog>
    }

    function PostCreateToolbar(props) {
        const notify = useNotify();
        const refresh = useRefresh();

        function onDeleteSuccess(response) {
            const { success, message } = response.data;
            if(!success) {
                notify(message, 'error');
            } else if(response.data.data) {
                setDeleteReport(response.data.data)
            }
            refresh();
        }

        return <FormDataConsumer {...props}>
            {({formData}) => {
                return <Toolbar {...props}>
                    <SaveButton
                        className={formData.cleanupIsinsType === physicalId ? styles.buttonred : ''}
                        icon={<Delete/>}
                        label='ra.action.delete'
                        submitOnEnter={false}
                        onSuccess={onDeleteSuccess}
                        handleSubmitWithRedirect={
                            () => {
                                if(!window.confirm(translate('resources.cleanupisins.askDelete')))
                                    return false;
                                return props.handleSubmitWithRedirect();
                            }
                        }
                    />
                </Toolbar>
            }}
        </FormDataConsumer>;
    }

    const ListActions = (props) => {
        const {
            currentSort,
            permanentFilter,
            className,
            filters,
            maxResults,
            exporter,
            ...rest
        } = props;
        const {
            resource,
            filterValues,
            total,
        } = useListContext();

        return (
            <Box display="flex" justifyContent="space-between" width="100%">
                <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                    <ExportButton
                        disabled={total === 0}
                        resource={resource}
                        sort={currentSort}
                        filter={{ ...filterValues, ...permanentFilter }}
                        exporter={exporter}
                        maxResults={maxResults}
                    />
                </TopToolbar>
            </Box>
        );
    };

    const getTypography = (props) => {
        return <FormDataConsumer {...props}>
            {({ formData }) => {
                const isPhysical = formData.cleanupIsinsType === physicalId
                return <Typography
                    className={isPhysical ? styles.warningred : styles.warningblack}>{translate(isPhysical ? 'resources.cleanupisins.physicalDeleteWarning' : 'resources.cleanupisins.logicalDeleteWarning')}</Typography>
            }}
        </FormDataConsumer>
    }

    let logicalId = 'logical';

    if(!roles.admin){
        return "forbidden!"
    }

    return <><Typography variant="h5">Cleanup ISINs</Typography><Create {...props} title={<></>}>
        <SimpleForm toolbar={<PostCreateToolbar/>}
        validate={validateAll}>

            <HiddenInputCsrfToken/>

            {/*<FileInput source={AUDIT.file}*/}
            {/*           accept={"text/plain,application/vnd.ms-excel,text/csv,.csv"}*/}
            {/*           placeholder={translate('resources.cleanupisins.isinsFile')}*/}
            {/*           fullWidth*/}
            {/*           type="file"*/}
            {/*           variant="outlined"*/}
            {/*           multiple={false}*/}
            {/*           validate={[validateTextFile, maxLength(1)]}>*/}

            {/*    <FunctionField render={file => {*/}
            {/*        return file.rawFile.name;*/}
            {/*    }}*/}
            {/*    />*/}
            {/*</FileInput>*/}
            <TextInput multiline
                       source="pastedIsins"
                       fullWidth
                       helperText="resources.cleanupisins.hints.pasted"
                       rowsMax={5}
            />
            <TextInput multiline
                       source="reason"
                       fullWidth
                       rowsMax={2}
            />
            <RadioButtonGroupInput source={"cleanupIsinsType"}
                                   choices={[
                                       {id: logicalId, name: 'resources.cleanupisins.logicalDelete'},
                                       {id: physicalId, name: 'resources.cleanupisins.physicalDelete'},
                                   ]}
                                   initialValue='logical'
                                   validate={required()}/>

            {getTypography(props)}

            <DeletionReportDialog values={deleteReport}/>

        </SimpleForm>
    </Create>

        <br></br>
        <Typography variant="h5">Cleanup History List</Typography>
        <CardContent>
            <List title='resources.cleanupisins.title'
                  perPage={25}
                  bulkActionButtons={false}
                  actions={<ListActions/>}
                  filters={<CleanupFilter/>}
                  pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]}/>}
                  {...props}>

                <Datagrid rowClick="expand" optimized>
                    {/*<TextField source={"batchId"}*/}
                    {/*           sortable={false}/>*/}
                    <TextField source={"isinId"}
                               sortable={false}/>
                    <TextField source={"transType"}
                               sortable={false}/>
                    <TextField source={"enteredBy"}
                               sortable={false}/>
                    <DateField source={"entryDate"}
                               sortable={false}/>
                    <TextField source={"status"}
                               sortable={false}/>
                    <DateField source={"completionDateLogical"}
                               sortable={false}/>
                    <DateField source={"completionDatePhysical"}
                               sortable={false}/>
                    <TextField source={"remark"} label={"Reason"}
                               sortable={false}/>
                </Datagrid>
            </List>
        </CardContent>
    </>
}