import { useFormState } from "react-final-form";
import { required, SelectInput } from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    small: {
        fontSize: '0.9rem',
        padding: '0px',
        '& .MuiInputBase-input': {
            fontSize: '0.9rem',
        },
    }
});
export const AssigneeNNASelection = ({ source, label, defaultAssignees }) => {

    interface Assignee {
        customerid: string;
        nnacd: string;
    }

    const classes = useStyles();
    const { values } = useFormState();
    const assignees = defaultAssignees;
    const uniqueAssignees:Assignee[] = Object.values(assignees.reduce((acc, assignee) => {
        acc[assignee.customerid] = assignee;
        return acc;
    }, {}));

    const choices = uniqueAssignees.map(assignee => ({
        id: assignee.customerid,
        name: assignee.nnacd
    })).sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    return <SelectInput validate={required()} className={classes.small} source={source} label={label} choices={choices} fullWidth/>;
};

export const AssigneeUserSelection = ({ source, label, defaultAssignees }) => {
    const classes = useStyles();
    const { values } = useFormState();
    const nnaCustomerId = values.assignedToCustomerId || [];
    const assignees = defaultAssignees;
    var choices = assignees.filter(assignee => assignee.customerid === nnaCustomerId).map(assignee => ({
        id: assignee.userid,
        name: assignee.username
    }));
    choices = [{
        id: undefined,
        name: "unassigned"
    }, ...choices]
    return <SelectInput className={classes.small} source={source} label={label} choices={choices} fullWidth/>;
};

export async function getAssigneesFromDb(dataProvider) {
    const { data } = await dataProvider.getList('assignees', {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'nnacd', order: 'ASC' },
        filter: {},
    });

    const mapAssignees = data.map(assignee => ({
        userid: assignee.userid,
        customerid: assignee.customerid,
        username: assignee.username,
        nnacd: assignee.nnacd
    }));

    return mapAssignees;
}
