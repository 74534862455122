import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export interface ConfirmationDialogRawProps {
    id: string,
    defaultValue: string,
    open: boolean,
    lei: string,
    optionUser: string,
    optionGleif: string,
    onClose: (value?: string) => void
}

const dialogStyles = makeStyles({
    label: {
        fontWeight: "bold"
    },
    divider: {
        marginTop: "1em",
        marginBottom: "1em"
    }
});

export function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
    const { onClose, defaultValue, open, optionUser, optionGleif } = props;
    const [value, setValue] = React.useState(defaultValue);
    const radioGroupRef = React.useRef<HTMLElement>(null);

    React.useEffect(() => {
        if (!open) {
            setValue(defaultValue);
        }
    }, [defaultValue, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const style = dialogStyles();

    return (
        <Dialog open={open}
                maxWidth="xs"
                onEntering={handleEntering}
                aria-labelledby="confirmation-dialog-title"
                onClose={handleCancel}
        >
            <DialogTitle id="confirmation-dialog-title">
                Difference with GLEIF database
                <IconButton aria-label="close" onClick={handleCancel}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >

                    <div color="textSecondary">
                        Entered value:
                    </div>
                    <FormControlLabel value={optionUser} control={<Radio/>} label={optionUser} classes={{
                        label: style.label
                    }}/>

                    <Divider variant="middle" classes={{
                        root: style.divider
                    }}/>

                    <div color="textSecondary">
                        LEI data for <a href={"https://search.gleif.org/#/record/" + props.lei}
                                        target="_blank"
                                        rel="noopener noreferrer">{props.lei}</a>:
                    </div>
                    <FormControlLabel value={optionGleif} control={<Radio/>} label={optionGleif} classes={{
                        label: style.label
                    }}/>

                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    {value === optionUser ? "Accept" : "Replace"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
