import React from 'react';
import { Layout, useAuthState } from 'react-admin';

import Menu from './Menu';
import Header from "./header";
import theme, { sidebarClosedWidth, sidebarSize } from "./theme";

export default (props: any) => {
    const { authenticated } = useAuthState();

    if(!authenticated){
        theme.sidebar.width = 0;
        theme.sidebar.closedWidth = 0;
    }else{
        theme.sidebar.width = sidebarSize;
        theme.sidebar.closedWidth = sidebarClosedWidth;
    }

    return <>
        <Layout
            {...props}
            menu={Menu}
            appBar={Header}
            theme={theme}
        />
    </>;
};
