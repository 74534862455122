import React from "react";
import { useFormState } from 'react-final-form';
import {
    ArrayInput,
    composeSyncValidators,
    DateTimeInput,
    maxLength,
    maxValue,
    minValue,
    number,
    NumberInput,
    required,
    SimpleFormIterator,
    TextInput,
} from "react-admin";
import FLD from "../model/ISIN";
import YesNo from "../model/YesNo";
import IsinInput from "./IsinInput";
import {
    nominalValueValidation,
    validateNumber_12_6,
    validateNumber_3_6,
    validateNumber_5_6,
    validateNumber_9_6
} from "../validation/numberValidation";
import IsinStatus from "../model/IsinStatus";
import CfiStandard from "../model/CfiStandard";
import { IE11DateInput } from "./IE11DateInput";
import InterestType from "../model/InterestType";
import ConditionalInput from "./ConditionalInput";
import { fillEnums } from "../api/commonFunctions";
import { LeiDataInput, LeiInput } from "./LeiInput";
import { SafeSelectInput } from "./SafeSelectInput";
import InterestFrequency from "../model/InterestFrequency";
import InstrumentCategory from "../model/InstrumentCategory";
import { utcToLocalDateTimeISO } from "../api/dateFormatter";
import { MaturityDate, MaturityType } from "./MaturityDateInput";
import validateIsinStatus from "../validation/validateIsinStatus";
import AutocompleteReferenceInput from "./AutocompleteReferenceInput";
import { Full, Half, Quarter, Row, TwoColumnLayout } from "../layout/LayoutComponents";
import { isValidISIN } from '../validation/isinValidation';
import CallPutType from "../model/CallPut";
import validateLei from "../validation/lei";
import { cfiValidater, fisnValidater } from "../validation/isinFieldValidators";

export interface IsinFieldsProps {
    resource: any,
    record: any,
    challenge: boolean
    fieldsToImprove?: any[],
}

function splitArrayOnWhitespace(text: string): string[] {
    if (!text) return [];
    return text.split(/\s/)
}

function joinArrayWithNewLines(text: string[]): string {
    if (!text) return "";
    return text.join("\n")
}

const OldIsinInput = ({ source }: { source: string }) => {
    const { values } = useFormState({ subscription: { values: true } });

    const validateIsin = (value: string): string | undefined => {
        if (!value) {
            return undefined;
        }
        const isinValues = value.split(' ').filter(Boolean);
        if (isinValues.length > 5) {
            return 'resources.isin.oldIsinsInvalid';
        }

        if (isinValues.includes(values[FLD.id])) {
            return 'resources.isin.isinAlreadyExists';
        }

        for (const isin of isinValues) {
            if (!isValidISIN(isin)) {
                return 'resources.isin.oldIsinInvalid';
            }
        }

        return undefined;
    };

    return <TextInput source={source} validate={validateIsin} style={{ width: '100%' }} />;
};

const NewIsinInput = ({ source }: { source: string }) => {
    const { values } = useFormState({ subscription: { values: true } });

    const validateIsin = (value: string): string | undefined => {
        if (!value) {
            return undefined;
        }

        if (value.includes(values[FLD.id])) {
            return 'resources.isin.isinAlreadyExists';
        }

        if (!isValidISIN(value)) {
            return 'resources.isin.oldIsinInvalid';
        }

        return undefined;
    };

    return <TextInput source={source} validate={validateIsin} style={{ width: '100%' }} />;
};



const IsinFields = ({ resource, record, fieldsToImprove, challenge }: IsinFieldsProps) => {
    const isIsinResource = resource === 'isin';
    const { dirtyFields } = useFormState({ subscription: { dirtyFields: true } });

    function validateStatus(value: string) {
        return validateIsinStatus(
            value,
            !!record.dateAdd  // is filled only by the server
        );
    }

    return <TwoColumnLayout resource={resource} dirtyfields={dirtyFields} fieldstoimprove={fieldsToImprove}>
        <Row>
            <Half>
                {resource === 'isin' ? <IsinInput source={FLD.id}/> : null}
            </Half>
            <Half>
                <SafeSelectInput source={FLD.instrumentCategory}
                                 choices={fillEnums(InstrumentCategory)}
                                 validate={isIsinResource ? [required()] : []}/>
            </Half>
        </Row>
        <Row>
            <Half>
                <OldIsinInput source={FLD.oldIsin}/>
            </Half>
            <Half>
                <NewIsinInput source={FLD.newIsin}/>
            </Half>
        </Row>
        <Row>
            <Full>
                <ConditionalInput source={FLD.issuerNameLong}>
                    <LeiDataInput maxLength="400"/>
                </ConditionalInput>
            </Full>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.issuerLegalRegCountry}>
                    <AutocompleteReferenceInput source={FLD.issuerLegalRegCountry}
                                                reference="countries"/>
                </ConditionalInput>
            </Half>

        </Row>
        <Row>
            <Full>
                {/* additional Entity Profile Data */}
                <ConditionalInput source={FLD.issueDescription}>
                    <TextInput validate={maxLength(120)}/>
                </ConditionalInput>
            </Full>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.issueCurrency}>
                    <AutocompleteReferenceInput reference="currencies"/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.isinStatus}>
                    <SafeSelectInput choices={fillEnums(IsinStatus)}
                                     validate={isIsinResource ? [validateStatus] : []}/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.cfiCode}>
                    <TextInput allowEmpty={!isIsinResource} validate={!challenge ? cfiValidater : null} parse={value => value ? value.toUpperCase() : ''}/>
                </ConditionalInput>
            </Half>
            <Half>
                <SafeSelectInput source={FLD.cfiVersion}
                                 choices={fillEnums(CfiStandard, false)}/>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.fisn}>
                    <TextInput allowEmpty={!isIsinResource} validate={!challenge ? fisnValidater : null}/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.preliminaryTerms}>
                    <SafeSelectInput allowEmpty={true}
                                     choices={fillEnums(YesNo)}/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.smallestDenomination}>
                    <TextInput validate={validateNumber_12_6}/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.nominalValue}>
                    <TextInput validate={nominalValueValidation}/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.maturityDateCode}>
                    <MaturityType/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.maturityDate}>
                    <MaturityDate/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.interestType}>
                    <SafeSelectInput allowEmpty={true}
                                     choices={fillEnums(InterestType)}
                    />
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.interestRate}>
                    <TextInput validate={validateNumber_3_6}/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.interestFrequency}>
                    <SafeSelectInput allowEmpty={true}
                                     choices={fillEnums(InterestFrequency)}/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Quarter>
                <ConditionalInput source={FLD.interestPayMonth}>
                    <NumberInput min={1} max={12}
                                 validate={composeSyncValidators(number(), minValue(1), maxValue(12))}/>
                </ConditionalInput>
            </Quarter>
            <Quarter>
                <ConditionalInput source={FLD.interestPayDay}>
                    <NumberInput min={1} max={31}
                                 validate={composeSyncValidators(number(), minValue(1), maxValue(31))}/>
                </ConditionalInput>
            </Quarter>
            <Half>
                <ConditionalInput source={FLD.interestFirstPayDate}>
                    <IE11DateInput/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.callPut}>
                    <SafeSelectInput allowEmpty={true}
                                     choices={fillEnums(CallPutType)}
                    />
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.conversionRatio}>
                    <TextInput validate={validateNumber_5_6}/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.exercisePrice}>
                    <TextInput validate={validateNumber_9_6}/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.exercisePriceCurrency}>
                    <AutocompleteReferenceInput reference="currencies"/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.underlyings}>
                    <TextInput multiline
                               rows={3}
                               format={joinArrayWithNewLines}
                               parse={splitArrayOnWhitespace}/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.underlyingInstrumentName}>
                    <TextInput multiline rows={3} validate={maxLength(255)}/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Full>
                <TextInput source={FLD.additionalInformationText}/>
            </Full>
        </Row>
        <Row>
            <Half>
                <TextInput source={FLD.marketIdCodes}
                           multiline
                           rows={3}
                           format={joinArrayWithNewLines}
                           parse={splitArrayOnWhitespace}/>
            </Half>

        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.leadManagerLEI}>
                    <LeiInput dependentFields={[
                        [FLD.leadManagerName, "legalName.name"]
                    ]}/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.leadManagerName}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.fundManagerLEI}>
                    <LeiInput dependentFields={[
                        [FLD.fundManagerName, "legalName.name"]
                    ]}/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.fundManagerName}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Full>
                <ConditionalInput source={FLD.centralSecDepsAndLEIs}>
                    <ArrayInput>
                        <SimpleFormIterator>
                            <LeiInput source={FLD.csdLei}
                                      dependentFields={[
                                          [FLD.csdName, "legalName.name"]
                                      ]}/>
                            <LeiDataInput source={FLD.csdName}/>

                        </SimpleFormIterator>
                    </ArrayInput>
                </ConditionalInput>
            </Full>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.issuerLEI}>
                    <LeiInput source={FLD.issuerLEI}
                              dependentFields={[
                                  [FLD.issuerHQCountry, "headquartersAddress.country"],
                                  [FLD.issuerHQAddress1, "headquartersAddress.addressLines[0]"],
                                  [FLD.issuerHQAddress2, "headquartersAddress.addressLines[1]"],
                                  [FLD.issuerHQStateProvince, "headquartersAddress.region"],
                                  [FLD.issuerHQPostCode, "headquartersAddress.postalCode"],
                                  [FLD.issuerHQCity, "headquartersAddress.city"],

                                  [FLD.issuerLegalRegAddress1, "legalAddress.addressLines[0]"],
                                  [FLD.issuerLegalRegAddress2, "legalAddress.addressLines[1]"],
                                  [FLD.issuerLegalRegStateProvince, "legalAddress.region"],
                                  [FLD.issuerLegalRegPostCode, "legalAddress.postalCode"],
                                  [FLD.issuerLegalRegCity, "legalAddress.city"],

                                  // questionable
                                  [FLD.issuerLegalRegCountry, "legalAddress.country"],
                                  [FLD.issuerNameLong, "legalName.name"],
                              ]}/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.issuerSupranational}>
                    <SafeSelectInput allowEmpty={true}
                                     choices={fillEnums(YesNo)}/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.issuerNameShort}>
                    <TextInput/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.issuerHQCountry}>
                    <AutocompleteReferenceInput reference="countries"/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <TextInput source={FLD.headOfficeLEI} validate={validateLei}/>
            </Half>
            <Half>
                <TextInput source={FLD.headOfficeName} validate={maxLength(255)}/>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.issuerHQAddress1}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.issuerLegalRegAddress1}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.issuerHQAddress2}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.issuerLegalRegAddress2}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.issuerHQStateProvince}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.issuerLegalRegStateProvince}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.issuerHQPostCode}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.issuerLegalRegPostCode}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
        </Row>
        <Row>
            <Half>
                <ConditionalInput source={FLD.issuerHQCity}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
            <Half>
                <ConditionalInput source={FLD.issuerLegalRegCity}>
                    <LeiDataInput/>
                </ConditionalInput>
            </Half>
        </Row>
        {isIsinResource && <Row>
            <Quarter>
                <DateTimeInput source={FLD.dateAdd}
                               format={utcToLocalDateTimeISO}
                               variant='standard'
                               disabled/>
            </Quarter>
            <Quarter>
                <TextInput source={FLD.userIdAdd}
                           variant='standard'
                           disabled/>
            </Quarter>
            <Quarter>
                <DateTimeInput source={FLD.dateModify}
                               format={utcToLocalDateTimeISO}
                               variant='standard'
                               disabled/>
            </Quarter>
            <Quarter>
                <TextInput source={FLD.userIdModify}
                           variant='standard'
                           disabled/>
            </Quarter>
        </Row> || <></>}
    </TwoColumnLayout>
};

export default IsinFields;
