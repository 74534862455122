enum InterestType {
    F = "Fixed Rate",
    Z = "Zero Rate",
    V = "Variable Rate",
    C = "Cash payment",
    K = "Payment in kind",
    D = "Dividend",
    Y = "Non-Payment",
    M = "Others"
}

export default InterestType;