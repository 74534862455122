import React from 'react';
import Typography from "@material-ui/core/Typography";
import { Button, Card, CardContent } from '@material-ui/core';
import { SelectField, Show, useRedirect, useTranslate } from 'react-admin';
import { SearchResults } from "./SearchResults";
import { Full, Half, Quarter, Row, TwoColumnLayout } from "../layout/LayoutComponents";
import ISIN_FIELD from "../model/ISIN";
import FLD from "../model/ISIN";
import { fillEnums, getRoles, PermissionInterface } from "../api/commonFunctions";
import InstrumentCategory from "../model/InstrumentCategory";
import IsinStatus from "../model/IsinStatus";
import CfiStandard from "../model/CfiStandard";
import InterestType from "../model/InterestType";
import YesNo from "../model/YesNo";
import InterestFrequency from "../model/InterestFrequency";
import { checkResponsibility } from "../validation/isinValidation";
import { ShowField } from "../layout/ShowField";
import ChallengeShortList from "../challenge/ChallengeShortList";
import { ShowFieldReference } from "../layout/ShowFieldReference";
import { IsinPageTitle } from "./IsinEdit";
import { utcToLocalDateTime } from "../api/dateFormatter";
import "./IsinShow.css"
import CallPutType from "../model/CallPut";
import { useEffect, useState } from 'react';

const IsinShow = ({ permissions, ...props }) => {

    const translate = useTranslate();
    const redirect = useRedirect();

    const [roles, setRoles] = useState<PermissionInterface>({ nna: false, portfolio: false, admin: false, user: false });

    useEffect(() => {
        async function fetchRoles() {
            const fetchedRoles = await getRoles();
            setRoles(fetchedRoles);
        }

        fetchRoles();
    }, []);

    function challengeIsin() {
        redirect('edit', '/challenge', props.id);
    }

    function editIsin(isin) {
        redirect('edit', '/isin', isin);
    }

    const IsinActions = ({ isin }) => {
        const showEdit = checkResponsibility(isin) || roles.admin;
        const showChallenge = (!checkResponsibility(isin) && roles.nna) || roles.admin;

        return <div className="actionButtons">
            {showEdit && <Button variant="contained" color="primary" onClick={() => editIsin(isin)}>
                {translate('resources.isin.editAction')}
            </Button>}
            {showChallenge && <Button variant="contained" color="primary" onClick={challengeIsin}>
                {translate('resources.isin.challengeAction')}
            </Button>}
        </div>;
    };

    return <Show {...props}
                 component={CardWithPadding}
                 actions={<></>}
                 title={<IsinPageTitle/>}
                 aside={<SearchResults currentIsin={props.id}/>}>

        <TwoColumnLayout resource={props.resource}>
            <ChallengeShortList isin={props.id} type="open"/>

            <Row>
                <Half>
                    <ShowField source={ISIN_FIELD.id}/>
                </Half>
                <Half>
                    <ShowField source={ISIN_FIELD.instrumentCategory}>
                        <SelectField choices={fillEnums(InstrumentCategory)}/>
                    </ShowField>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.issuerNameLong}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.oldIsin}/>
                </Half>
                <Half>
                    <ShowField source={FLD.newIsin}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={ISIN_FIELD.issuerLegalRegCountry}>
                        <ShowFieldReference reference="countries"/>
                    </ShowField>
                </Half>
            </Row>
            <Row>
                <Full>
                    {/* additional Entity Profile Data */}
                    <ShowField source={FLD.issueDescription}/>
                </Full>
            </Row>
            <Row>
                <Half>
                    <ShowField source={ISIN_FIELD.issueCurrency}>
                        <ShowFieldReference reference="currencies"/>
                    </ShowField>
                </Half>
                <Half>
                    <ShowField source={FLD.isinStatus}>
                        <SelectField choices={fillEnums(IsinStatus)}/>
                    </ShowField>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.cfiCode}/>
                </Half>
                <Half>
                    <ShowField source={FLD.cfiVersion}>
                        <SelectField choices={fillEnums(CfiStandard, false)}/>
                    </ShowField>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.fisn}/>
                </Half>
                <Half>
                    <ShowField source={FLD.preliminaryTerms}>
                        <SelectField choices={fillEnums(YesNo)}/>
                    </ShowField>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.smallestDenomination}/>
                </Half>
                <Half>
                    <ShowField source={FLD.nominalValue}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.maturityDate}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.interestType}>
                        <SelectField choices={fillEnums(InterestType)}/>
                    </ShowField>
                </Half>
                <Half>
                    <ShowField source={FLD.interestRate}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.interestFrequency}>
                        <SelectField choices={fillEnums(InterestFrequency)}/>
                    </ShowField>
                </Half>
            </Row>
            <Row>
                <Quarter>
                    <ShowField source={FLD.interestPayMonth}/>
                </Quarter>
                <Quarter>
                    <ShowField source={FLD.interestPayDay}/>
                </Quarter>
                <Half>
                    <ShowField source={FLD.interestFirstPayDate}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.callPut}>
                        <SelectField choices={fillEnums(CallPutType)}/>
                    </ShowField>
                </Half>
                <Half>
                    <ShowField source={FLD.conversionRatio}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.exercisePrice}/>
                </Half>
                <Half>
                    <ShowField source={FLD.exercisePriceCurrency}>
                        <ShowFieldReference reference="currencies"/>
                    </ShowField>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.underlyings}/>
                </Half>
                <Half>
                    <ShowField source={FLD.underlyingInstrumentName}/>
                </Half>
            </Row>
            <Row>
                <Full>
                    <ShowField source={FLD.additionalInformationText}/>
                </Full>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.marketIdCodes}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.leadManagerLEI}/>
                </Half>
                <Half>
                    <ShowField source={FLD.leadManagerName}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.fundManagerLEI}/>
                </Half>
                <Half>
                    <ShowField source={FLD.fundManagerName}/>
                </Half>
            </Row>
            <Row>
                <Full>
                    <ShowField source={FLD.centralSecDepsAndLEIs}>
                        <ShowCsd/>
                    </ShowField>
                </Full>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.issuerLEI}/>
                </Half>
                <Half>
                    <ShowField source={FLD.issuerSupranational}>
                        <SelectField choices={fillEnums(YesNo)}/>
                    </ShowField>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.issuerNameShort}/>
                </Half>
                <Half>
                    <ShowField source={ISIN_FIELD.issuerHQCountry}>
                        <ShowFieldReference reference="countries"/>
                    </ShowField>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.headOfficeLEI}/>
                </Half>
                <Half>
                    <ShowField source={FLD.headOfficeName}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.issuerHQAddress1}/>
                </Half>
                <Half>
                    <ShowField source={FLD.issuerLegalRegAddress1}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.issuerHQAddress2}/>
                </Half>
                <Half>
                    <ShowField source={FLD.issuerLegalRegAddress2}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.issuerHQStateProvince}/>
                </Half>
                <Half>
                    <ShowField source={FLD.issuerLegalRegStateProvince}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.issuerHQPostCode}/>
                </Half>
                <Half>
                    <ShowField source={FLD.issuerLegalRegPostCode}/>
                </Half>
            </Row>
            <Row>
                <Half>
                    <ShowField source={FLD.issuerHQCity}/>
                </Half>
                <Half>
                    <ShowField source={FLD.issuerLegalRegCity}/>
                </Half>
            </Row>
            <Row>
                <Quarter>
                    <ShowField source={FLD.dateAdd}
                               format={utcToLocalDateTime}/>
                </Quarter>
                <Quarter>
                    <ShowField source={FLD.userIdAdd}/>
                </Quarter>
                <Quarter>
                    <ShowField source={FLD.dateModify}
                               format={utcToLocalDateTime}/>
                </Quarter>
                <Quarter>
                    <ShowField source={FLD.userIdModify}/>
                </Quarter>
            </Row>

            <Row>
                <Quarter>
                    <IsinActions isin={props.id}/>
                </Quarter>
            </Row>

            <ChallengeShortList isin={props.id} type="closed"/>
        </TwoColumnLayout>
    </Show>
};

const ShowCsd = ({ value = null }) =>
    <Typography variant={"body2"}>
        {value.map(pair => pair.csdName + (pair.csdLei ? ` (${pair.csdLei})` : "")).join(", ")}
    </Typography>

const CardWithPadding = ({ children }) =>
    <Card>
        <CardContent>
            {children}
        </CardContent>
    </Card>

export default IsinShow;

