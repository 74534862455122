// https://material-ui.com/ru/components/material-icons/
import IsinIcon from '@material-ui/icons/AccountBalance';

import IsinList from './IsinList';
import IsinCreate from './IsinCreate';
import IsinEdit from './IsinEdit';
import IsinShow from "./IsinShow";

export default {
    list: IsinList,
    create: IsinCreate,
    edit: IsinEdit,
    show: IsinShow,
    icon: IsinIcon,
    options: {
        label: "ISIN Management"
    }
};
