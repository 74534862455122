import React from 'react';
import { Edit, useNotify, useRedirect, } from 'react-admin';
import ChallengeForm from './ChallengeForm'
import ChallengeShortList from "./ChallengeShortList";

function setIsin(data) {
    data.challengeISINs = data.id;
    return data
}

function oneIsin(props: any) {
    return props.id.length === 12;
}

const ChallengeCreate = (props: any) => {

    const notify = useNotify();
    const redirect = useRedirect();

    function onSubmitSuccess(response) {
        const { success, message } = response;
        if (!success) {
            notify(message, 'error');
        } else {
            notify(message, 'info');

            if (oneIsin(props)) {
                redirect('show', '/isin', props.id);
            } else {
                redirect('list', '/isin');
            }
        }
    }

    return <>
        { oneIsin(props) && <ChallengeShortList isin={props.id} type="open"/> }
        <Edit {...props}
              transform={setIsin}
              onSuccess={onSubmitSuccess}
              undoable={false}
              title='resources.challenge.create'>

            <ChallengeForm {...props}/>
        </Edit>
    </>;
};

export default ChallengeCreate;