import * as React from 'react';
import get from 'lodash/get';
import { Link } from '@material-ui/core';

export default ({ href, source, record = {}, basePath = "", sortable = false, ...rest }) =>

    <Link href={href(record)}
          {...rest}
    >
        {get(record, source)}
    </Link>