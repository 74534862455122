import * as React from "react";
import { Button, Datagrid, DateField, List, TextField, useRefresh, useUpdateMany, useUnselectAll } from 'react-admin';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

const BulkActions = (props) => {
    const [updateMany] = useUpdateMany(props.resource, props.selectedIds, { runNow: true });
    const refresh = useRefresh();
    const unselectAll = useUnselectAll();

    async function runNow() {
        await updateMany();
        unselectAll('schedule');
        refresh();
    }

    return <>
        <Button label="resources.schedule.runNow" onClick={runNow}>
            <PlayCircleOutlineIcon/>
        </Button>
    </>
};


export const ScheduleList = (props) => (
    <List {...props} bulkActionButtons={<BulkActions/>}>
        <Datagrid>
            <TextField sortable={false} source="text"/>
            <TextField sortable={false} source="schedule"/>
            <TextField sortable={false} source="trigger"/>
            <DateField sortable={false} source="next" showTime={true}/>
        </Datagrid>
    </List>
);
