import React, { useCallback } from 'react';
import { Layout } from '../layout';
import { Loading, useAuthState } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import './login.css'
import { useLogout, useTranslate } from 'ra-core';
import { KEY_USER } from "../api/authProvider";
import LoginForm from "./LoginForm";

const LogoutForm = () => {

    const translate = useTranslate();
    const logout = useLogout();

    const handleClick = useCallback(() =>
            logout(null, '/', false),
        [logout]);

    return <Card variant="outlined">
        <CardContent>
            Logged in as <strong>{localStorage.getItem(KEY_USER)}</strong>
        </CardContent>

        <CardActions>
            <Button variant="contained" type="submit" color="primary" onClick={handleClick}
                    className="button"> {translate('ra.auth.logout')}</Button>
        </CardActions>
    </Card>
};

export default function LoginPage(props) {
    const translate = useTranslate();

    const { loading, authenticated } = useAuthState();

    return <Layout className={"loginPage"} {...props}>

        <div className="actions">
            {loading && <Loading/>}
            {authenticated
                ? <LogoutForm/>
                : <LoginForm/>
            }
        </div>

    </Layout>;

}