import React, { Children } from "react";
import { Box } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'

import '../layout/fieldHighlight.css'

const theme = createTheme();
const base = 120;
const gap = 2;
const marginRight = theme.spacing(gap);
const fullWidth = theme.spacing(base + 2 * gap);
const fullSize = theme.spacing(base);
const halfSize = theme.spacing((base - gap) / 2);
const quarterSize = theme.spacing((base - 3 * gap) / 4);

const TO_IMPROVE_STYLE = 'fieldToImprove';
const MODIFIED_STYLE = 'modifiedCell';

export const TwoColumnLayout = (props) => {
    return <Box width={fullWidth} display="flex" flexDirection="column">
        {renderChildren(props)}
    </Box>;
};

const Cell = (props) => {
    
    let fieldStyle = '';

    if (props.children){
        const child = Children.only(props.children);
        const sourceName = child.props.source;

        if (props.fieldstoimprove){
            const toImprove = props.fieldstoimprove.map(f => f.field);
            
            const isFieldToImprove = toImprove?.includes(sourceName);
            
            if (isFieldToImprove) {
                // Only highlight for improvement if not already highlighted for modified
                if (fieldStyle !== MODIFIED_STYLE) {
                    fieldStyle = TO_IMPROVE_STYLE;
                }
            } else {
                // reset highlight for improvement if it's not marked as such anymore
                if (fieldStyle === TO_IMPROVE_STYLE) {
                    fieldStyle = '';
                }
            }
        }

        const fieldChanged = sourceName !== undefined && props.dirtyfields !== undefined && props.dirtyfields[sourceName];
        
        if (fieldChanged) {
            fieldStyle = MODIFIED_STYLE;
        } else {
            // reset highlight for modified if it's not marked as such anymore
            if (fieldStyle === MODIFIED_STYLE) {
                fieldStyle = '';
            }
        }
    }

    const newProps = {
        fullWidth: true,
        className: fieldStyle,
        ...props
    }

    return <Box width={props.width} mr={marginRight + 'px'} className="formLayoutCell">
        {renderChildren(newProps)}
    </Box>;

}

export const Full = (props) =>
    <Cell width={fullSize} {...props}/>;

export const Half = (props) =>
    <Cell width={halfSize} {...props}/>;

export const Quarter = ({ ...props }) =>
    <Cell width={quarterSize} {...props}/>;

export const Row = (props) =>
    <Box display="flex" flexDirection="row" className="formLayoutRow">
        {renderChildren(props)}
    </Box>;

const renderChildren = ({ children, ...props }) => {
    return React.Children.map(children, child => {
        return React.cloneElement(child, props)
    })
}