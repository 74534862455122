import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Title } from "react-admin";

export default function FieldDefinitions() {
    return <Card className="Card">
        <Title title="ASB field and record format document"/>
        <CardContent>
            <h1>Format &amp; Field Definitions.</h1>
        </CardContent>
        <CardActions>
            <div className="externallink">
                <a href="./publicdocs/fielddefinitions.pdf" target="_blank" rel="noopener noreferrer">
                    Download.
                </a>
            </div>
        </CardActions>
    </Card>;
}
